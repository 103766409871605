import styled, {css} from 'styled-components'
import React from 'react'
import {Colors, HTMLDivProps, Icon} from '@blueprintjs/core'

export const CardTag = styled.div`
  border-radius: 4px;
  border: solid 1px rgba(16, 22, 26, 0.15);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const interactiveStyle = css`
  cursor: pointer;

  :hover {
    background-color: ${Colors.LIGHT_GRAY5};
  }

  :active {
    background-color: ${Colors.LIGHT_GRAY4};
  }
`

const CardSectionTag = styled.div<{ interactive?: boolean, noPadding?: boolean, fillSpace?: boolean }>`
  :not(:last-child) {
    border-bottom: solid 1px rgba(16, 22, 26, 0.15);
  }

  ${props => !props.noPadding && {padding: '8px'}}
  ${props => props.interactive && interactiveStyle}
  ${props => props.fillSpace && {flex: 1}}
`

const CardHeaderTag = styled(CardSection)`
  background-color: rgba(138, 155, 168, .15);
  display: flex;
  justify-content: space-between;
`

const CardTitle = styled.span`
  font-size: 16px;
`

const CardBodyTag = styled.div<{ scrollable: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${props => props.scrollable && {overflow: 'auto'}}
`

interface ICardProps extends HTMLDivProps {
  children?
  leftIcon?
  title?: string
  rightElement?
  scrollable?
  renderFooter?
}

export function Card(props: ICardProps) {
  const {
    children,
    leftIcon,
    title,
    rightElement,
    scrollable,
    renderFooter,
    ...divProps
  } = props

  return <CardTag
    {...divProps}
  >
    {title && <CardHeaderTag>
      <CardTitle>
        {leftIcon && <Icon icon={leftIcon} style={{marginRight: '4px'}}/>}
        <span>{title}</span>
      </CardTitle>
      {rightElement}
    </CardHeaderTag>}

    <CardBodyTag scrollable={scrollable}>
      {children}
    </CardBodyTag>

    {renderFooter}
  </CardTag>
}

export function CardSection(props) {
  return <CardSectionTag {...props}/>
}
