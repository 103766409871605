import React, {useCallback, useMemo} from 'react'
import InputSelect, {IInputSelectProps} from 'shared/inputsV2/select/InputSelect'

interface IInputSelectIdProps<T> extends Omit<IInputSelectProps<T>, 'value' | 'onChange'> {
  value: string
  onChange: (value: string) => void
  entityKey?: string
}

function InputSelectId<T>(props: IInputSelectIdProps<T>) {
  const {
    items,
    value,
    onChange,
    entityKey = 'id',
    ...others
  } = props

  const itemValue = useMemo<T>(() => {
    if (!items)
      return null
    return items.find(item => item[entityKey]?.toString() === value?.toString())
  }, [items, entityKey, value])

  const onItemChange = useCallback((value: T) => {
    const nextValue = value ? value[entityKey] : null
    onChange(nextValue)
  }, [onChange, entityKey])

  return (
    <InputSelect<T>
      value={itemValue}
      onChange={onItemChange}
      items={items}
      {...others}
    />
  )
}

export default InputSelectId
