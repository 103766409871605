import {Intent, Position, Toaster} from '@blueprintjs/core'

export const AppToaster = Toaster.create({position: Position.BOTTOM_RIGHT, maxToasts: 1})

const showSuccess = message => AppToaster.show({
  message,
  icon: 'tick',
  intent: Intent.SUCCESS,
})

const showError = message => AppToaster.show({
  message,
  icon: 'error',
  intent: Intent.DANGER,
})

export const MyToasts = {
  showSuccess,
  showError,
}
