import {Dispatch, SetStateAction, useEffect, useState} from 'react'

export function useLocalStorage(key: string): [string, Dispatch<SetStateAction<string>>] {
  const [value, setValue] = useState<string>(localStorage.getItem(key))

  useEffect(() => {
    if (!!value)
      localStorage.setItem(key, value)
    else
      localStorage.removeItem(key)
  }, [value, key])

  return [value, setValue]
}
