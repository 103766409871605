import React from 'react'
import {Tag} from '@blueprintjs/core'

interface IProductStockTagProps {
  stock: number
}

function ProductStockTag(props: IProductStockTagProps) {
  const {
    stock,
  } = props

  const label = stock > 0 ? stock : 'Terminato'

  return (
    <Tag
      minimal={true}
      intent={stock > 0 ? 'success' : 'danger'}
    >{label}</Tag>
  )
}

export default ProductStockTag
