import React from 'react'
import {List} from 'shared/list/List'
import {Dialog} from '@blueprintjs/core'
import {useSelectedListItem} from 'shared/hooks/useSelectedListItem'
import styled from 'styled-components'
import {useFieldArray} from 'react-final-form-arrays'
import {ListHeader} from 'shared/list/ListHeader'
import {IListSelectionManager} from 'shared/hooks/useListSelectionManager'
import {useForm} from 'react-final-form'

interface IItemsFormSectionProps<T> {
  title: string
  state: IItemsFormSectionState<T>
  renderItem: (value: T) => JSX.Element
  dialogTitle?: string
  renderDialog: JSX.Element
  selectionManager?: IListSelectionManager<T>
  headerElement?
}

export function ItemsFormSection<T>(props: IItemsFormSectionProps<T>) {
  const {
    title,
    state,
    renderItem,
    dialogTitle,
    renderDialog,
    selectionManager,
    headerElement,
  } = props

  const {
    isDialogOpen,
    onDialogClose,
    items,
    onItemSelect,
    isEmptyList,
  } = state

  return (
    <>
      {!isEmptyList && <div>
        <ListHeader
          style={{height: '40px'}}
          selectionManager={selectionManager}
          leftElement={
            <div>
              <LabelTag>{title}</LabelTag>
              {headerElement}
            </div>
          }
        />

        <List<T>
          greyBackground={true}
          interactive={true}
          items={items}
          renderItem={renderItem}
          selectionManager={selectionManager}
          itemRowProps={(item, index) => ({
            onClick: () => onItemSelect(item, index),
          })}
        />
      </div>}

      <Dialog
        isOpen={isDialogOpen}
        onClose={onDialogClose}
        lazy={true}
        title={dialogTitle}
      >
        {renderDialog}
      </Dialog>
    </>
  )
}

const LabelTag = styled.label`
text-transform: uppercase;
font-weight: 500;
font-size: 16px;
`

export interface IItemsFormSectionState<T> {
  isDialogOpen: boolean
  onDialogClose: () => void
  onItemSelect: (item: T, index: number) => void
  items: T[]
  isEmptyList: boolean
  onNewItem: (value?: T) => void
  pushItem: (item: T) => void
  updateItem: (item: T, index: number) => void
  removeItem: (index: number) => void
  selectedValue: T
  onDialogSave: (value: T) => void
  isNewSelected: boolean
  removeSelected: () => void
  updateAll: (values: T[]) => void
}

export function useItemsFormSectionState<T>(name): IItemsFormSectionState<T> {
  const {
    isSelected,
    reset,
    selectItem,
    selectNew,
    selectedValue,
    selectedIndex,
    isNew,
  } = useSelectedListItem<T>()

  const form = useForm()
  const goodsItemsField = useFieldArray<T>(name)
  const items = goodsItemsField.fields.value
  const isEmptyList = !items || !items.length

  const pushItem = (item: T) => goodsItemsField.fields.push(item)
  const updateItem = (item: T, index: number) => goodsItemsField.fields.update(index, item)
  const removeItem = (index: number) => goodsItemsField.fields.remove(index)
  const onDialogSave = (value: T) => {
    if (isNew)
      pushItem(value)
    else
      updateItem(value, selectedIndex)
    reset()
  }
  const removeSelected = () => {
    removeItem(selectedIndex)
    reset()
  }
  const updateAll = (values: T[]) => {
    form.change(name, values)
  }

  return {
    isDialogOpen: isSelected,
    onDialogClose: reset,
    onItemSelect: selectItem,
    items,
    isEmptyList,
    onNewItem: selectNew,
    pushItem,
    updateItem,
    removeItem,
    removeSelected,
    selectedValue,
    onDialogSave,
    isNewSelected: isNew,
    updateAll,
  }
}
