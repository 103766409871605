import React from 'react'
import { IItemsFormSectionState, ItemsFormSection } from './ItemsFormSection'
import { IDocumentGoodItem } from './IDocumentGoodItem'
import { MoneyUtils } from 'shared/utils/moneyUtils'
import IProduct from '../product/IProduct'
import { Table } from 'shared/table/Table'
import { MoneyValue } from 'shared/components/MoneyValue'
import { Button, Classes, Intent } from '@blueprintjs/core'
import { ProductForm } from '../product/components/ProductForm'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { useListSelectionManager } from 'shared/hooks/useListSelectionManager'
import { IMassUpdateOption, MassUpdateButton } from './MassUpdateButton'

interface IGoodsItemsFormSectionProps {
  isGrossMode: boolean
  state: IItemsFormSectionState<IDocumentGoodItem>
  renderItemForm: JSX.Element
  productToGoodItem: (product: IProduct) => IDocumentGoodItem
  massUpdateOptions: IMassUpdateOption[]
  renderItem: (value: IDocumentGoodItem) => JSX.Element
  onDocumentGoodItemSubmit?: (value: IDocumentGoodItem) => any
}

export function GoodsItemsFormSection(props: IGoodsItemsFormSectionProps) {
  const {
    state,
    isGrossMode,
    renderItemForm,
    productToGoodItem,
    massUpdateOptions,
    renderItem,
    onDocumentGoodItemSubmit,
  } = props

  const {
    selectedValue,
    onDialogSave,
    isNewSelected,
    removeSelected,
  } = state

  const showProductForm = !selectedValue?.product

  const selectionManager = useListSelectionManager(state.items)

  const onMassUpdate = (value) => {
    const newValue = state.items.map(item =>
      selectionManager.isItemSelected(item) ?
        goodItemMapper({...item, ...value}, isGrossMode) :
        item)
    state.updateAll(newValue)
    selectionManager.resetSelection()
  }

  const onGoodItemFormSubmit = formValue => {
    const value = goodItemMapper(formValue, isGrossMode)
    if (onDocumentGoodItemSubmit) onDocumentGoodItemSubmit(value)
    onDialogSave(value)
  }

  return (
    <ItemsFormSection<IDocumentGoodItem>
      title={'articoli'}
      headerElement={selectionManager.isSomeSelected && <MassUpdateButton
        onSubmit={onMassUpdate}
        options={massUpdateOptions}
      />}
      renderItem={renderItem}
      state={state}
      dialogTitle={showProductForm ? 'Crea un nuovo prodotto' : 'Articolo selezionato'}
      selectionManager={selectionManager}
      renderDialog={<>
        {showProductForm && <ProductForm
          onSaved={product => state.onNewItem(productToGoodItem(product))}
          hideDismissButton={true}
          hideInitialStock={true}
          className={Classes.DIALOG_BODY}
        />}

        {!showProductForm && <Form<IDocumentGoodItem>
          onSubmit={onGoodItemFormSubmit}
          initialValues={selectedValue}
          mutators={{...arrayMutators}}
          render={({handleSubmit}) => <form
            onSubmit={handleSubmit}
            className={Classes.DIALOG_BODY}
          >

            <h4 style={{marginTop: 0}}>Dettaglio prodotto</h4>
            <ProductPreview product={selectedValue.product}/>

            <h4 style={{marginTop: 0}}>Dettaglio articolo</h4>

            {renderItemForm}


            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              {!isNewSelected && <Button
                text={'Rimuovi articolo'}
                intent={Intent.DANGER}
                onClick={removeSelected}
              />}
              <Button
                type={'submit'}
                text={'Conferma'}
                intent={Intent.SUCCESS}
              />
            </div>
          </form>}
        />}
      </>}
    />
  )
}

export const goodItemMapper = (item: IDocumentGoodItem, isGrossMode: boolean) => {
  const vat = item.vat ? item.vat.value : 0
  const result = isGrossMode ?
    ({...item, unit_net: MoneyUtils.grossToNetR(item.unit_gross, vat, 5)}) :
    ({...item, unit_gross: MoneyUtils.netToGrossR(item.unit_net, vat, 2)})

  const {unit_net, unit_gross, quantity} = result
  const discount = MoneyUtils.round(item.discount_percent / 100, 2)
  return {
    ...result,
    discount,
    net: MoneyUtils.applyDiscountR(unit_net * quantity, discount, 5),
    gross: MoneyUtils.applyDiscountR(unit_gross * quantity, discount, 2),
  }
}

function ProductPreview({product}: { product: IProduct }) {
  const {
    code,
    description,
    vat,
    price_list_gross,
  } = product

  return <Table<{ label, value }>
    hideHeader={true}
    compact={true}
    data={[
      {
        label: 'Codice:',
        value: code,
      },
      {
        label: 'Descrizione:',
        value: description,
      },
      {
        label: 'IVA prodotto:',
        value: vat.label,
      },
      {
        label: 'Listino',
        value: <MoneyValue value={price_list_gross}/>,
      },
    ]}
    columns={[
      {
        renderCell: ({label}) => label,
      },
      {
        renderCell: ({value}) => value,
      },
    ]}
  />
}
