import {Dispatch, SetStateAction, useMemo} from 'react'

const key = 'page'

export function useParamPage([params, setParams], defaultValue = 1): [number, Dispatch<SetStateAction<number>>] {
  const value = params?.[key]

  const page = useMemo(() => (+value || defaultValue), [value, defaultValue])

  const setPage = page => setParams({...params, [key]: page})

  return [page, setPage]
}
