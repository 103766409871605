import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import {HTMLDivProps, IResizeEntry, ResizeSensor} from '@blueprintjs/core'

const DivAppWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`

export function AppWrapper(props: HTMLDivProps) {
  const [value, setValue] = useState<IWrapperContext>({
    width: null,
    height: null,
  })

  const resizeToValue = useCallback((entries: IResizeEntry[]) => {
    const {width, height} = entries[0].contentRect

    setValue({
      width,
      height,
    })
  }, [])

  return (
    <ResizeSensor onResize={resizeToValue}>
      <WrapperContext.Provider value={value}>
        <DivAppWrapper>
          {props.children}
        </DivAppWrapper>
      </WrapperContext.Provider>
    </ResizeSensor>
  )
}

export interface IWrapperContext {
  width: number
  height: number
}

export const WrapperContext = React.createContext<IWrapperContext>(null)
