import React from 'react'
import {HTMLDivProps} from '@blueprintjs/core'
import styled from 'styled-components'

interface IListRowProps extends HTMLDivProps {
  leftImage?: JSX.Element
  topLeft?: JSX.Element | string | number
  topRight?: JSX.Element | string | number
  bottomLeft?: JSX.Element | string | number
  bottomRight?: JSX.Element | string | number
  large?: boolean
}

export function ListRow(props: IListRowProps) {
  const {
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    leftImage,
    large,
    ...divProps
  } = props

  return (
    <ListRowTag
      {...divProps}
    >
      {leftImage && <LeftTag>
        {leftImage}
      </LeftTag>}
      <CenterTag>
        <MainData large={large}>
          {topLeft}
          {topRight}
        </MainData>
        <SecondaryData large={large}>
          {bottomLeft}
          {bottomRight}
        </SecondaryData>
      </CenterTag>
    </ListRowTag>
  )
}

const ListRowTag = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`

const LeftTag = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`

const CenterTag = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`


const DataSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MainData = styled(DataSection)<{ large }>`
  font-size: ${({large}) => large ? 18 : 14}px;
`

const SecondaryData = styled(DataSection)<{ large }>`
  font-size: ${({large}) => large ? 14 : 12}px;
`
