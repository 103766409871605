import React, {CSSProperties, useContext} from 'react'
import {Button, Colors, HTMLDivProps} from '@blueprintjs/core'
import styled from 'styled-components'
import {FullScreenPortal} from './FullscreenPortal'

interface IFullScreenPageProps {
  contentMaxWidth?
  tagName?: keyof JSX.IntrinsicElements
  children?
  wrapperProps?
}

const wrapperStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}

const FullScreenSectionWith = React.createContext(null)

export function FullScreenPage(props: IFullScreenPageProps) {
  const {
    contentMaxWidth = '700px',
    tagName: Wrapper = `div`,
    wrapperProps,
    children,
  } = props

  return (
    <FullScreenPortal>
      <FullScreenSectionWith.Provider value={contentMaxWidth}>
        <Wrapper {...wrapperProps} style={wrapperStyle} children={children}/>
      </FullScreenSectionWith.Provider>
    </FullScreenPortal>
  )
}

export interface FullScreenSectionProps {
  children?: any
  noBorder?: boolean
  containerProps?: HTMLDivProps
  contentProps?: HTMLDivProps
  fill?: boolean
  noPadding?: boolean
}

const SectionContent = styled.div<{ maxWidth: string, noPadding: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${({maxWidth}) => maxWidth};
  padding: ${({noPadding}) => noPadding ? '0 16px' : '16px'};
`

const SectionContainer = styled.div <{ noBorder: boolean, ff: boolean }>`
  :not(:last-child) {
    border-bottom: ${({noBorder}) => noBorder ? null : 'solid 1px ' + Colors.LIGHT_GRAY1}
  }

  ${({ff}) => ff && {
    overflow: 'auto',
    flex: 1,
  }}
`

export function FullScreenSection(props: FullScreenSectionProps) {
  const {
    children,
    noBorder,
    containerProps,
    contentProps,
    fill,
    noPadding,
  } = props

  const maxWidth = useContext(FullScreenSectionWith)

  return (
    <SectionContainer
      noBorder={noBorder}
      ff={fill}
      {...containerProps}
    >
      <SectionContent
        maxWidth={maxWidth}
        noPadding={noPadding}
        {...contentProps}
      >
        {children}
      </SectionContent>
    </SectionContainer>
  )
}

const HeaderTitleContainer = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;
`

const HeaderTitle = styled.h4`
  margin: 0;
  flex: 1;
`

interface IFullScreenHeaderSectionProps {
  title?: string
  onClose?: () => any
  renderSubHeader?
}

export function FullScreenHeaderSection(props: IFullScreenHeaderSectionProps) {
  const {title, onClose, renderSubHeader} = props

  return (
    <FullScreenSection noPadding={true}>
      <HeaderTitleContainer>
        <HeaderTitle>{title}</HeaderTitle>
        <Button
          icon={'cross'}
          onClick={onClose}
          minimal={true}
        />
      </HeaderTitleContainer>

      {renderSubHeader}
    </FullScreenSection>
  )
}
