import React from 'react'
import {DocumentListPage} from 'document/DocumentListPage'
import {PurchaseApi} from './PurchaseApi'
import {MomentDate} from 'shared/date/MomentDate'
import {MoneyValue} from 'shared/components/MoneyValue'
import {IPurchase} from 'document/movement/purchase/IPurchase'

export function PurchasesListPage() {
  return (
    <DocumentListPage<IPurchase>
      title={'Arrivi merce'}
      link={'purchase'}
      newLinkText={'Nuovo arrivo merce'}
      counterpartRole={'supplier'}
      crudApi={PurchaseApi}
      defaultPeriod={'current_year'}
      columns={[
        {
          renderHead: 'Data',
          renderCell: ({date}) => <MomentDate date={date}/>,
          minSpace: true,
        },
        {
          renderHead: 'Fornitore',
          renderCell: ({counterpart}) => counterpart.name,
        },
        {
          renderHead: 'Prezzo',
          renderCell: ({net}) => <MoneyValue value={net}/>,
          align: 'right',
          minSpace: true,
        },
      ]}
    />
  )
}
