import React, {useState} from 'react'
import styled, {css} from 'styled-components'
import {Checkbox, HTMLDivProps} from '@blueprintjs/core'
import {IListSelectionManager} from '../hooks/useListSelectionManager'
import {LoadingPanel} from '../components/LoadingPanel'

export interface IListProps<T> extends HTMLDivProps {
  items: T[]
  renderItem: (item: T, options?: { index?: number, isMouseOver?: boolean }) => JSX.Element | string
  interactive?: boolean
  itemRowProps?: (item, index) => HTMLDivProps
  selectionManager?: IListSelectionManager<T>
  loading?: boolean
  greyBackground?: boolean
}

export function List<T>(props: IListProps<T>) {
  const {
    items,
    renderItem,
    interactive,
    itemRowProps,
    selectionManager,
    loading,
    greyBackground,
    ...divProps
  } = props

  const [mouseOverIndex, setMouseOverIndex] = useState(null)

  const showSelectCheckbox = !!selectionManager

  const {
    isItemSelected,
    onItemSelectionChange,
  } = selectionManager || {}

  return (
    <ListTag
      onMouseLeave={() => setMouseOverIndex(null)}
      greyBackground={greyBackground}
      {...divProps}
    >
      {loading && <LoadingPanel/>}

      {
        items && items.map((item, index) => {
          const isMouseOver = mouseOverIndex === index
          const options = {index, isMouseOver}
          const isSelected = isItemSelected && isItemSelected(item)
          const onSelectionChange = onItemSelectionChange && onItemSelectionChange(item)
          const rowProps = itemRowProps && itemRowProps(item, index)

          return (<ListItemTag
            key={index}
            onMouseEnter={() => setMouseOverIndex(index)}
            interactive={interactive}
            selected={isSelected}
            {...rowProps}
          >
            {showSelectCheckbox && <CheckboxContainerTag onClick={e => e.stopPropagation()}>
              <Checkbox
                checked={isSelected}
                className='only-checkbox'
                onChange={onSelectionChange}
              />
            </CheckboxContainerTag>}

            {renderItem(item, options)}
          </ListItemTag>)
        })
      }
    </ListTag>
  )
}

const ListTag = styled.div<{ greyBackground?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: ${props => props.greyBackground && 'rgba(242,245,245,0.8)'};
`

const mouseOverCss = css`
  &:hover {
    box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
    z-index: 1;
    cursor: pointer;
  }
`

export const ListItemTag = styled.div<{ interactive?: boolean, selected?: boolean }>`
  display: flex;
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
  padding: 8px;
  background-color: ${props => props.selected && '#c2dbff'};
  ${props => props.interactive && mouseOverCss}
`

export const CheckboxContainerTag = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
`
