import {MoneyUtils} from 'shared/utils/moneyUtils'
import createDecorator from 'final-form-calculate'
import {IDocument} from './IDocument'

const getTotalNet = items => MoneyUtils.round(items.reduce((tot, item) => MoneyUtils.round(tot + item.net, 5), 0), 5)
const getTotalGross = items => MoneyUtils.round(items.reduce((tot, item) => MoneyUtils.round(tot + item.gross, 2), 0), 2)

const createUpdateFunction = (fields: FieldsConfig[]) => (_, __, allValues: IDocument) => {
  const net = fields.reduce((carry, {field, direction}) => carry + getTotalNet(allValues[field] || []) * direction, 0)
  const gross = fields.reduce((carry, {
    field,
    direction,
  }) => carry + getTotalGross(allValues[field] || []) * direction, 0)

  return {
    net: MoneyUtils.round(net, 5),
    gross: MoneyUtils.round(gross, 2),
    vat: MoneyUtils.round(gross - net, 5),
  }
}

interface FieldsConfig {
  field: string,
  direction: 1 | -1
}

export function createDocumentMoneysDecorator(fields: FieldsConfig[]): any {
  const updates = createUpdateFunction(fields)
  return createDecorator(...fields.map(({field}) => ({field, updates})))
}
