import React from 'react'
import {GoodItemListRow} from '../GoodItemListRow'
import {IOrderGoodItem} from './IOrderGoodItem'
import {MarginedContent} from 'shared/styled/InlineGroup'
import {Intent, Tag} from '@blueprintjs/core'

interface IOrderGoodItemListRowProps {
  item: IOrderGoodItem
  isGrossMode: boolean
}

export function OrderStatus({item}: { item: IOrderGoodItem }) {
  const dismissed = +item.dismissed_quantity / +item.quantity * 100
  const delivered = +item.delivered_quantity / +item.quantity * 100
  const pending = +item.quantity - +item.dismissed_quantity - +item.delivered_quantity

  return (
    <MarginedContent>
      {!!dismissed && <Tag
        minimal={true}
        intent={Intent.DANGER}
      >{item.dismissed_quantity} annullati</Tag>}
      {!!delivered && <Tag
        minimal={true}
        intent={Intent.SUCCESS}
      >{item.delivered_quantity} consegnati</Tag>}
      {!!pending && <Tag
        minimal={true}
      >{pending} da consegnare</Tag>}
    </MarginedContent>
  )
}

export function OrderGoodItemListRow(props: IOrderGoodItemListRowProps) {
  const {
    item,
    isGrossMode,
  } = props

  return (
    <GoodItemListRow
      item={item}
      isGrossMode={isGrossMode}
    />
  )
}
