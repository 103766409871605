import React, {useCallback, useMemo, useState} from 'react'
import FilterValueTag from 'shared/filters/FilterValueTag'
import InlineMultiSelectAsync from 'shared/inputsV2/select/InlineMultiSelectAsync'
import {ValuesTagRender} from 'shared/filters/filtersTag/FilterSelectEntity'
import {AttributeApi, IAttribute} from 'productAttributes/attribute'
import {IRenderTagProps} from 'shared/filters/InputFilters'

interface IFilterSelectAttributeProps extends IRenderTagProps {
  attribute: IAttribute
}

function FilterSelectAttribute(props: IFilterSelectAttributeProps) {
  const {
    value,
    onChange,
    onRemove,
    onClose,
    isNew,
    attribute,
  } = props

  const [selected, setSelected] = useState<string[]>()
  const arrayValue = useMemo(() => Array.isArray(value?.value) ? value.value : [], [value])
  const getter = useCallback(search => AttributeApi.getValues(attribute.id, search), [attribute])

  const onConfirm = () => {
    onChange({op: 'in', value: selected})
  }

  const content = () =>
    <InlineMultiSelectAsync<string>
      value={selected}
      onChange={setSelected}
      itemsGetter={getter}
      renderItem={v => v}
    />

  const renderValue = <ValuesTagRender
    label={attribute.name}
    values={arrayValue}
  />

  return (
    <FilterValueTag
      renderValue={renderValue}
      onRemove={onRemove}
      isNew={isNew}
      renderPopoverContent={content}
      onConfirm={onConfirm}
      onClose={onClose}
      onOpening={() => setSelected(arrayValue)}
      confirmDisabled={!selected?.length}
    />
  )
}

export default FilterSelectAttribute
