import {Omnibar} from '@blueprintjs/select'
import React from 'react'
import {Menu, MenuItem, Spinner} from '@blueprintjs/core'
import {IResponsePaginated} from '../api/hooks/useApiListResponse'
import {ItemRenderer} from '@blueprintjs/select/src/common/itemRenderer'
import {useSearchApiList} from '../hooks/useSearchApiList'

interface IAsyncOmnibarProps<T> {
  isOpen: boolean
  onItemSelect: (item: T) => any
  onClose: () => void
  itemsGetter: (search: string) => Promise<IResponsePaginated<T>>
  itemRenderer: ItemRenderer<T>
  inputPlaceholder?: string
  noResultsText?: string
}

export function AsyncOmnibar<T>(props: IAsyncOmnibarProps<T>) {
  const {
    isOpen,
    onItemSelect,
    onClose,
    itemsGetter,
    itemRenderer,
    inputPlaceholder = 'Cerca...',
    noResultsText = 'Nessun elemento trovato',
  } = props

  const {
    search,
    setSearch,
    response,
    loading,
    remaining,
    noResults,
  } = useSearchApiList(itemsGetter)

  const _onClose = () => {
    setSearch('')
    onClose()
  }

  const inputProps = {
    placeholder: inputPlaceholder,
    rightElement: loading && <Spinner size={15}/>,
  }

  const items = response ? response.data : []

  const renderMoreResults = !!remaining && <MenuItem
    disabled={true}
    text={'Ci sono altri ' + (remaining) + ' risultati'}
  />

  const renderNoResults = noResults && <MenuItem text={noResultsText} disabled={true}/>

  return <Omnibar<T>
    isOpen={isOpen}
    onItemSelect={onItemSelect}
    onClose={_onClose}
    query={search}
    onQueryChange={setSearch}
    resetOnSelect={true}
    items={items}
    inputProps={inputProps}
    itemRenderer={itemRenderer}
    itemListRenderer={({items, renderItem}) => <Menu>
      {items.map((item, index) => renderItem(item, index))}
      {renderMoreResults}
      {renderNoResults}
    </Menu>}
  />
}
