import React, {useContext, useEffect} from 'react'
import {Redirect} from 'react-router'
import {useApiListResponse} from 'shared/api/hooks/useApiListResponse'
import {CompanyApi} from './CompanyApi'
import {LoadingState} from 'shared/components/LoadingState'
import {Form} from 'react-final-form'
import {Button} from '@blueprintjs/core'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {Intent} from '@blueprintjs/core/lib/esm/common/intent'
import {CompanyContext} from './CompanyState'
import {useApiFormSubmitter} from 'shared/api/hooks/useApiFormSubmitter'
import {PageContainer} from 'shared/components/PageContainer'
import {ICompany} from './ICompany'
import {Input} from 'shared/inputsV2/Input'

function CompanyCreator({onCreated}) {
  const {onSubmit, loading} = useApiFormSubmitter(CompanyApi.create, onCreated)

  return (
    <PageContainer maxWidth={'400px'}>
      <Form
        onSubmit={onSubmit}
        render={({handleSubmit}) =>
          <form onSubmit={handleSubmit}>
            <h1>Crea il tuo negozio</h1>

            <FieldFormGroup
              label={'Ragione sociale'}
              name={'name'}
              required={true}
              render={(props) => <Input
                {...props}
                autoFocus={true}
                large={true}
                placeholder={'Mio negozio SRL'}
              />}
            />

            <Button
              text={'Procedi'}
              type={'submit'}
              intent={Intent.PRIMARY}
              large={true}
              loading={loading}
              fill={true}
            />
          </form>
        }
      />
    </PageContainer>
  )
}

function CompaniesManager({selectCompany}) {
  const {response} = useApiListResponse<ICompany>(CompanyApi.getList)

  useEffect(() => {
    if (response && response.total > 0)
      selectCompany(response.data[0].id)
  }, [response, selectCompany])

  if (!response)
    return <LoadingState/>

  if (response.total === 0)
    return <CompanyCreator onCreated={company => setTimeout(() => selectCompany(company.id), 0)}/>

  return null
}

export function CompaniesPage(props) {
  const appContext = useContext(CompanyContext)

  if (!!appContext.company)
    return <Redirect to='/'/>

  return (
    <CompaniesManager
      selectCompany={appContext.changeCompanyById}
    />
  )
}
