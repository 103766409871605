import {NavLink} from 'react-router-dom'
import {Classes, Icon} from '@blueprintjs/core'
import React from 'react'
import {IconName} from '@blueprintjs/icons'

interface IMenuItemLinkProps {
  to: string
  icon: IconName
  text: string
}

export function MenuItemLink(props: IMenuItemLinkProps) {
  const {to, icon, text} = props

  const handleLinkClick = (e) => e.target.parentElement.parentElement.click()

  return (
    <li className={Classes.POPOVER_DISMISS}>
      <NavLink
        className={Classes.MENU_ITEM}
        activeClassName={Classes.ACTIVE}
        onClick={handleLinkClick}
        to={to}
      >
        <Icon icon={icon}/>
        <div className={Classes.TEXT_OVERFLOW_ELLIPSIS + ' ' + Classes.FILL}>{text}</div>
      </NavLink>
    </li>
  )
}
