import {useCallback, useState} from 'react'
import {ICrudApi} from '../api/CrudApi'
import {IEntity} from '../types/IEntity'
import {useApiFormSubmitter} from '../api/hooks/useApiFormSubmitter'

export default function useEntityActions<T extends IEntity>(entity: T, api: ICrudApi<T>, callbacks?) {
  const [deleting, setDeleting] = useState<boolean>(false)
  const id = entity && entity.id
  const isNew = !id

  const {onSaved, onDeleted} = callbacks || {}

  const saveApi = value => isNew ? api.create(value) : api.update(id, value)
  const submitter = useApiFormSubmitter(saveApi, onSaved)

  const deleteItem = useCallback(() => {
    if (isNew)
      return

    setDeleting(true)
    return api.delete(id)
      .then(response => {
        if (onDeleted)
          setTimeout(() => onDeleted(response), 0)
        return response
      })
      .finally(() => setDeleting(false))
  }, [isNew, id, api, onDeleted])

  return {
    isNew,
    deleting,
    deleteItem,
    saving: submitter.loading,
    formSubmit: submitter.onSubmit,
  }
}
