import React, { useMemo, useState } from 'react'
import { TabId } from '@blueprintjs/core'
import moment from 'moment'
import { PurchaseApi } from './PurchaseApi'
import { useApiFormSubmitter } from 'shared/api/hooks/useApiFormSubmitter'
import { FullscreenFormStepper, IFormStep } from 'shared/form/FullscreenFormStepper'
import { Validators } from 'shared/form/Validators'
import { DocumentFormData } from '../../DocumentFormData'
import { DocumentTotalsStepperSection } from '../../DocumentTotalsStepperSection'
import { IPurchase } from './IPurchase'
import { createDocumentMoneysDecorator } from '../../createDocumentMoneysDecorator'
import arrayMutators from 'final-form-arrays'
import { GoodsItemsFormSection } from '../../GoodsItemsFormSection'
import { DocumentFormActionsButton } from '../../DocumentFormActionsButton'
import { useItemsFormSectionState } from '../../ItemsFormSection'
import { IOrderGoodItem } from '../../order/IOrderGoodItem'
import { GoodItemForm } from '../GoodItemForm'
import { MoneysItemsFormSection } from '../MoneysItemsFormSection'
import IProduct from '../../../product/IProduct'
import { IPurchaseGoodItem } from './IPurchaseGoodItem'
import { discountOption, vatOption } from '../../massUpdateOptions'
import { useFormState } from 'react-final-form'
import { GoodItemListRow } from '../../GoodItemListRow'

const decorators = createDocumentMoneysDecorator([
  {field: 'goods_items', direction: 1},
  {field: 'moneys_items', direction: 1},
])

export function PurchaseForm({initialValues, isNew, onDismiss, onSaved}) {
  const [isGrossMode, setIsGrossMode] = useState<boolean>(false)
  const [step, setStep] = useState<TabId>(isNew ? 'data' : 'items')
  const {onSubmit, loading} = useApiFormSubmitter(PurchaseApi.save, onSaved)

  const orderSteps = useMemo<IFormStep<IPurchase>[]>(() => [
    {
      id: 'data',
      title: 'Dati',
      validate: ({counterpart}) => ({
        counterpart: Validators.required(counterpart),
      }),
      render: () => <DocumentFormData
        counterpartRole={'supplier'}
        counterpartRequired={true}
      />,
    },
    {
      id: 'items',
      title: 'Articoli',
      render: () => <ItemsStep
        isGrossMode={isGrossMode}
        setIsGrossMode={setIsGrossMode}
      />,
      validate: ({goods_items}) => ({
        items: Validators.arrayMinLength(1)(goods_items),
      }),
      renderFooter: ({values}) => <DocumentTotalsStepperSection
        movement={values}
        isGrossMode={isGrossMode}
      />,
      nextButtonText: 'Salva',
    },
  ], [isGrossMode])

  const defaultValues = useMemo(() => ({
    date_time: moment().format('YYYY-MM-DD HH:mm:ss'),
  }), [])

  return <FullscreenFormStepper
    formProps={{
      mutators: {...arrayMutators},
      decorators: [decorators],
      initialValues: initialValues || defaultValues,
    }}
    title={isNew ? 'Nuovo arrivo merce' : 'Modifica arrivo merce'}
    selectedTabId={step}
    onTabIdChange={setStep}
    steps={orderSteps}
    onSubmit={onSubmit}
    onClose={onDismiss}
    submitLoading={loading}
  />
}

function ItemsStep({isGrossMode, setIsGrossMode}) {
  const goodsItemsState = useItemsFormSectionState<IOrderGoodItem>('goods_items')
  const moneysItemsState = useItemsFormSectionState<IOrderGoodItem>('moneys_items')
  const {values} = useFormState()

  const [lastValues, setLastValues] = useState<any>()

  const productToGoodItem = (product: IProduct): IPurchaseGoodItem => ({
    quantity: 1,
    vat: product.vat,
    ...lastValues,
    product,
  })

  return (
    <>
      <DocumentFormActionsButton
        onSelected={product => goodsItemsState.onNewItem(productToGoodItem(product))}
        isGrossMode={isGrossMode}
        setIsGrossMode={setIsGrossMode}
        date={values.date_time}
        actions={[
          {
            icon: 'add',
            label: 'Crea un nuovo prodotto',
            action: goodsItemsState.onNewItem,
          }, {
            icon: 'euro',
            label: 'Modifica il totale',
            action: moneysItemsState.onNewItem,
          },
        ]}
      />

      <GoodsItemsFormSection
        isGrossMode={isGrossMode}
        state={goodsItemsState}
        productToGoodItem={productToGoodItem}
        onDocumentGoodItemSubmit={({unit_net, vat}) => setLastValues({unit_net, vat})}
        renderItemForm={<GoodItemForm
          isGrossMode={isGrossMode}
          movLabel={'acquisto'}
        />}
        massUpdateOptions={[vatOption, discountOption]}
        renderItem={item => <GoodItemListRow
          item={item}
          isGrossMode={isGrossMode}
        />}
      />

      <MoneysItemsFormSection
        isGrossMode={isGrossMode}
        state={moneysItemsState}
      />
    </>
  )
}
