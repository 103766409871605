import {createCrudApi} from 'shared/api/CrudApi'
import IGetListParams from 'shared/api/hooks/IGetListParams'
import {myAxios} from 'shared/api/myAxios'

export const OrderApi = {
  ...createCrudApi('order'),

  getTotal: (params) => myAxios.get('order_total', {params}).then(({data}) => data),

  getPendingProducts: (params: IGetListParams) =>
    myAxios.get('order_pending', {params}).then(({data}) => data),
}
