import React, {useCallback} from 'react'
import {IResponsePaginated, useApiListResponse} from '../api/hooks/useApiListResponse'
import {InputSuggestString} from './InputSuggestString'
import {HTMLInputProps, IInputGroupProps} from '@blueprintjs/core'
import {useDebounce} from 'use-hooks'

interface IInputSuggestStringAsyncProps {
  value: string
  onChange: (value: string) => void
  itemsGetter: (search: string) => Promise<IResponsePaginated<string>>
  inputProps?: IInputGroupProps & HTMLInputProps
  placeholder?: string
  intent?
}

export function InputSuggestStringAsync(props: IInputSuggestStringAsyncProps) {
  const {
    value,
    onChange,
    itemsGetter,
    placeholder = 'Cerca...',
    intent,
  } = props

  const debouncedValue = useDebounce(value, 300)
  const getFunction = useCallback(() => itemsGetter(debouncedValue), [debouncedValue, itemsGetter])

  const {data} = useApiListResponse<string>(getFunction)

  return (
    <InputSuggestString
      items={data}
      value={value}
      onChange={onChange}
      inputProps={{
        onKeyDown: preventSubmit,
        placeholder,
        intent,
      }}
    />
  )
}

const preventSubmit = event => {
  if (event.keyCode === 13) {
    event.preventDefault()
    return false
  }
}
