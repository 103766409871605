import React, {useState} from 'react'
import FilterValueTag from 'shared/filters/FilterValueTag'
import {FormGroup, Icon} from '@blueprintjs/core'
import {Input} from 'shared/inputsV2/Input'
import {AppText} from 'shared/components/AppText'

interface IFilterSearchProps {
  value: string
  onChange: (value: string) => void
}

function FilterSearch(props: IFilterSearchProps) {
  const {
    value,
    onChange,
  } = props

  const onConfirm = () => onChange(inputValue)
  const [inputValue, setInputValue] = useState(value)

  const label = 'Cerca: '

  const content = ({closePopover}) =>
    <FormGroup
      label={label}
      style={{padding: '8px'}}
    >
      <Input
        value={inputValue}
        onChange={setInputValue}
        autoFocus={true}
        placeholder={'Valore'}
        onEnter={() => {
          if (inputValue.length) {
            onConfirm()
            closePopover()
          }
        }}
      />
    </FormGroup>

  return (
    <FilterValueTag
      renderValue={<AppText>
        <Icon icon={'search'}/>
        <span>Cerca: "{value}"</span>
      </AppText>}
      onRemove={() => onChange('')}
      isNew={false}
      renderPopoverContent={content}
      onConfirm={onConfirm}
      confirmDisabled={!inputValue.length}
    />
  )
}

export default FilterSearch
