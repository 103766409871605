import styled, {css} from 'styled-components'
import React from 'react'

const DividerTextTag = styled.div`
  margin: 12px 0 14px 0;
  width: 100%;
  display: block;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
`

const prova = css`
  background: #ccd0d5;
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  width: 9999px;
`

const DividerTextText = styled.span`
  display: inline-block;
  position: relative;

  &:before {
    margin-right: 15px;
    right: 100%;
    ${prova}
  }

  &:after {
    margin-left: 15px;
    left: 100%;
    ${prova}
  }
`

interface IDividerTextProps {
  text?: any
  children?: any
}

export function DividerText(props: IDividerTextProps) {
  const {
    text,
    children,
  } = props

  return (
    <DividerTextTag>
      <DividerTextText>{text || children}</DividerTextText>
    </DividerTextTag>
  )
}
