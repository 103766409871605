import React, {useCallback, useState} from 'react'
import {CounterpartPreview} from './CounterpartPreview'
import CounterpartApi from '../CounterpartApi'
import IGetListParams from 'shared/api/hooks/IGetListParams'
import ICounterpart, {CounterpartRole} from '../ICounterpart'
import CounterpartForm from './CounterpartForm'
import {Classes, Dialog} from '@blueprintjs/core'
import {IInputSelectAsyncProps, InputSelectAsync} from 'shared/inputsV2/select/InputSelectAsync'
import {useCounterpartLabel} from './useCounterpartLabel'

interface ICounterpartSelectProps extends Omit<IInputSelectAsyncProps<ICounterpart>, 'renderItem' | 'itemsGetter'> {
  role?: CounterpartRole,
}

export function CounterpartSelect(props: ICounterpartSelectProps) {
  const {
    role,
    ...entitySelectProps
  } = props

  const [initialValues, setInitialValues] = useState<ICounterpart>()
  const onDismiss = () => setInitialValues(null)

  const itemsGetter = useCallback(search => {
    const params: IGetListParams = {search}
    if (role)
      params.filters = [{field: 'role', op: '=', value: role}]
    return CounterpartApi.getList(params)
  }, [role])

  const counterpartLabel = useCounterpartLabel(role)

  const placeholder = entitySelectProps.required ?
    'Seleziona un ' + counterpartLabel :
    'Nessuno'

  return (
    <>
      <InputSelectAsync
        itemsEqual={'id'}
        placeholder={placeholder}
        itemsGetter={itemsGetter}
        renderItem={item => <CounterpartPreview item={item}/>}
        onNew={name => setInitialValues({name, role})}
        {...entitySelectProps}
      />

      <Dialog
        isOpen={!!initialValues}
        lazy={true}
        title={'Nuovo ' + counterpartLabel}
        onClose={onDismiss}
      >
        <CounterpartForm
          initialValues={initialValues}
          hideRoleSelector={!!role}
          onDismiss={onDismiss}
          onSaved={item => {
            setInitialValues(null)
            entitySelectProps.onChange(item)
          }}
          className={Classes.DIALOG_BODY}
        />
      </Dialog>
    </>
  )
}
