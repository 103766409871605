import React from 'react'
import {MenuItem} from '@blueprintjs/core'
import {useSearchApiList} from 'shared/hooks/useSearchApiList'
import InputSelect, {IInputSelectProps} from 'shared/inputsV2/select/InputSelect'

export interface IInputSelectAsyncProps<T> extends Omit<IInputSelectProps<T>, 'search' | 'setSearch' | 'items'> {
  itemsGetter
}

export function InputSelectAsync<T>(props: IInputSelectAsyncProps<T>) {
  const {
    itemsGetter,
    ...selectProps
  } = props

  const {
    search,
    setSearch,
    response,
    remaining,
    loading,
  } = useSearchApiList<T>(itemsGetter)

  const renderMoreResults = !!remaining && <MenuItem
    disabled={true}
    text={`Ci sono altri ${remaining} risultati`}
  />

  return (
    <InputSelect<T>
      items={response?.data}
      search={search}
      setSearch={setSearch}
      searchLoading={loading}
      renderAfterItems={renderMoreResults}
      {...selectProps}
    />
  )
}
