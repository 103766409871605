import {HTMLDivProps} from '@blueprintjs/core'
import React from 'react'
import styled from 'styled-components'
import {IDocumentGoodItem} from './IDocumentGoodItem'
import {DetailsViewer, IDetailsViewerItem} from 'shared/list/DetailsViewer'
import {PercentageValue} from 'shared/components/PercentageValue'
import {ListRow} from 'shared/list/ListRow'
import {MoneyValue} from 'shared/components/MoneyValue'
import {AppText} from 'shared/components/AppText'
import ProductAttributesList from 'product/components/ProductAttributesList'


interface IGoodItemListRowProps extends HTMLDivProps {
  item: IDocumentGoodItem
  isGrossMode: boolean
}

export function GoodItemListRow(props: IGoodItemListRowProps) {
  const {
    item,
    isGrossMode,
    ...divProps
  } = props

  const {
    product,
    vat,
    quantity,
    gross,
    net,
    discount,
    unit_net,
    unit_gross,
  } = item

  const total = isGrossMode ? gross : net
  const totalNoDiscount = (isGrossMode ? unit_gross : unit_net) * quantity

  const detailsConfig: IDetailsViewerItem[] = [
    {label: 'Qt', value: quantity},
    {label: 'IVA', value: vat ? vat.label : 'Nessuna'},
    {label: 'Sconto', value: <PercentageValue value={discount}/>, hidden: !discount},
  ]

  const topRight =
    <TotalTag>
      {!!discount && <DiscountValueTag>
        <MoneyValue value={totalNoDiscount}/>
      </DiscountValueTag>}
      <AppText
        intent={'primary'}
        bold={true}
        size={'XL'}
      >
        <MoneyValue value={total}/>
      </AppText>
    </TotalTag>

  const topLeft =
    <AppText preventNewLine={true}>
      <AppText size={'L'} bold={true}>{product.description}</AppText>
      <AppText muted={true}>{product.code}</AppText>
    </AppText>

  const bottomRight = <DetailsViewer items={detailsConfig}/>

  return (
    <ListRow
      {...divProps}
      topLeft={topLeft}
      topRight={topRight}
      bottomLeft={<ProductAttributesList values={product.attributes_values}/>}
      bottomRight={bottomRight}
    />
  )
}

const DiscountValueTag = styled.div`
  margin-right: 8px;
  text-decoration: line-through;
`

const TotalTag = styled.div`
  display: flex;
  align-items: center;
`
