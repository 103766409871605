import React, {useCallback, useMemo} from 'react'
import {DateRangePicker as BP3DateRangePicker} from '@blueprintjs/datetime'
import moment, {Moment} from 'moment'
import {DATE_FORMAT, dayPickerProps, DEFAULT_MAX_DATE, DEFAULT_MIN_DATE} from 'shared/date/date'

type DateType = string

interface IDateRangePickerProps {
  value: [DateType, DateType]
  onChange: (value: [DateType, DateType]) => void
  format?: string
  minDate?: string | Moment
  maxDate?: string | Moment
}

function DateRangePicker(props: IDateRangePickerProps) {
  const {
    value,
    onChange,
    minDate,
    maxDate,
    format = DATE_FORMAT,
  } = props

  const pickerValue: [Date, Date] = useMemo(() => {
    const notNullValue = value ? [value[0], value[1]] : [null, null]
    const momentValue = [moment(notNullValue[0], format), moment(notNullValue[1], format)]
    return [momentValue[0].isValid() ? momentValue[0].toDate() : null, momentValue[1].isValid() ? momentValue[1].toDate() : null]
  }, [value, format])

  const onPickerChange = useCallback<(value: [Date, Date]) => void>(
    (value) => {
      const momentValue = [moment(value[0]), moment(value[1])]
      onChange([momentValue[0].isValid() ? momentValue[0].format(format) : null, momentValue[1].isValid() ? momentValue[1].format(format) : null])
    },
    [onChange, format],
  )

  const pickerMinDate = useMemo(() => convertDate(minDate || DEFAULT_MIN_DATE), [minDate])

  const pickerMaxDate = useMemo(() => convertDate(maxDate || DEFAULT_MAX_DATE), [maxDate])

  return (
    <BP3DateRangePicker
      value={pickerValue}
      onChange={onPickerChange}
      minDate={pickerMinDate}
      maxDate={pickerMaxDate}
      shortcuts={null}
      dayPickerProps={dayPickerProps}
    />
  )
}

function convertDate(value: Moment | string) {
  const momentValue = moment.isMoment(value) ? value : moment(value)
  return momentValue.toDate()
}

export default DateRangePicker
