import React from 'react'
import styled from 'styled-components'

const AvatarImage = styled.img`
  vertical-align: middle;
  width: 100%;
`

interface IAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string,
  large?: boolean
  small?: boolean
}

function Avatar(props: IAvatarProps) {
  const {
    src,
    large,
    small,
    ...divProps
  } = props

  return (
    <div {...divProps}>
      {src && <AvatarImage
        src={src}
        alt='avatar'
      />}
    </div>
  )
}

export default styled(Avatar)`
  display: block;
  border-radius: 50%;
  overflow: hidden;

  ${props => {
    const dim = (props.small && 24) || (props.large && 40) || 30
    return {
      height: dim + 'px',
      width: dim + 'px',
    }
  }}
`
