import React from 'react'
import {OrderForm} from './OrderForm'
import {useEntityPage} from 'shared/hooks/useEntityPage'
import {IOrder} from './IOrder'
import {OrderApi} from './OrderApi'
import {LoadingState} from 'shared/components/LoadingState'
import {ErrorState} from 'shared/components/ErrorState'
import PageHeader from 'shared/components/PageHeader'
import {Button, ButtonGroup, Menu, MenuItem, Popover} from '@blueprintjs/core'
import {OrderView} from './OrderView'
import useEntityActions from 'shared/hooks/useEntityActions'

export function OrderPage(props) {
  const {
    entity,
    error,
    loading,
    onSaved,
    editing,
    setEditing,
    onDismiss,
    goBack,
    isNew,
  } = useEntityPage<IOrder>('order', OrderApi)


  const {deleteItem} = useEntityActions(entity, OrderApi, {onDeleted: goBack})

  if (loading)
    return <LoadingState/>
  if (error)
    return <ErrorState/>

  const buttonGroup = <ButtonGroup>
    <Button
      text={'Modifica'}
      onClick={() => setEditing(true)}
    />
    <Popover content={<Menu>
      <MenuItem
        icon={'trash'}
        text={'Elimina'}
        onClick={deleteItem}
      />
    </Menu>}>
      <Button icon={'more'}/>
    </Popover>
  </ButtonGroup>

  return (
    <div>
      <PageHeader
        title={entity && ('Ordine a ' + entity.counterpart.name)}
        rightElement={buttonGroup}
      />

      {editing ?
        <OrderForm
          initialValues={entity}
          isNew={isNew}
          onSaved={onSaved}
          onDismiss={onDismiss}
        /> :
        <OrderView
          order={entity}
        />
      }
    </div>
  )
}
