import React, {useContext, useMemo, useState} from 'react'
import {Form} from 'react-final-form'
import {Button, Intent} from '@blueprintjs/core'
import {myAxios} from 'shared/api/myAxios'
import {Validators} from 'shared/form/Validators'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {Link} from 'react-router-dom'
import {TokenContext} from './TokenState'
import {FieldStrongPassword} from './FieldStrongPassword'
import {FakeLink} from 'shared/styled/FakeLink'
import {useApiFormSubmitter} from 'shared/api/hooks/useApiFormSubmitter'
import {AuthApi} from './AuthApi'
import {Input} from 'shared/inputsV2/Input'

const checkEmail = email => myAxios.post('check_email', {email})
  .then(() => null)
  .catch(error => error.data.data.email)

function EmailStep({email, onSubmit}) {
  return <Form
    initialValues={{email}}
    onSubmit={onSubmit}
    render={({handleSubmit, form}) => {
      const emailStatus = form.getFieldState('email')
      return (<form onSubmit={handleSubmit}>
        <FieldFormGroup
          label={'Email'}
          name={'email'}
          required={true}
          validators={[Validators.email, checkEmail]}
          render={(params, {meta}) => <Input
            {...params}
            autoFocus={true}
            autoComplete={true}
            placeholder={'Inserisci qui la tua email'}
            loading={meta.validating}
            large={true}
          />}
        />

        <Button
          disabled={emailStatus && (emailStatus.invalid || emailStatus.validating)}
          type={'submit'}
          fill={true}
          large={true}
          text={'Prosegui'}
          intent={Intent.PRIMARY}
        />

        <div style={{marginTop: '8px'}}>
          <Link to={'/login'}>Ho già un account</Link>
        </div>
      </form>)
    }}
  />
}

function PasswordStep({onSubmit, loading, goBack}) {
  return <Form
    onSubmit={onSubmit}
    render={({handleSubmit, invalid}) => {
      return (<form onSubmit={handleSubmit}>
        <FieldStrongPassword
          name={'password'}
          label={'Password'}
          large={true}
          autoFocus={true}
          placeholder={'Scegli una password'}
        />

        <Button
          disabled={invalid}
          type={'submit'}
          fill={true}
          large={true}
          text={'Conferma'}
          intent={Intent.PRIMARY}
          loading={loading}
        />

        <div style={{marginTop: '8px'}}>
          <FakeLink onClick={goBack}>Torna indietro</FakeLink>
        </div>
      </form>)
    }}
  />
}

export function RegisterPage() {
  const {setToken} = useContext(TokenContext)
  const [step, setStep] = useState('email')
  const [email, setEmail] = useState()
  const {onSubmit, loading} = useApiFormSubmitter(AuthApi.register, ({token}) => setToken(token))

  const currentRender = useMemo(() => {
    switch (step) {
      case 'email':
        return <EmailStep
          email={email}
          onSubmit={({email}) => {
            setEmail(email)
            setStep('password')
          }}
        />
      case 'password':
        return <PasswordStep
          loading={loading}
          goBack={() => {
            setStep('email')
          }}
          onSubmit={({password}) => onSubmit({email, password})}
        />
    }
  }, [loading, onSubmit, step, email])

  return (
    <div>
      <h1>Registrati</h1>

      {currentRender}
    </div>
  )
}
