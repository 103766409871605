import {InlineGroup} from 'shared/styled/InlineGroup'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {Validators} from 'shared/form/Validators'
import {InputVat} from 'shared/inputs/InputVat'
import {InputMoney} from 'shared/inputsV2/InputMoney'
import React from 'react'
import {Input} from 'shared/inputsV2/Input'

export function OrderItemForm({isGrossMode}) {
  const priceFieldProps = isGrossMode ?
    {name: 'unit_gross', label: 'Prezzo di acquisto lordo'} :
    {name: 'unit_net', label: 'Prezzo di acquisto netto'}

  return <>
    <InlineGroup>
      <FieldFormGroup
        name={'quantity'}
        label='Quantità'
        required={true}
        validators={[Validators.min(1)]}
        render={input => <Input type={'number'} {...input}/>}
      />

      <FieldFormGroup
        name={'vat'}
        label='IVA'
        render={input => <InputVat {...input}/>}
      />
    </InlineGroup>

    <FieldFormGroup
      {...priceFieldProps}
      required={true}
      validators={[Validators.min(0)]}
      render={input => <InputMoney {...input} autoFocus={true}/>}
    />

    <InlineGroup>
      <FieldFormGroup
        label={'Consegnati'}
        name={'delivered_quantity'}
        validators={[Validators.min(0)]}
        render={input => <Input type={'number'} {...input}/>}
      />

      <FieldFormGroup
        label={'Annullati'}
        name={'dismissed_quantity'}
        validators={[Validators.min(0)]}
        render={input => <Input type={'number'} {...input}/>}
      />
    </InlineGroup>
  </>
}
