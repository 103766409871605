import React, {useMemo, useState} from 'react'
import FilterValueTag from 'shared/filters/FilterValueTag'
import {IRenderTagProps} from 'shared/filters/InputFilters'
import {FormGroup} from '@blueprintjs/core'
import {Input} from 'shared/inputsV2/Input'

interface IFilterStringProps extends IRenderTagProps {

}

function FilterString(props: IFilterStringProps) {
  const {
    value,
    onChange,
    onRemove,
    onClose,
    isNew,
    name,
  } = props

  const onConfirm = () => {
    onChange({
      op: 'like',
      value: inputValue,
    })
  }

  const filterValue = useMemo(() => value?.value || '', [value])
  const [inputValue, setInputValue] = useState(filterValue)

  const label = name + ' contiene'

  const content = ({closePopover}) =>
    <FormGroup
      label={label}
      style={{padding: '8px'}}
    >
      <Input
        value={inputValue}
        onChange={setInputValue}
        autoFocus={true}
        placeholder={'Valore'}
        onEnter={() => {
          if (inputValue.length) {
            onConfirm()
            closePopover()
          }
        }}
      />
    </FormGroup>

  return (
    <FilterValueTag
      renderValue={label + (filterValue ? (': "' + filterValue + '"') : '')}
      onRemove={onRemove}
      isNew={isNew}
      renderPopoverContent={content}
      onConfirm={onConfirm}
      onClose={onClose}
      confirmDisabled={!inputValue.length}
    />
  )
}

export default FilterString
