import React from 'react'
import styled, {css} from 'styled-components'
import {Position} from '@blueprintjs/core'

type Dimension = 'XS' | 'S' | 'M' | 'L' | 'XL'


interface IRowProps extends React.HTMLAttributes<HTMLDivElement> {
  childrenMargin?: boolean | Dimension
  contentPosition?: Position
}

const marginMap = {
  'XS': 2,
  'S': 4,
  'M': 8,
  'L': 20,
  'XL': 24,
}

const getChildrenMargin = (margin) => {
  if (!margin)
    return 0

  if (margin === true)
    return marginMap.M

  return marginMap[margin]
}

function getJustifyContent(position: Position): string {
  switch (position) {
    case 'left':
    case 'bottom-left':
    case 'left-bottom':
    case 'left-top':
    case 'top-left':
      return 'flex-end'

    case 'right':
    case 'top-right':
    case 'right-top':
    case 'right-bottom':
    case 'bottom-right':
      return 'flex-start'

    case 'top':
    case 'bottom':
      return 'center'
  }
}

function getAlignItems(position: Position): string {
  switch (position) {
    case 'top':
    case 'top-right':
    case 'top-left':
    case 'right-top':
    case 'left-top':
      return 'flex-start'

    case 'bottom':
    case 'bottom-right':
    case 'bottom-left':
    case 'right-bottom':
    case 'left-bottom':
      return 'flex-end'

    case 'right':
    case 'left':
      return 'center'
  }
}

function Row(props: IRowProps) {
  const {
    childrenMargin,
    contentPosition,
    ...divProps
  } = props

  return (
    <RowTag
      childrenMargin={getChildrenMargin(childrenMargin)}
      justifyContent={getJustifyContent(contentPosition)}
      alignItems={getAlignItems(contentPosition)}
      {...divProps}
    />
  )
}

const childrenMarginStyle = n => css`
  margin: 0 -${n}px;

  > * {
    margin: ${n}px;
  }
`

const RowTag = styled.div<{
  childrenMargin: number
  justifyContent: string
  alignItems: string
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${({childrenMargin}) => childrenMargin ? childrenMarginStyle(childrenMargin) : null};
  justify-content: ${({justifyContent}) => justifyContent};
  align-items: ${({alignItems}) => alignItems};
`

export default Row
