import React from 'react'
import {Button, ControlGroup, Menu, MenuItem, Popover, Tooltip} from '@blueprintjs/core'
import {ISortParam} from 'shared/api/hooks/IGetListParams'

export interface ISortOption {
  label: string,
  sortBy: string,
}

interface ISortSelectorProps {
  options: ISortOption[]
  value: ISortParam
  onChange: (value: ISortParam) => void
}

export function SortSelector(props: ISortSelectorProps) {
  const {
    options,
    value,
    onChange,
  } = props

  const {sortBy, sortDir} = value
  const actualSort = options.find(({sortBy}) => sortBy === value.sortBy)

  const isSortAsc = sortDir === 'asc'
  const setSortBy = by => onChange({sortBy: by, sortDir})
  const toggleSortDir = () => onChange({sortBy, sortDir: isSortAsc ? 'desc' : 'asc'})
  const oneOption = options.length <= 1

  return (
    <ControlGroup>
      <Popover
        disabled={oneOption}
        content={<Menu>
          {options.map((option) => <MenuItem
            key={option.sortBy}
            text={option.label}
            onClick={() => setSortBy(option.sortBy)}
            active={sortBy === option.sortBy}
          />)}
        </Menu>}
      >
        <Button
          text={actualSort ? actualSort.label : 'Ordina per...'}
          rightIcon={oneOption ? null : 'chevron-down'}
        />
      </Popover>

      <Tooltip
        content={'Ordine ' + (isSortAsc ? 'descrescente' : 'crescente')}
      >
        <Button
          disabled={!sortBy}
          icon={isSortAsc ? 'sort-asc' : 'sort-desc'}
          onClick={toggleSortDir}
        />
      </Tooltip>
    </ControlGroup>
  )
}
