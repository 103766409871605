import axios from 'axios'
import {environment} from '../environment'
import {MyToasts} from '../utils/appToaster'

export const myAxios = axios.create({
  baseURL: environment.apiEndpoint,
  headers: {
    'Content-Language': 'it',
  },
})

myAxios.interceptors.response.use(
  response => {
    const message = response.data.message
    if (message)
      MyToasts.showSuccess(message)

    return response
  },
  error => {
    const response = error.response

    const message = response && response.data && response.data.message
    if (message)
      MyToasts.showError(message)

    return Promise.reject(response)
  },
)
