import {IResponse, useApiResponse} from './useApiResponse'

export interface IResponsePaginated<T> extends IResponse <T[]> {
  current_page?: number
  first_page_url?: string
  from?: number
  last_page?: number
  last_page_url?: string
  next_page_url?: string
  path?: string
  per_page?: number
  prev_page_url?: string
  to?: number
  total?: number
}

export interface IApiListResponse<T> {
  loading: boolean
  error: any
  reload: () => void
  data: T[]
  total: number
  from: number
  to: number
  remaining: number
  page: number
  lastPage: number
  noResults: boolean
  response: IResponsePaginated<T>
  reset
}

export function useApiListResponse<T = any>(api: () => Promise<IResponsePaginated<T>>): IApiListResponse<T> {
  const {
    response,
    loading,
    error,
    reload,
    reset,
  } = useApiResponse<IResponsePaginated<T>>(api)

  const {
    current_page,
    total,
    data,
    last_page,
    from,
    to,
  } = response || {}

  const noResults = !loading && !!response && data.length === 0
  const remaining = response && response.total - response.to

  return {
    loading,
    error,
    reload,
    data,
    total,
    from,
    to,
    remaining,
    page: current_page,
    lastPage: last_page,
    noResults,
    response,
    reset,
  }
}
