import {DayPickerProps} from 'react-day-picker'
import moment from 'moment'
import 'moment/locale/it'

export const dayPickerProps: DayPickerProps = {
  months: moment.months(),
  weekdaysLong: moment.weekdays(),
  weekdaysShort: moment.weekdaysMin(),
  firstDayOfWeek: 1,
}

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const DEFAULT_MAX_DATE = moment().add(1, 'year')
export const DEFAULT_MIN_DATE = moment('2015-01-01')
