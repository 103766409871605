import React from 'react'
import {DocumentDataView} from '../../DocumentDataView'
import {ItemsViewSection} from '../../ItemsViewSection'
import {GoodItemListRow} from '../../GoodItemListRow'
import {CardsList} from 'shared/styled/CardsList'
import {MoneyItemListRow} from '../MoneyItemListRow'
import {IPurchase} from './IPurchase'

interface IPurchaseViewProps {
  purchase: IPurchase
}

export function PurchaseView(props: IPurchaseViewProps) {
  const {purchase} = props

  return (
    <CardsList>
      <DocumentDataView
        document={purchase}
        counterpartRole={'supplier'}
      />

      <ItemsViewSection
        title={'Articoli'}
        items={purchase.goods_items}
        renderItem={item => <GoodItemListRow item={item} isGrossMode={false}/>}
      />

      <ItemsViewSection
        title={'Modifiche al totale'}
        items={purchase.moneys_items}
        renderItem={item => <MoneyItemListRow item={item} isGrossMode={false}/>}
      />
    </CardsList>
  )
}
