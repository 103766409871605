import React, {useState} from 'react'
import {Button, Classes, Divider, Popover, Position, Tag} from '@blueprintjs/core'
import styled from 'styled-components'

interface IFilterValueTagProps {
  renderValue: JSX.Element | string
  onRemove: () => void
  renderPopoverContent: (options) => JSX.Element
  onConfirm
  onClose?
  onOpening?
  isNew: boolean
  loading?: boolean
  confirmDisabled: boolean
}

function FilterValueTag<T>(props: IFilterValueTagProps) {
  const {
    onRemove,
    renderPopoverContent,
    renderValue,
    onConfirm,
    onClose,
    onOpening,
    isNew,
    loading,
    confirmDisabled,
  } = props

  const [isOpen, setIsOpen] = useState<boolean>(isNew)

  const handlePopoverInteraction = (nextOpenState) => {
    setIsOpen(nextOpenState)
  }

  const handleTagRemove = onRemove ? e => {
    e.stopPropagation()
    onRemove()
  } : null

  const handleConfirmClick = () => {
    onConfirm()
    setIsOpen(false)
  }

  const closePopover = () => {
    setIsOpen(false)
  }

  const content = <div>
    {renderPopoverContent({closePopover})}
    <Divider/>
    <ConfirmButtonContainer>
      <Button
        onClick={handleConfirmClick}
        text={'Conferma'}
        minimal={true}
        intent={'primary'}
        disabled={confirmDisabled}
      />
    </ConfirmButtonContainer>
  </div>

  return (
    <Popover
      isOpen={isOpen}
      onInteraction={handlePopoverInteraction}
      content={content}
      hasBackdrop={true}
      position={Position.BOTTOM}
      popoverClassName={'input-filters-popover'}
      onClose={onClose}
      onOpening={onOpening}
    >
      <Tag
        minimal={true}
        interactive={true}
        onRemove={handleTagRemove}
        className={loading && Classes.SKELETON}
      >{renderValue}</Tag>
    </Popover>
  )
}

export default FilterValueTag

const ConfirmButtonContainer = styled.div`
  padding: 0 5px 5px 5px;
  text-align: right;
`
