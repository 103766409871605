import classNames from 'classnames'
import * as React from 'react'

import {
  AbstractPureComponent2,
  Classes as CoreClasses,
  DISPLAYNAME_PREFIX,
  IPopoverProps,
  ITagInputProps,
  Keys,
  TagInput,
  TagInputAddMethod,
} from '@blueprintjs/core'
import {Classes, IListItemsProps, IQueryListRendererProps, QueryList} from '@blueprintjs/select'


// N.B. selectedItems should really be a required prop, but is left optional for backwards compatibility

export interface IInlineMultiSelectProps<T> extends IListItemsProps<T> {
  /**
   * Whether the component should take up the full width of its container.
   * This overrides `popoverProps.fill` and `tagInputProps.fill`.
   */
  fill?: boolean;

  /**
   * Callback invoked when an item is removed from the selection by
   * removing its tag in the TagInput. This is generally more useful than
   * `tagInputProps.onRemove`  because it receives the removed value instead of
   * the value's rendered `ReactNode` tag.
   *
   * It is not recommended to supply _both_ this prop and `tagInputProps.onRemove`.
   */
  onRemove?: (value: T, index: number) => void;

  /**
   * If true, the component waits until a keydown event in the TagInput
   * before opening its popover.
   *
   * If false, the popover opens immediately after a mouse click focuses
   * the component's TagInput.
   *
   * N.B. the behavior of this prop differs slightly from the same one
   * in the Suggest component; see https://github.com/palantir/blueprint/issues/4152.
   *
   * @default false
   */
  openOnKeyDown?: boolean;

  /**
   * Input placeholder text. Shorthand for `tagInputProps.placeholder`.
   *
   * @default "Search..."
   */
  placeholder?: string;

  /** Props to spread to `Popover`. Note that `content` cannot be changed. */
  // eslint-disable-next-line @typescript-eslint/ban-types
  popoverProps?: Partial<IPopoverProps> & object;

  /** Controlled selected values. */
  selectedItems?: T[];

  /** Props to spread to `TagInput`. Use `query` and `onQueryChange` to control the input. */
  // eslint-disable-next-line @typescript-eslint/ban-types
  tagInputProps?: Partial<ITagInputProps> & object;

  /** Custom renderer to transform an item into tag content. */
  tagRenderer: (item: T) => React.ReactNode;
}

export interface IInlineMultiSelectState {
  isOpen: boolean;
}

export class InlineMultiSelect<T> extends AbstractPureComponent2<IInlineMultiSelectProps<T>, IInlineMultiSelectState> {
  public static displayName = `${DISPLAYNAME_PREFIX}.InlineMultiSelect`

  public static defaultProps = {
    fill: false,
    placeholder: 'Search...',
  }
  public state: IInlineMultiSelectState = {
    isOpen: (this.props.popoverProps && this.props.popoverProps.isOpen) || false,
  }
  private TypedQueryList = QueryList.ofType<T>()
  private input: HTMLInputElement | null = null
  private queryList: QueryList<T> | null = null
  private refHandlers = {
    input: (ref: HTMLInputElement | null) => {
      this.input = ref
      this.props.tagInputProps?.inputRef?.(ref)
    },
    queryList: (ref: QueryList<T> | null) => (this.queryList = ref),
  }

  public static ofType<U>() {
    return InlineMultiSelect as new (props: IInlineMultiSelectProps<U>) => InlineMultiSelect<U>
  }

  public render() {
    // omit props specific to this component, spread the rest.
    const {openOnKeyDown, popoverProps, tagInputProps, ...restProps} = this.props

    return (
      <this.TypedQueryList
        {...restProps}
        onItemSelect={this.handleItemSelect}
        onQueryChange={this.handleQueryChange}
        ref={this.refHandlers.queryList}
        renderer={this.renderQueryList}
      />
    )
  }

  private renderQueryList = (listProps: IQueryListRendererProps<T>) => {
    const {
      fill,
      tagInputProps = {},
      popoverProps = {},
      selectedItems = [],
      placeholder,
    } = this.props
    const {handlePaste, handleKeyDown, handleKeyUp} = listProps

    if (fill) {
      popoverProps.fill = true
      tagInputProps.fill = true
    }

    // add our own inputProps.className so that we can reference it in event handlers
    const inputProps = {
      ...tagInputProps.inputProps,
      className: classNames(tagInputProps.inputProps?.className, Classes.MULTISELECT_TAG_INPUT_INPUT),
    }

    const handleTagInputAdd = (values: any[], method: TagInputAddMethod) => {
      if (method === 'paste') {
        handlePaste(values)
      }
    }

    return (
      <div>
        <div
          onKeyDown={this.getTagInputKeyDownHandler(handleKeyDown)}
          onKeyUp={this.getTagInputKeyUpHandler(handleKeyUp)}
          style={{padding: '5px'}}
        >
          <TagInput
            placeholder={placeholder}
            {...tagInputProps}
            className={classNames(Classes.MULTISELECT, tagInputProps.className)}
            inputRef={this.refHandlers.input}
            inputProps={inputProps}
            inputValue={listProps.query}
            /* eslint-disable-next-line react/jsx-no-bind */
            onAdd={handleTagInputAdd}
            onInputChange={listProps.handleQueryChange}
            onRemove={this.handleTagRemove}
            values={selectedItems.map(this.props.tagRenderer)}
          />
        </div>
        <div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
          {listProps.itemList}
        </div>
      </div>
    )
  }

  private handleItemSelect = (item: T, evt?: React.SyntheticEvent<HTMLElement>) => {
    if (this.input != null) {
      this.input.focus()
    }
    this.props.onItemSelect?.(item, evt)
  }

  private handleQueryChange = (query: string, evt?: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({isOpen: query.length > 0 || !this.props.openOnKeyDown})
    this.props.onQueryChange?.(query, evt)
  }

  private handleTagRemove = (tag: React.ReactNode, index: number) => {
    const {selectedItems = [], onRemove, tagInputProps} = this.props
    onRemove?.(selectedItems[index], index)
    tagInputProps?.onRemove?.(tag, index)
  }

  private getTagInputKeyDownHandler = (handleQueryListKeyDown: React.KeyboardEventHandler<HTMLElement>) => {
    return (e: React.KeyboardEvent<HTMLElement>) => {
      // HACKHACK: https://github.com/palantir/blueprint/issues/4165
      const {which} = e

      if (which === Keys.ESCAPE || which === Keys.TAB) {
        // By default the escape key will not trigger a blur on the
        // input element. It must be done explicitly.
        if (this.input != null) {
          this.input.blur()
        }
        this.setState({isOpen: false})
      } else if (!(which === Keys.BACKSPACE || which === Keys.ARROW_LEFT || which === Keys.ARROW_RIGHT)) {
        this.setState({isOpen: true})
      }

      const isTargetingTagRemoveButton = (e.target as HTMLElement).closest(`.${CoreClasses.TAG_REMOVE}`) != null

      if (this.state.isOpen && !isTargetingTagRemoveButton) {
        handleQueryListKeyDown?.(e)
      }
    }
  }

  private getTagInputKeyUpHandler = (handleQueryListKeyUp: React.KeyboardEventHandler<HTMLElement>) => {
    return (e: React.KeyboardEvent<HTMLElement>) => {
      const isTargetingInput = (e.target as HTMLElement).classList.contains(Classes.MULTISELECT_TAG_INPUT_INPUT)

      // only handle events when the focus is on the actual <input> inside the TagInput, as that's
      // what QueryList is designed to do
      if (this.state.isOpen && isTargetingInput) {
        handleQueryListKeyUp?.(e)
      }
    }
  }
}
