import React from 'react'
import {List} from 'shared/list/List'
import styled from 'styled-components'
import {ListHeader} from 'shared/list/ListHeader'

interface IItemsViewSectionProps<T> {
  title: string
  renderItem: (value: T) => JSX.Element
  items: T[]
}

export function ItemsViewSection<T>(props: IItemsViewSectionProps<T>) {
  const {
    title,
    renderItem,
    items,
  } = props

  if (!items || !items.length)
    return <></>

  return (
    <div>
      <ListHeader
        style={{height: '40px'}}
        leftElement={<LabelTag>{title}</LabelTag>}
      />

      <List<T>
        items={items}
        greyBackground={true}
        renderItem={renderItem}
      />
    </div>
  )
}

const LabelTag = styled.label`
text-transform: uppercase;
font-weight: 500;
font-size: 16px;
`
