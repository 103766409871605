import React, {useContext} from 'react'
import {CompanyContext} from 'company/CompanyState'
import {Form} from 'react-final-form'
import {Intent} from '@blueprintjs/core/lib/esm/common/intent'
import {Button} from '@blueprintjs/core'
import {InputVat} from 'shared/inputs/InputVat'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {Input} from 'shared/inputsV2/Input'

export function SettingsCompanyPage() {
  const {company, isUpdatingCompany, updateCompany} = useContext(CompanyContext)

  return (
    <Form
      initialValues={company}
      onSubmit={updateCompany}
      render={({handleSubmit}) => <form>
        <FieldFormGroup
          label='Nome'
          name='name'
          required={true}
          render={input => <Input {...input} autoFocus={true}/>}
        />

        <FieldFormGroup
          label='IVA di default'
          name='default_vat'
          render={input => <InputVat {...input}/>}
        />

        <Button
          type={'submit'}
          fill={true}
          text={'Salva'}
          intent={Intent.SUCCESS}
          onClick={handleSubmit}
          loading={isUpdatingCompany}
        />
      </form>}
    />
  )
}
