import React, {useContext} from 'react'
import {useRouterParam} from 'shared/routing/useRouterParam'
import {Form} from 'react-final-form'
import {FieldStrongPassword} from './FieldStrongPassword'
import {Button, Intent} from '@blueprintjs/core'
import {AuthApi} from './AuthApi'
import {useApiFormSubmitter} from 'shared/api/hooks/useApiFormSubmitter'
import {TokenContext} from './TokenState'

interface IResetPasswordProps {

}

export function ResetPassword(props: IResetPasswordProps) {
  const {setToken} = useContext(TokenContext)
  const [token] = useRouterParam<string>('token', '')
  const {onSubmit, loading} = useApiFormSubmitter(AuthApi.resetPassword, ({token}) => setToken(token))

  return <Form<{ token: string, newPassword: string }>
    initialValues={{token, newPassword: ''}}
    onSubmit={onSubmit}
    render={({handleSubmit, invalid}) => {
      return (<form onSubmit={handleSubmit}>
        <FieldStrongPassword
          name={'newPassword'}
          label={'Password'}
          autoFocus={true}
          large={true}
        />

        <Button
          disabled={invalid}
          type={'submit'}
          fill={true}
          large={true}
          text={'Prosegui'}
          intent={Intent.PRIMARY}
          loading={loading}
        />
      </form>)
    }}
  />
}
