import {Button, ButtonGroup, Classes, Dialog, Intent} from '@blueprintjs/core'
import React, {useState} from 'react'
import {Form} from 'react-final-form'

export interface IMassUpdateOption {
  label: string
  form: JSX.Element
  initialValue: any
}

interface IMassUpdateButtonProps {
  onSubmit: (value) => void
  options: IMassUpdateOption[]
}

export function MassUpdateButton(props: IMassUpdateButtonProps) {
  const {onSubmit, options} = props

  const [selected, setSelected] = useState<IMassUpdateOption>()
  const close = () => setSelected(null)

  const formSubmit = value => {
    close()
    onSubmit(value)
  }

  return (
    <>
      <ButtonGroup>
        {options.map((option, index) => <Button
          key={index}
          text={option.label}
          onClick={() => setSelected(option)}
        />)}
      </ButtonGroup>

      <Dialog
        isOpen={!!selected}
        onClose={close}
        title={selected && selected.label}
        lazy={true}
      >
        <Form
          initialValues={selected && selected.initialValue}
          onSubmit={formSubmit}
          render={({handleSubmit}) =>
            <form onSubmit={handleSubmit} className={Classes.DIALOG_BODY}>

              {selected && selected.form}

              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  text={'Conferma'}
                  type={'submit'}
                  intent={Intent.SUCCESS}
                />
              </div>
            </form>
          }/>
      </Dialog>
    </>
  )
}
