import React from 'react'
import styled from 'styled-components'

interface IPageContainerProps {
  children
  maxWidth?
  minWidth?
}

const Container = styled.div`
  flex: 1;
  overflow: auto;
`

const Content = styled.div`
  padding: 16px;
  margin-right: auto;
  margin-left: auto;
`

export function PageContainer(props: IPageContainerProps) {
  const {children, maxWidth, minWidth} = props
  return (
    <Container>
      <Content style={{maxWidth, minWidth}}>
        {children}
      </Content>
    </Container>
  )
}

