import React from 'react'
import {ListRow} from 'shared/list/ListRow'
import {ISaleReturnedItem} from './ISaleReturnedItem'
import classNames from 'classnames'
import {Classes} from '@blueprintjs/core'
import {DetailsViewer, IDetailsViewerItem} from 'shared/list/DetailsViewer'
import {MoneyValue} from 'shared/components/MoneyValue'
import {MomentDate} from 'shared/date/MomentDate'
import {AppText} from 'shared/components/AppText'

interface IReturnedItemListRowProps {
  item: ISaleReturnedItem
  isGrossMode: boolean
}

export function ReturnableItemListRow(props: IReturnedItemListRowProps) {
  const {
    item,
    isGrossMode,
  } = props

  const {
    product,
    movement,
    quantity,
    gross,
    net,
  } = item

  const descriptionClasses = classNames(Classes.TEXT_OVERFLOW_ELLIPSIS)
  const total = isGrossMode ? gross : net

  const detailsConfig: IDetailsViewerItem[] = [
    {label: 'Qt', value: quantity, hidden: quantity <= 1},
    {label: 'Cod', value: product.code, hidden: !product.code},
    {label: 'Data', value: <MomentDate date={movement.date}/>},
    {label: 'Cliente', value: movement.counterpart?.name, hidden: !movement.counterpart},
  ]

  return (
    <ListRow
      topLeft={<b className={descriptionClasses}>
        {product.description}
      </b>}
      topRight={<AppText intent={'primary'}>
        <MoneyValue value={total}/>
      </AppText>}
      bottomLeft={<DetailsViewer items={detailsConfig}/>}
    />
  )
}
