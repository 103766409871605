import React, {useCallback, useEffect, useState} from 'react'
import {useLocalStorageNumber} from 'shared/hooks/useLocalStorageNumber'
import {myAxios} from 'shared/api/myAxios'
import {ICompany} from './ICompany'
import {CompanyApi} from './CompanyApi'
import {Redirect, Route} from 'react-router'
import {AppBase} from 'app-base/AppBase'
import {LoadingState} from 'shared/components/LoadingState'
import {CompaniesPage} from './CompaniesPage'

interface ICompanyContext {
  isLoggedInCompany: boolean
  company: ICompany
  changeCompanyById: (id: number) => void
  updateCompany: (value: ICompany) => any
  isUpdatingCompany: boolean
}

export const CompanyContext = React.createContext<ICompanyContext>({
  company: null,
  changeCompanyById: null,
  isLoggedInCompany: false,
  updateCompany: null,
  isUpdatingCompany: false,
})

export function CompanyState() {
  const [companyId, changeCompanyById] = useLocalStorageNumber('companyId')
  const [company, setCompany] = useState<ICompany>(null)
  const [isUpdatingCompany, setIsUpdatingCompany] = useState<boolean>(false)
  const isLoggedInCompany = !!companyId

  useEffect(() => {
    myAxios.defaults.headers['Company-Id'] = companyId
    setCompany(null)

    if (!companyId)
      return

    CompanyApi.getOne(companyId)
      .then(response => setCompany(response))
      .catch(error => changeCompanyById(null))
  }, [companyId, changeCompanyById])

  const updateCompany = useCallback((params) => {
    setIsUpdatingCompany(true)
    return CompanyApi.update(companyId, params)
      .then(setCompany)
      .catch(error => error.data.data)
      .finally(() => setIsUpdatingCompany(false))
  }, [companyId])

  return (
    <CompanyContext.Provider
      value={{
        company,
        changeCompanyById,
        isLoggedInCompany,
        isUpdatingCompany,
        updateCompany,
      }}
    >
      {isLoggedInCompany ?
        (company ? <Route path="/" component={AppBase}/> : <LoadingState/>) :
        <>
          <Route path="/companies" component={CompaniesPage}/>
          <Redirect to='/companies'/>
        </>
      }
    </CompanyContext.Provider>
  )
}
