import React from 'react'
import {IInputProps, Input} from './Input'

export function InputMoney(props: IInputProps) {
  return (
    <Input
      type={'number'}
      leftIcon='euro'
      {...props}
    />
  )
}
