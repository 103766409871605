import React from 'react'
import {LinkTabs} from 'shared/routing/LinkTabs'
import {StockPage} from 'analytics/StockPage'

function AnalyticsPage({match}) {
  return (
    <LinkTabs
      defaultRoute={'stock'}
      match={match}
      renderActiveTabPanelOnly={true}
      tabs={[
        {
          route: 'stock',
          label: 'Giacenze',
          component: StockPage,
        },
      ]}
    />
  )
}

export default AnalyticsPage
