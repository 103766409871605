import styled from 'styled-components'

export const FakeLink = styled.span`
  color: #106ba3;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
