import React from 'react'
import {DataViewer} from 'shared/components/DataViewer'
import {MoneyValue} from 'shared/components/MoneyValue'
import IProduct from '../IProduct'
import ProductStockTag from 'product/components/ProductStockTag'

interface IProductViewerProps {
  product: IProduct
}

export function ProductViewer(props: IProductViewerProps) {
  const {
    product: {
      description,
      code,
      attributes_values,
      vat,
      price_list_gross,
      stock,
      stock_value,
    },
  } = props

  return (
    <DataViewer
      data={[
        {
          label: 'Descrizione',
          value: description,
        },
        {
          label: 'Codice',
          value: code,
        },
        ...attributes_values.map(({attribute, value}) => ({
          label: attribute.name,
          value,
        })),
        {
          label: 'IVA',
          value: vat.label,
        },
        {
          label: 'Prezzo di vendita',
          value: <MoneyValue value={price_list_gross}/>,
        },
        {
          label: 'Giacenza',
          value: <ProductStockTag stock={stock}/>,
        },
        {
          label: 'Valore magazzino',
          value: <MoneyValue value={stock_value}/>,
          hidden: stock === 0,
        },
      ]}
    />
  )
}
