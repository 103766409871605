import React, {useCallback, useMemo} from 'react'
import {useApiListResponse} from 'shared/api/hooks/useApiListResponse'
import {IPurchase} from 'document/movement/purchase/IPurchase'
import PageHeader from 'shared/components/PageHeader'
import {ButtonLink} from 'shared/routing/ButtonLink'
import {Intent} from '@blueprintjs/core/lib/esm/common/intent'
import {MoneyValue} from 'shared/components/MoneyValue'
import ICounterpart, {CounterpartRole} from 'counterpart/ICounterpart'
import {ICrudApi} from 'shared/api/CrudApi'
import InputFilters, {IFiltersConfigs} from 'shared/filters/InputFilters'
import FilterSelectEntity from 'shared/filters/filtersTag/FilterSelectEntity'
import CounterpartApi from 'counterpart/CounterpartApi'
import Row from 'settings/Row'
import DataTable from 'shared/table/DataTable'
import {useApiResponse} from 'shared/api/hooks/useApiResponse'
import {IMovement} from 'document/movement/IMovement'
import {useRouterParams} from 'shared/routing/useRouterParams'
import {useParamPage} from 'shared/params/useParamPage'
import {useParamSort} from 'shared/params/useParamSort'
import {useParamFilter} from 'shared/params/useParamFilter'
import PeriodPicker from 'shared/filters/PeriodPicker/PeriodPicker'
import {IPeriodParamConfig, useParamPeriod} from 'shared/params/useParamPeriod'
import {ISortParam} from 'shared/api/hooks/IGetListParams'
import {ITableColumn} from 'shared/table/Table'
import {Period} from 'shared/filters/PeriodPicker/configs'
import TotalCard from 'shared/components/TotalCard'

export interface IMovementCrudApi<T> extends ICrudApi<T> {
  getTotal: (params: { filters }) => Promise<{ data: { net, gross } }>
}

interface IMovementListPageProps<T> {
  title: string
  link: string
  newLinkText: string
  counterpartRole: CounterpartRole
  crudApi: IMovementCrudApi<T>
  columns: ITableColumn<T>[]
  defaultPeriod: Period
}

const defaultSort: ISortParam = {sortDir: 'desc', sortBy: 'date_time'}

export function DocumentListPage<T extends IMovement>(props: IMovementListPageProps<T>) {
  const {
    title,
    link,
    newLinkText,
    counterpartRole,
    crudApi,
    columns,
    defaultPeriod,
  } = props

  const counterpartLabel = counterpartRole === 'customer' ? 'Cliente' : 'Fornitore'
  const allowNullCounterpart = counterpartRole === 'customer'

  const periodParamConfig = useMemo<IPeriodParamConfig>(() => ({defaultValue: defaultPeriod}), [defaultPeriod])

  const routerParams = useRouterParams()
  const [page, setPage] = useParamPage(routerParams)
  const [sort, setSort] = useParamSort(routerParams, defaultSort)
  const [filters, setFilters] = useParamFilter(routerParams)
  const [period, setPeriod, periodFilters] = useParamPeriod(routerParams, periodParamConfig)

  const listCall = useCallback(() => crudApi.getList({
      filters: [...filters, ...periodFilters],
      ...sort,
      page,
    }),
    [crudApi, filters, periodFilters, sort, page])

  const totalCall = useCallback(() => crudApi.getTotal({
    filters: [...filters, ...periodFilters],
  }), [crudApi, filters, periodFilters])

  const listResponse = useApiListResponse<IPurchase>(listCall)
  const totalResponse = useApiResponse(totalCall)

  const filtersConfigs = useMemo<IFiltersConfigs>(() => {
    const roleFilter = {field: 'role', op: '=', value: counterpartRole}
    const getter = search => CounterpartApi.getList({
      search,
      filters: [roleFilter],
    })
    const loadByKeys = CounterpartApi.loadByKeys({filters: [roleFilter]})

    return {
      'counterpart_id': {
        name: counterpartLabel,
        renderTag: props => <FilterSelectEntity<ICounterpart>
          {...props}
          getter={getter}
          loadByKeys={loadByKeys}
          allowNull={allowNullCounterpart}
          renderItem={(item) => item?.name || 'Nessuno'}
        />,
      },
    }
  }, [counterpartRole, allowNullCounterpart, counterpartLabel])

  return (
    <div>
      <PageHeader
        title={title}
        rightElement={<ButtonLink
          icon={'plus'}
          to={link + '/new'}
          text={newLinkText}
          intent={Intent.SUCCESS}
          large={true}
        />}
      />

      <Row childrenMargin>
        <PeriodPicker
          value={period}
          onChange={setPeriod}
        />
        <InputFilters
          value={filters}
          onChange={setFilters}
          configs={filtersConfigs}
        />
      </Row>

      <Row childrenMargin>
        <TotalCard
          label={'Totale lordo'}
          loading={totalResponse.loading}
          value={<MoneyValue value={totalResponse.response?.data.gross}/>}
        />
        <TotalCard
          label={'Totale netto'}
          loading={totalResponse.loading}
          value={<MoneyValue value={totalResponse.response?.data.net}/>}
        />
      </Row>

      <DataTable<IPurchase>
        apiListResponse={listResponse}
        sort={sort}
        setSort={setSort}
        sortOptions={[
          {sortBy: 'date_time', label: 'Data'},
          {sortBy: 'counterpart.name', label: counterpartLabel},
        ]}
        page={page}
        setPage={setPage}
        rowLink={({id}) => link + '/' + id}
        columns={columns}
      />
    </div>
  )
}
