import {createCrudApi} from 'shared/api/CrudApi'
import {myAxios} from 'shared/api/myAxios'
import IGetListParams from 'shared/api/hooks/IGetListParams'

export const SaleApi = {
  ...createCrudApi('sale'),

  getTotal: (params) => myAxios.get('sale_total', {params}).then(({data}) => data),

  getReturnable: (params?: IGetListParams) =>
    myAxios.get('sale_returnable', {params}).then(({data}) => data),
}
