import React, {useCallback, useState} from 'react'
import {Classes, Dialog} from '@blueprintjs/core'
import {IPaymentMethod} from './IPaymentMethod'
import PaymentMethodApi from './PaymentMethodApi'
import PaymentMethodForm from './PaymentMethodForm'
import {IInputSelectAsyncProps, InputSelectAsync} from 'shared/inputsV2/select/InputSelectAsync'

interface ICounterpartSelectProps extends Omit<IInputSelectAsyncProps<IPaymentMethod>, 'renderItem' | 'itemsGetter'> {

}

export function PaymentMethodSelect(props: ICounterpartSelectProps) {
  const {
    ...entitySelectProps
  } = props

  const [newString, setNewString] = useState()
  const onDismiss = () => setNewString(null)

  const itemsGetter = useCallback(search => PaymentMethodApi.getList({search}), [])

  return (
    <>
      <InputSelectAsync<IPaymentMethod>
        placeholder={'Seleziona metodo di pagamento'}
        itemsGetter={itemsGetter}
        renderItem={item => item.name}
        onNew={setNewString}
        itemsEqual={'id'}
        {...entitySelectProps}
      />

      <Dialog
        isOpen={!!newString}
        lazy={true}
        title={'Nuovo metodo di pagamento'}
        onClose={onDismiss}
      >
        <PaymentMethodForm
          initialValues={{name: newString}}
          onDismiss={onDismiss}
          onSaved={item => {
            setNewString(null)
            entitySelectProps.onChange(item)
          }}
          className={Classes.DIALOG_BODY}
        />
      </Dialog>
    </>
  )
}
