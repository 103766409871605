import React from 'react'
import ICounterpart from '../ICounterpart'
import EntityPreviewer, {IEntityPreview} from 'shared/components/EntityPreviewer'

interface ICounterpartPreviewProps extends IEntityPreview<ICounterpart> {
}

export const CounterpartPreview = (props: ICounterpartPreviewProps) => {
  const {item, ...others} = props

  return (
    <EntityPreviewer
      title={item.name}
      {...others}
    />
  )
}
