import React from 'react'
import {Button, Intent} from '@blueprintjs/core'
import {Form, FormRenderProps} from 'react-final-form'
import {IEntity} from '../types/IEntity'
import {JsonViewer} from '../components/JsonViewer'
import useEntityActions from '../hooks/useEntityActions'
import {Decorator} from 'final-form'
import styled from 'styled-components'
import {ICrudApi} from '../api/CrudApi'

export interface IGenericFormProps<T> extends React.HTMLAttributes<HTMLFormElement> {
  initialValues?: T,
  emptyFormValue?: T,
  showDebugFormValue?: boolean
  onDismiss?: () => void
  onSaved?: (value: T) => void
  onDeleted?: () => void
  mutators?
  hideDeleteButton?: boolean
  hideDismissButton?: boolean
  hideSaveButton?: boolean
}

export interface IFormManagerProps<T> extends IGenericFormProps<T> {
  api: ICrudApi<T>
  render: (props: FormRenderProps<T>, actions?) => any
  decorators?: Decorator<any>[]
  mapIn?: (value: T) => any
  mapOut?: (value: any) => T
}

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

const MarginChildren = styled.div`
  *:not(:last-child) {
    margin-right: 8px;
  }
`

export function FormManager<T extends IEntity>(props: IFormManagerProps<T>) {
  const {
    initialValues,
    showDebugFormValue,
    onDismiss,
    onSaved,
    onDeleted,
    api,
    render,
    decorators,
    mutators,
    hideDeleteButton,
    hideDismissButton,
    hideSaveButton,
    emptyFormValue,
    mapIn = v => v,
    mapOut = v => v,
    ...formProps
  } = props

  const actions = useEntityActions(initialValues, api, {onSaved, onDeleted})
  const {
    isNew,
    deleteItem,
    saving,
    deleting,
    formSubmit,
  } = actions

  const mappedInitial = mapIn(initialValues || emptyFormValue)

  return (
    <Form<T>
      initialValues={mappedInitial}
      decorators={decorators}
      onSubmit={value => formSubmit(mapOut(value))}
      mutators={mutators}
      keepDirtyOnReinitialize={false}
      render={(finalFormProps) =>
        <form
          {...formProps}
          onSubmit={finalFormProps.handleSubmit}
        >
          <div>
            {render(finalFormProps, actions)}

            {showDebugFormValue && <JsonViewer data={finalFormProps.values}/>}
          </div>

          <SpaceBetween>
            <div>
              {!isNew && !hideDeleteButton && <Button
                text={'Elimina'}
                onClick={deleteItem}
                disabled={saving}
                loading={deleting}
                intent={Intent.DANGER}
              />}
            </div>

            <MarginChildren>
              {!hideDismissButton && <Button
                text={'Annulla'}
                onClick={onDismiss}
                disabled={saving || deleting}
              />}
              {!hideSaveButton && <Button
                text={'Salva'}
                intent={Intent.SUCCESS}
                type={'submit'}
                disabled={deleting}
                loading={saving}
              />}
            </MarginChildren>
          </SpaceBetween>
        </form>
      }
    />
  )
}
