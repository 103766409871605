import React, {useEffect, useMemo, useState} from 'react'
import {DATE_FORMAT, DATETIME_FORMAT, dayPickerProps, DEFAULT_MAX_DATE, DEFAULT_MIN_DATE} from './date'
import {DatePicker as BP3DatePicker} from '@blueprintjs/datetime'
import {IDatePickerShortcut} from '@blueprintjs/datetime/src/shortcuts'
import moment, {Moment} from 'moment'
import {TimePrecision} from '@blueprintjs/datetime/src/timePicker'

interface IDatePickerProps {
  value: string
  onChange: (value: string) => void
  minDate?: string
  maxDate?: string
  timePrecision?: TimePrecision;
}

export function DatePicker(props: IDatePickerProps) {
  const {
    value,
    onChange,
    minDate,
    maxDate,
    timePrecision,
  } = props

  const [pickerValue, setPickerValue] = useState<Date>()

  const format = useMemo(() => timePrecision ? DATETIME_FORMAT : DATE_FORMAT, [timePrecision])
  const momentMinDate = useMemo(() => minDate ? moment(minDate) : DEFAULT_MIN_DATE, [minDate])
  const momentMaxDate = useMemo(() => maxDate ? moment(maxDate) : DEFAULT_MAX_DATE, [maxDate])
  const momentValue = useMemo(() => moment(value, format), [value, format])

  const onPickerChange = (selectedDate: Date, isUserChange: boolean) => {
    if (isUserChange)
      onChange(moment(selectedDate).format(format))
  }

  useEffect(() => {
    if (!momentValue.isValid() || momentValue.isAfter(momentMaxDate) || momentValue.isBefore(momentMinDate))
      setPickerValue(null)
    else
      setPickerValue(momentValue.toDate())
  }, [momentValue, momentMinDate, momentMaxDate])

  const selectedShortcutIndex = useMemo(() => getSelectedShortcutIndex(value), [value])

  return (
    <BP3DatePicker
      value={pickerValue}
      onChange={onPickerChange}
      dayPickerProps={dayPickerProps}
      selectedShortcutIndex={selectedShortcutIndex}
      shortcuts={defaultShortcuts}
      minDate={momentMinDate.toDate()}
      maxDate={momentMaxDate.toDate()}
      timePrecision={timePrecision}
    />
  )
}

export function getSelectedShortcut(value) {
  const index = getSelectedShortcutIndex(value)
  return momentShortcuts[index]
}

export function getSelectedShortcutIndex(value) {
  return momentShortcuts
    .map(({date}) => date.format(DATE_FORMAT))
    .indexOf(value)
}

export const momentShortcuts: { label: string, date: Moment }[] = [
  {
    label: 'Oggi',
    date: moment(),
  },
  {
    label: 'Ieri',
    date: moment().subtract(1, 'day'),
  },
]

export const defaultShortcuts: IDatePickerShortcut[] = momentShortcuts.map(({label, date}) => ({
  label,
  date: date.toDate(),
}))
