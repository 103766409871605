import {AttributeApi, IAttribute} from './attribute'
import React, {useState} from 'react'
import {useApiFormSubmitter} from 'shared/api/hooks/useApiFormSubmitter'
import {Form} from 'react-final-form'
import {Button, Checkbox, Divider, Menu, Popover} from '@blueprintjs/core'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {Validators} from 'shared/form/Validators'
import {ButtonApi} from 'shared/components/ButtonApi'
import {LoadingPanel} from 'shared/components/LoadingPanel'
import {Input} from 'shared/inputsV2/Input'
import {Intent} from '@blueprintjs/core/src/common/intent'

interface IAttributeManagerProps {
  attribute?: IAttribute
  onChange: (value?: IAttribute) => void
  intent?: Intent
}

export function AttributeManager(props: IAttributeManagerProps) {
  const {
    attribute,
    onChange,
    intent,
  } = props

  const newMode = !attribute || !attribute.id
  const [isOpen, setIsOpen] = useState(false)
  const closePopover = () => setIsOpen(false)
  const onChanged = value => {
    closePopover()
    onChange(value)
  }

  const formSubmitter = useApiFormSubmitter<IAttribute>(AttributeApi.save, onChanged)
  const onDelete = () => {
    return AttributeApi.delete(attribute.id)
      .then(onChanged)
  }

  return (
    <Form
      initialValues={attribute}
      onSubmit={((values, form) => {
        if (!form.getState().dirty)
          closePopover()
        else
          return formSubmitter.onSubmit(values)
            .then(() => setTimeout(form.reset, 0))
      })}
      render={({handleSubmit, dirty, form: {reset}}) => {
        const onInteraction = (nextOpenState, event) => {
          if (!!event && !nextOpenState && dirty)
            handleSubmit().then(reset)
          else
            setIsOpen(nextOpenState)
        }

        return (<Popover
            isOpen={isOpen}
            onInteraction={onInteraction}
            hasBackdrop={true}
            usePortal={true}
            lazy={true}
            fill={true}
            content={<form onSubmit={handleSubmit}>
              {formSubmitter.loading && <LoadingPanel/>}
              <Menu>
                <FieldFormGroup
                  name={'name'}
                  validators={Validators.required}
                  render={props => <Input
                    {...props}
                    autoFocus={true}
                    onFocus={e => e.target.select()}
                    placeholder={'Nome campo'}
                  />}
                />

                <FieldFormGroup
                  name={'required'}
                  render={({value, onChange}) => {
                    return <Checkbox
                      checked={!!value}
                      onChange={e => onChange(e.target['checked'])}
                      label={'Campo obbligatorio'}
                    />
                  }}
                />

                {!newMode && <>
                  <Divider/>

                  <ButtonApi
                    icon={'trash'}
                    text={'Elimina campo'}
                    fill={true}
                    minimal={true}
                    alignText={'left'}
                    apiCall={onDelete}
                  />
                </>}
              </Menu>
            </form>}
          >
            <Button
              text={newMode ? 'Crea nuovo campo' : attribute.name}
              icon={newMode ? 'add' : null}
              alignText={'left'}
              fill={true}
              minimal={true}
              active={isOpen}
              intent={intent}
            />
          </Popover>
        )
      }}
    />
  )
}
