import React, {useCallback, useMemo, useState} from 'react'
import {Button, Classes, Dialog, Intent} from '@blueprintjs/core'
import {useForm, useFormState} from 'react-final-form'
import {useApiListResponse} from 'shared/api/hooks/useApiListResponse'
import PaymentMethodApi from '../../../paymentMethod/PaymentMethodApi'
import {LoadingState} from 'shared/components/LoadingState'
import {MoneyValue} from 'shared/components/MoneyValue'
import {FieldArray} from 'react-final-form-arrays'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {InputMoney} from 'shared/inputsV2/InputMoney'
import {PaymentMethodSelect} from '../../../paymentMethod/PaymentMethodSelect'
import PaymentMethodForm from '../../../paymentMethod/PaymentMethodForm'
import styled from 'styled-components'
import {InlineGroup} from 'shared/styled/InlineGroup'
import {ISale} from './ISale'
import {IPaymentMethod} from '../../../paymentMethod/IPaymentMethod'

interface IPaymentsFormProps {

}

export function PaymentsForm(props: IPaymentsFormProps) {
  const {values} = useFormState<ISale>()
  const f = useForm()

  const {gross, payments_items} = values
  const payed = useMemo(() => {
    if (!payments_items)
      return 0
    return payments_items.reduce((carry, item) => carry + +item.gross, 0)
  }, [payments_items])

  const setSinglePayment = useCallback((payment_method) => {
    f.change('payments_items', [{payment_method, gross}])
  }, [f, gross])

  const splitPayments = useCallback(() => {
    f.change('payments_items', [{gross}])
  }, [f, gross])

  const onePaymentsMode = !payments_items || payments_items.length === 0

  return (
    <div>
      {onePaymentsMode ?
        <PaymentFastSelector
          total={gross}
          onSelected={setSinglePayment}
          onPaymentSplit={splitPayments}
        /> :
        <PaymentsListForm
          total={+gross}
          payed={+payed}
        />}
    </div>
  )
}

const PaymentItemTag = styled.div`
display: flex;
align-items: start;
`

const PaymentsItemFormTag = styled(InlineGroup)`
flex: 1;
`

function PaymentsListForm({total, payed}) {
  const diff = total - payed
  const absDiff = Math.abs(diff)
  const isOverTotal = diff < 0

  return (
    <div>
      <FieldArray
        name={'payments_items'}
        render={({fields}) =>
          <div>
            {fields.map((name, index) =>
              <PaymentItemTag key={index}>
                <PaymentsItemFormTag>
                  <FieldFormGroup
                    name={name + '.gross'}
                    required={true}
                    render={props => <InputMoney
                      {...props}
                      large={true}
                    />}
                  />
                  <FieldFormGroup
                    name={name + '.payment_method'}
                    required={true}
                    render={props => <PaymentMethodSelect
                      {...props}
                      canAddNew={true}
                      large={true}
                      required={true}
                    />}
                  />
                </PaymentsItemFormTag>

                <Button
                  icon={'trash'}
                  minimal={true}
                  onClick={() => fields.remove(index)}
                  large={true}
                  style={{marginLeft: '8px'}}
                />
              </PaymentItemTag>,
            )}

            <Button
              text={'Aggiungi'}
              icon={'plus'}
              onClick={() => fields.push({gross: Math.max(total - payed, 0)})}
              minimal={true}
              fill={true}
              large={true}
              intent={Intent.PRIMARY}
            />
          </div>
        }
      />

      <hr/>

      <div>
        Totale: <MoneyValue value={payed} style={{color: isOverTotal ? 'red' : 'initial'}}/> di
        <MoneyValue value={total}/>
      </div>
      <div>
        {isOverTotal ? 'In eccesso' : 'Rimanente'}: <MoneyValue value={absDiff}/>
      </div>
    </div>
  )
}

function PaymentFastSelector({onSelected, total, onPaymentSplit}) {
  const {loading, data} = useApiListResponse<IPaymentMethod>(PaymentMethodApi.getList)
  const [creating, setCreating] = useState<boolean>(false)

  const onDismiss = () => setCreating(false)

  if (!data || loading)
    return <LoadingState/>

  return (
    <div>
      <div>
        <h2>Totale da pagare: <MoneyValue value={total}/></h2>

        <Button
          onClick={onPaymentSplit}
          text={'Dividi pagamento'}
        />
      </div>

      <hr/>

      <PaymentMethodButtonList>
        {data.map(paymentMethod => <Button
          key={paymentMethod.id}
          text={paymentMethod.name}
          large={true}
          onClick={() => onSelected(paymentMethod)}
        />)}
      </PaymentMethodButtonList>

      <Button
        text={'Crea nuovo metodo di pagamento'}
        intent={Intent.SUCCESS}
        onClick={() => setCreating(true)}
        large={true}
      />

      <Dialog
        isOpen={creating}
        lazy={true}
        title={'Nuovo metodo di pagamento'}
        onClose={onDismiss}
      >
        <PaymentMethodForm
          onDismiss={onDismiss}
          onSaved={onSelected}
          className={Classes.DIALOG_BODY}
        />
      </Dialog>
    </div>
  )
}

const PaymentMethodButtonList = styled.div`
margin: 8px -8px;
display: flex;
flex-wrap: wrap;

& > button {
  width: 200px;
  margin: 8px;
}
`
