import React, {useEffect, useMemo, useState} from 'react'
import FilterValueTag from 'shared/filters/FilterValueTag'
import {IEntity} from 'shared/types/IEntity'
import InlineMultiSelectAsync from 'shared/inputsV2/select/InlineMultiSelectAsync'
import _ from 'lodash'
import Arr from 'shared/utils/Arr'
import {IRenderTagProps} from 'shared/filters/InputFilters'

interface IFilterSelectEntityProps<T> extends IRenderTagProps {
  loadByKeys
  getter
  renderItem: (value: T) => JSX.Element | string
  allowNull?: boolean
}

const itemsEqual = (a, b) => a?.id === b?.id

function FilterSelectEntity<T extends IEntity>(props: IFilterSelectEntityProps<T>) {
  const {
    value,
    onChange,
    name,
    onRemove,
    isNew,
    onClose,
    loadByKeys,
    getter,
    renderItem,
    allowNull,
  } = props

  const arrayValue = useMemo(() => Array.isArray(value?.value) ? value.value : [], [value])
  const nullValueIndex = allowNull ? arrayValue.indexOf(null) : -1

  const mustLoadIds = useMemo(() => arrayValue
    .map(v => +v)
    .filter(v => !!v), [arrayValue])

  const [loading, setLoading] = useState<boolean>()
  const [loadedValue, setLoadedValue] = useState([])
  const [selected, setSelected] = useState<T[]>([])

  const loadedWithNull = useMemo(() => {
    return Arr.insert(loadedValue, null, nullValueIndex)
  }, [loadedValue, nullValueIndex])

  const onConfirm = () => {
    setLoadedValue(selected.filter(Boolean))
    onChange({op: 'in', value: selected.map(v => v?.id || null)})
  }

  useEffect(() => {
    if (!mustLoadIds?.length) {
      if (!!loadedValue?.length)
        setLoadedValue([])
      return
    }

    if (_.isEqual(_.sortBy(mustLoadIds), _.sortBy(loadedValue.map(({id}) => +id))))
      return

    setLoading(true)
    loadByKeys(mustLoadIds)
      .then(response => response.data)
      .then(setLoadedValue)
      .finally(() => setLoading(false))
  }, [mustLoadIds, loadedValue, loadByKeys])

  useEffect(() => setSelected(loadedWithNull), [loadedWithNull])

  const content = () =>
    <InlineMultiSelectAsync
      value={selected}
      onChange={setSelected}
      itemsGetter={getter}
      renderItem={renderItem}
      allowNull={allowNull}
      itemsEqual={itemsEqual}
    />

  const renderValue = <ValuesTagRender
    label={name}
    values={loadedWithNull?.map(renderItem)}
  />

  return (
    <FilterValueTag
      renderValue={renderValue}
      onRemove={onRemove}
      isNew={isNew}
      renderPopoverContent={content}
      onConfirm={onConfirm}
      onClose={onClose}
      onOpening={() => setSelected(loadedWithNull)}
      loading={loading}
      confirmDisabled={!selected?.length}
    />
  )
}

export default FilterSelectEntity

export function ValuesTagRender(props) {
  const {
    label,
    values,
  } = props

  const othersCount = Math.max(values.length - 1, 0)
  const showSeparator = values.length > 0

  return <>
    {label}
    {showSeparator && ': '}
    {values[0]}
    {!!othersCount && <>, + altri {othersCount}</>}
  </>
}
