import React, {useContext} from 'react'
import {CompanyContext} from 'company/CompanyState'
import {Classes} from '@blueprintjs/core'
import styled from 'styled-components'

/*
export function CompanyControl(props) {
  const {company, changeCompanyById} = useContext(CompanyContext)

  const itemsGetter = useCallback(search => CompanyApi.getList({search}), [])

  return <InputSelectAsync<ICompany>
    itemsEqual={'id'}
    value={company}
    onChange={({id}) => changeCompanyById(id)}
    itemsGetter={itemsGetter}
    renderItem={company => <CompanyPreview item={company}/>}
    fill={true}
    popoverProps={{
      position: Position.RIGHT_TOP,
      boundary: 'window',
    }}
    buttonProps={{
      minimal: true,
      rightIcon: null
    }}
  />
}
*/

export function CompanyControl(props) {
  const {company} = useContext(CompanyContext)

  return (
    <CompanyControlTag
      className={Classes.TEXT_OVERFLOW_ELLIPSIS}
    >
      {company.name}
    </CompanyControlTag>
  )
}

const CompanyControlTag = styled.div`
line-height: 20px;
padding: 5px 7px;
`
