import {createCrudApi} from 'shared/api/CrudApi'
import {IEntity} from 'shared/types/IEntity'
import {myAxios} from 'shared/api/myAxios'

export interface IAttribute extends IEntity {
  name?: string
  required?: boolean
}

export const AttributeApi = {
  ...createCrudApi<IAttribute>('attribute'),

  getValues: (attribute_id, search) => myAxios.get('attribute_value', {
    params: {
      attribute_id,
      search,
    },
  }).then(({data}) => data),
}
