import React from 'react'
import styled from 'styled-components'
import {IUser} from '../user/IUser'
import {CustomerServiceApi} from './CustomerServiceApi'
import {ButtonGroup, Colors, Drawer, Icon, Intent} from '@blueprintjs/core'
import {UserApi} from '../user/UserApi'
import {UserPreview} from '../user/UserPreviw'
import {LoadingState} from 'shared/components/LoadingState'
import {useToggleState} from 'shared/hooks/useToggleState'
import {EntityId} from 'shared/types/IEntity'
import {useApiResponse} from 'shared/api/hooks/useApiResponse'
import {ButtonApi} from 'shared/components/ButtonApi'
import {InputSelectAsync} from 'shared/inputsV2/select/InputSelectAsync'

const usersItemsGetter = search => CustomerServiceApi.getUsers({search})

const realUserGetter = () => UserApi.getUser({headers: {'Act-As': ''}})

interface ICustomerServiceControlProps {
  realUserId: EntityId
  fakeUser: IUser
  setFakeUser: (user: IUser) => any
}

export function CustomerServiceControl(props: ICustomerServiceControlProps) {
  const {
    realUserId,
    fakeUser,
    setFakeUser,
  } = props

  const {response} = useApiResponse<IUser>(realUserGetter)
  const drawer = useToggleState()

  const intent = realUserId === fakeUser.id ? Intent.SUCCESS : Intent.WARNING

  return (
    <>
      <CustomerServiceDiv
        intent={intent}
        onClick={drawer.on}
      >
        <SpacedItems>
          {response ? <UserPreview item={response}/> : <LoadingState/>}

          {
            realUserId !== fakeUser.id && <>
              <Icon icon='double-chevron-right'/>
              <UserPreview item={fakeUser}/>
            </>
          }
        </SpacedItems>


        <Icon
          icon='double-chevron-up'
        />
      </CustomerServiceDiv>

      <Drawer
        icon='lightbulb'
        title='Assistenza'
        isOpen={drawer.isOn}
        onClose={drawer.off}
        position='bottom'
        lazy={true}
      >
        <InputSelectAsync<IUser>
          value={fakeUser}
          onChange={setFakeUser}
          itemsGetter={usersItemsGetter}
          renderItem={user => user.full_name}
          required={fakeUser && fakeUser.id === realUserId}
          placeholder={response && response.full_name}
          itemsEqual={'id'}
        />

        <ButtonGroup>
          <ButtonApi
            apiCall={CustomerServiceApi.alignStockHistory}
            text={'Ricalcola stock history'}
          />
          <ButtonApi
            apiCall={CustomerServiceApi.alignDocumentsTotals}
            text={'Ricalcola totali documenti'}
          />
        </ButtonGroup>
      </Drawer>
    </>
  )
}

const CustomerServiceDiv = styled.div<{ intent?: Intent }>`
cursor: pointer;
padding: 4px;
display: flex;
justify-content: space-between;
background-color: ${Colors.ORANGE5};
align-items: center;
:hover{
  background-color: ${Colors.ORANGE4};
}
`

const SpacedItems = styled.div`
display: flex;
align-items: center;
overflow: hidden;
> *:not(:last-child) {
  margin-right: 8px;
}
`
