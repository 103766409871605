import React, {useContext} from 'react'
import {Form} from 'react-final-form'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {Button, Intent} from '@blueprintjs/core'
import {Validators} from 'shared/form/Validators'
import {InputPassword} from 'shared/inputsV2/InputPassword'
import {DividerText} from './DividerText'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {TokenContext} from './TokenState'
import {ButtonLink} from 'shared/routing/ButtonLink'
import {AuthApi} from './AuthApi'
import {useApiFormSubmitter} from 'shared/api/hooks/useApiFormSubmitter'
import {Input} from 'shared/inputsV2/Input'

const Centered = styled.div`
  text-align: center;
`

export function LoginPage() {
  const {setToken} = useContext(TokenContext)
  const {onSubmit, loading} = useApiFormSubmitter(AuthApi.login, ({token}) => setToken(token))

  return (
    <Centered>

      <h4>Accedi a Negoziapp</h4>

      <Form
        onSubmit={onSubmit}
        render={({handleSubmit}) => <form onSubmit={handleSubmit}>
          <FieldFormGroup
            name={'email'}
            label={'Email'}
            required={true}
            validators={Validators.email}
            render={params => <Input
              {...params}
              name={'email'}
              placeholder={'Inserisci qui la tua email'}
              autoFocus={true}
              autoComplete={true}
              large={true}
            />}
          />
          <FieldFormGroup
            label={'Password'}
            name={'password'}
            required={true}
            render={params => <InputPassword
              {...params}
              name={'password'}
              placeholder={'Inserisci qui la tua password'}
              autoComplete={true}
              large={true}
            />}
          />

          <Button
            type={'submit'}
            fill={true}
            text={'Accedi'}
            large={true}
            intent={Intent.PRIMARY}
            loading={loading}
          />
        </form>}
      />

      <DividerText text={'o'}/>

      <ButtonLink
        to={'register'}
        text={'Crea nuovo account'}
        intent={Intent.SUCCESS}
        large={true}
      />

      <div style={{marginTop: '8px'}}>
        <Link to={'/reset_password_request'}>Password dimenticata?</Link>
      </div>
    </Centered>
  )
}
