import React, {useCallback, useLayoutEffect, useMemo} from 'react'
import {useLocalStorage} from 'shared/hooks/useLocalStorage'
import {myAxios} from 'shared/api/myAxios'
import {ITokenPayload, parseJwt} from './tokenUtils'
import {UnAuthArea} from '../app-base/UnAuthArea'
import {UserState} from '../user/UserState'

interface ITokenContext {
  isLogged: boolean
  logout: () => any
  userId: number
  isCustomerService: boolean
  setToken: (token: string) => any
}

export const TokenContext = React.createContext<ITokenContext>({
  isLogged: null,
  logout: null,
  userId: null,
  isCustomerService: null,
  setToken: null,
})

export function TokenState() {
  const [token, setToken] = useLocalStorage('token')
  const payload = useMemo<ITokenPayload>(() => parseJwt(token), [token])
  const isLogged = !!payload

  const logout = useCallback(() => {
    setToken(null)
  }, [setToken])

  useLayoutEffect(() => {
    const interceptor = myAxios.interceptors.response.use(
      response => response,
      error => {
        if (error && error.status === 401)
          logout()
        return Promise.reject(error)
      },
    )

    return () => myAxios.interceptors.response.eject(interceptor)
  }, [logout])

  useLayoutEffect(() => {
    myAxios.defaults.headers.Authorization = token
  }, [token])

  return (
    <TokenContext.Provider
      value={{
        isLogged,
        userId: payload && payload.user_id,
        isCustomerService: payload && !!payload.ucs,
        logout,
        setToken,
      }}
    >
      {isLogged ? <UserState/> : <UnAuthArea/>}
    </TokenContext.Provider>
  )
}
