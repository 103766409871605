import IGetListParams from 'shared/api/hooks/IGetListParams'
import {myAxios} from 'shared/api/myAxios'
import IProduct, {IProductAttributeValue} from 'product/IProduct'
import {IResponsePaginated} from 'shared/api/hooks/useApiListResponse'
import {extractApiData} from 'shared/api/CrudApi'

export interface IStockListData {
  stock: number,
  stock_value: number,
  product: IProduct,
  attribute_value: IProductAttributeValue
}

export interface IStockTotalData {
  stock: number
  stock_value: number
}

interface IStockApi {
  getList: (params: IStockRequestParams) => Promise<IResponsePaginated<IStockListData>>
  export: (params: IStockRequestParams) => Promise<{}>
  totals: any
}

interface IStockRequestParams extends IGetListParams {
  date: string,
  group_by: string
}

export const StockApi: IStockApi = {
  export: (params: IStockRequestParams) => myAxios.get('stock/export', {
    params,
    responseType: 'arraybuffer',
  }),

  getList: (params: IGetListParams) => myAxios.get('stock/list', {params}).then(({data}) => data),

  totals: (params: IGetListParams) => myAxios.get('stock/totals', {params}).then(extractApiData),
}
