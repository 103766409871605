import React from 'react'
import {IInputGenericProps} from 'shared/inputsV2/IInputGenericProps'
import {Button, Menu, MenuItem, Popover, Position} from '@blueprintjs/core'
import {ItemsEqualProp} from '@blueprintjs/select/src/common/listItemsProps'
import {Input} from 'shared/inputsV2/Input'
import {Classes, executeItemsEqual} from '@blueprintjs/select'

export interface IInputSelectProps<T> extends IInputGenericProps<T, HTMLDivElement> {
  items: T[]
  loading?: boolean
  placeholder?: string
  allowSearch?: boolean
  searchPlaceholder?: string
  searchLoading?: boolean
  required?: boolean
  renderItem: (item: T) => JSX.Element | string
  itemsEqual?: ItemsEqualProp<T>
  search?: string
  setSearch?: (value: string) => void
  canAddNew?: boolean
  onNew?: (query: string) => void
  renderAfterItems?: JSX.Element
  nullOptionText?: string
}

function InputSelect<T>(props: IInputSelectProps<T>) {
  const {
    value,
    items,
    onChange,
    intent,
    disabled,
    fill,
    large,
    small,
    loading,
    allowSearch = true,
    searchPlaceholder = 'Cerca...',
    placeholder = 'Seleziona un elemento',
    nullOptionText = '- Nessuno -',
    required,
    renderItem,
    itemsEqual,
    search,
    setSearch,
    onNew,
    canAddNew,
    searchLoading,
    renderAfterItems,
  } = props

  const notNullItems = items || []
  const showNullOption = !required && !search && !!notNullItems.length
  const newText = 'Crea nuovo' + (search ? ` "${search}"` : '')
  const showNoResult = !notNullItems.length

  const popoverContent = <div>
    {allowSearch && <Input
      value={search || ''}
      onChange={setSearch}
      placeholder={searchPlaceholder}
      leftIcon={'search'}
      showResetButton={true}
      autoFocus={true}
      loading={searchLoading}
    />}
    <Menu>
      {showNullOption && <MenuItem
        text={nullOptionText}
        onClick={() => onChange(null)}
        active={!value}
      />}

      {notNullItems.map((item, index) => {
        const active = executeItemsEqual(itemsEqual, item, value)
        return <MenuItem
          key={index}
          text={renderItem(item)}
          onClick={() => onChange(item)}
          active={active}
        />
      })}

      {renderAfterItems}

      {showNoResult && <MenuItem
        text={'Nessun risultato'}
        disabled={true}
      />}

      {canAddNew && <MenuItem
        text={newText}
        icon={'add'}
        onClick={() => onNew(search)}
      />}
    </Menu>
  </div>

  return (
    <Popover
      autoFocus={false}
      enforceFocus={false}
      content={popoverContent}
      fill={fill}
      popoverClassName={Classes.SELECT_POPOVER}
      position={Position.BOTTOM_LEFT}
      disabled={disabled}
    >
      <Button
        fill={true}
        large={large}
        small={small}
        disabled={disabled}
        text={value ? renderItem(value) : placeholder}
        rightIcon={'chevron-down'}
        intent={intent}
        loading={loading}
        alignText={'left'}
      />
    </Popover>
  )
}

export default InputSelect
