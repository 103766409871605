import React, {useMemo, useState} from 'react'
import {Button, Classes, Divider, Menu, MenuItem, Popover} from '@blueprintjs/core'
import {MomentDate} from 'shared/date/MomentDate'
import classNames from 'classnames'
import moment from 'moment'
import DateRangePicker from 'shared/date/DateRangePicker'
import {periodMomentConfigs, valueToPeriodConfig} from 'shared/filters/PeriodPicker/configs'

interface IFilterDateProps {
  value: string
  onChange: (value: string) => void
}

function PeriodPicker(props: IFilterDateProps) {
  const {
    value,
    onChange,
  } = props

  const [pickerMode, setPickerMode] = useState(false)

  const selectedConfig = useMemo(() => valueToPeriodConfig(value), [value])

  const content =
    pickerMode ?
      <CustomPeriodPicker
        value={[selectedConfig?.dateFrom, selectedConfig?.dateTo]}
        onChange={([from, to]) => onChange(from + ',' + to)}
      />
      :
      <Menu>
        {periodMomentConfigs.map(({key, label}) => <MenuItem
          key={key}
          text={label}
          onClick={() => onChange(key)}
          active={value === key}
        />)}
        <Divider/>
        <MenuItem
          active={value === 'custom'}
          text={'Personalizza'}
          shouldDismissPopover={false}
          onClick={() => setPickerMode(true)}
        />
      </Menu>

  const showRangePreview = value !== 'all' && !!selectedConfig
  const selectionPreview = <div>
    {showRangePreview && <DateRangePreview dateFrom={selectedConfig.dateFrom} dateTo={selectedConfig.dateTo}/>}
    {selectedConfig?.label || 'Periodo non valido'}
  </div>

  return (
    <Popover
      content={content}
      onOpening={() => setPickerMode(false)}
    >
      <Button
        text={selectionPreview}
        rightIcon={'chevron-down'}
        large={true}
        fill={true}
        style={{minWidth: '200px'}}
        alignText={'left'}
      />
    </Popover>
  )
}

function DateRangePreview({dateFrom, dateTo}) {
  const defaultFormat = 'D MMM YYYY'
  const from = moment(dateFrom)
  const to = moment(dateTo)
  const fromFormat = from.year() === to.year() ?
    (from.month() === to.month() ? 'D' : 'D MMM') :
    defaultFormat

  const content = from.isSame(to) ?
    <MomentDate date={dateFrom} format={'D MMM YYYY'}/> :
    <><MomentDate date={dateFrom} format={fromFormat}/> - <MomentDate date={dateTo} format={'D MMM YYYY'}/></>

  return (<div className={classNames(Classes.TEXT_MUTED, Classes.TEXT_SMALL)}>
    {content}
  </div>)
}

function CustomPeriodPicker(props) {
  const {
    value,
    onChange,
  } = props

  const [range, setRange] = useState(value)

  const isValid = !!(range[0] && range[1])

  return (
    <div>
      <DateRangePicker
        value={range}
        onChange={setRange}
        maxDate={moment()}
      />

      <Divider/>

      <Button
        text={'Annulla'}
        className={Classes.POPOVER_DISMISS}
      />
      <Button
        text={'Conferma'}
        intent={'primary'}
        className={Classes.POPOVER_DISMISS}
        onClick={() => onChange(range)}
        disabled={!isValid}
      />
    </div>
  )
}

export default PeriodPicker
