import {Dispatch, SetStateAction, useCallback} from 'react'
import {useLocalStorage} from './useLocalStorage'
import _ from 'lodash'

export function useLocalStorageNumber(key: string): [number, Dispatch<SetStateAction<number>>] {
  const [value, setStringValue] = useLocalStorage(key)

  const setValue = useCallback((value: number) => {
    const stringValue = _.isNil(value) ? null : value.toString()
    setStringValue(stringValue)
  }, [setStringValue])

  return [+value, setValue]
}
