import _ from 'lodash'

const insert = (arrayValue, value, index?) => {
  const arr = Array.isArray(arrayValue) ? arrayValue : []

  if (!_.isInteger(index))
    return [...arr, value]
  if (index >= 0 && index <= arr.length)
    return [...arr.slice(0, index), value, ...arr.slice(index)]
  return [...arr]
}
const remove = (arr, index) => [...arr.slice(0, index), ...arr.slice(index + 1)]
const update = (arr, value, index) => [...arr.slice(0, index), value, ...arr.slice(index + 1)]
const insertOrUpdate = (arr, value, index?) =>
  _.isInteger(index) && index >= 0 && index <= arr.length ?
    update(arr, value, index) :
    insert(arr, value)

const Arr = {
  insert,
  remove,
  update,
  insertOrUpdate,
}

export default Arr
