import React from 'react'
import classNames from 'classnames'
import {Classes, HTMLDivProps, Icon, IconName, IIconProps, Intent} from '@blueprintjs/core'

interface IFakeInputProps extends HTMLDivProps {
  disabled?: boolean
  fill?: boolean
  intent?: Intent
  large?: boolean
  leftIcon?: IconName
  rightElement?: JSX.Element
  active?: boolean
  leftIconProps?: Partial<IIconProps & React.DOMAttributes<HTMLElement>>
}

function FakeInput(props: IFakeInputProps) {
  const {
    className,
    disabled,
    fill,
    intent,
    large,
    leftIcon,
    active,
    children,
    rightElement,
    leftIconProps,
    ...divProps
  } = props

  const classes = classNames(
    Classes.INPUT,
    Classes.TAG_INPUT,
    {
      [Classes.ACTIVE]: active,
      [Classes.DISABLED]: disabled,
      [Classes.FILL]: fill,
      [Classes.LARGE]: large,
    },
    Classes.intentClass(intent),
    className,
  )
  const isLarge = classes.indexOf(Classes.LARGE) > -1

  return (
    <div
      className={classes}
      {...divProps}
    >
      <Icon
        {...leftIconProps}
        className={Classes.TAG_INPUT_ICON}
        icon={leftIcon}
        iconSize={isLarge ? Icon.SIZE_LARGE : Icon.SIZE_STANDARD}
      />
      <div className={Classes.TAG_INPUT_VALUES}>
        {children}
      </div>
      {rightElement}
    </div>
  )
}

export default FakeInput
