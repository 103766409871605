import React from 'react'
import {AppMenu} from './AppMenu'
import {Redirect, Route, Switch} from 'react-router'
import {SettingsPage} from 'settings/SettingsPage'
import {PageContainer} from 'shared/components/PageContainer'
import {PurchasesListPage} from 'document/movement/purchase/PurchasesListPage'
import {SalesListPage} from 'document/movement/sale/SalesListPage'
import {PurchasePage} from 'document/movement/purchase/PurchasePage'
import {SalePage} from 'document/movement/sale/SalePage'
import {OrdersListPage} from 'document/order/OrdersListPage'
import {OrderPage} from 'document/order/OrderPage'
import {CounterpartsListPage} from 'counterpart/pages/CounterpartsListPage'
import {CounterpartPage} from 'counterpart/pages/CounterpartPage'
import {ProductsListPage} from 'product/ProductsListPage'
import {ProductPage} from 'product/ProductPage'
import TakingsPage from 'analytics/AnalyticsPage'

export const AppBase = () => {
  return (
    <>
      <AppMenu/>

      <PageContainer maxWidth={'900px'} minWidth={'400px'}>
        <Switch>
          <Route path='/product' exact component={ProductsListPage}/>
          <Route path='/product/:id' exact component={ProductPage}/>

          <Route path='/counterpart' exact component={CounterpartsListPage}/>
          <Route path='/counterpart/:id' exact component={CounterpartPage}/>

          <Route path='/order' exact component={OrdersListPage}/>
          <Route path='/order/:id' exact component={OrderPage}/>

          <Route path='/purchase' exact component={PurchasesListPage}/>
          <Route path='/purchase/:id' exact component={PurchasePage}/>

          <Route path='/sale' exact component={SalesListPage}/>
          <Route path='/sale/:id' exact component={SalePage}/>

          <Route path='/analytics' component={TakingsPage}/>

          <Route path='/settings' component={SettingsPage}/>

          <Redirect to='/product'/>
        </Switch>
      </PageContainer>
    </>
  )
}
