import {useFormState} from 'react-final-form'
import {useToggleState} from 'shared/hooks/useToggleState'
import {useCounterpartLabel} from 'counterpart/components/useCounterpartLabel'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import React from 'react'
import {InputDate} from 'shared/inputs/InputDate'
import {CounterpartSelect} from 'counterpart/components/CounterpartSelect'
import {InputText} from 'shared/inputsV2/InputText'
import {Icon} from '@blueprintjs/core'
import {FakeLink} from 'shared/styled/FakeLink'
import {Input} from 'shared/inputsV2/Input'


export function DocumentFormData({counterpartRole, counterpartRequired}) {
  const {values} = useFormState()
  const showNote = useToggleState(!!(values && values.note))
  const counterpartLabel = useCounterpartLabel(counterpartRole)

  return (
    <div>
      <FieldFormGroup
        name='counterpart'
        label={counterpartLabel}
        required={counterpartRequired}
        render={props => <CounterpartSelect
          {...props}
          role={counterpartRole}
          canAddNew={true}
          required={counterpartRequired}
        />}
      />

      <FieldFormGroup
        name='date_time'
        label='Data'
        required={true}
        render={props => <InputDate
          {...props}
          timePrecision={'second'}
          displayFormat={'LL HH:mm:ss'}
          closeOnSelection={false}
        />}
      />

      <FieldFormGroup
        name='number'
        label='N° Documento'
        render={props => <Input
          {...props}
        />}
      />

      <hr/>

      {showNote.isOn ?
        <FieldFormGroup
          name='note'
          label='Note'
          render={props => <InputText
            {...props}
            autoFocus={true}
            onBlur={() => {
              const note = props.value || ''
              if (!note.trim())
                showNote.off()
            }}
          />}
        /> :
        <FakeLink onClick={showNote.on}><Icon icon={'small-plus'}/>Aggiungi note</FakeLink>
      }
    </div>
  )
}
