import {Colors, HTMLDivProps} from '@blueprintjs/core'
import styled, {css} from 'styled-components'
import React from 'react'
import {AppText} from '../components/AppText'

export interface IDetailsViewerItem {
  value: string | number | JSX.Element
  label?: string
  hidden?: boolean
  interactive?: boolean
  onClick?
}

export interface IDetailsViewerProps {
  items: IDetailsViewerItem[]
}

export function DetailsViewer(props: IDetailsViewerProps) {
  const {items} = props
  const filteredItems = items.filter(item => !item.hidden)

  return (
    <DetailsViewerTag>
      {filteredItems.map(({label, value, interactive, onClick}, index) =>
        <DataText
          key={index}
          label={label}
          value={value}
          interactive={interactive}
          onClick={onClick}
        />,
      )}
    </DetailsViewerTag>
  )
}

interface IDataTextProps extends HTMLDivProps {
  label: JSX.Element | string | number
  value: JSX.Element | string | number
  interactive: boolean
}

function DataText(props: IDataTextProps) {
  const {label, value, ...divProps} = props

  return (
    <InteractiveDataTag {...divProps}>
      {label && <AppText muted={true}>{label}: </AppText>}
      <AppText preventNewLine={true}>{value}</AppText>
    </InteractiveDataTag>
  )
}

const DetailsViewerTag = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`

const interactiveStyle = css`
  :hover {
    background-color: ${Colors.LIGHT_GRAY3};
  }

  border-radius: 4px;
  cursor: pointer;
`

const InteractiveDataTag = styled(AppText)<{ interactive?: boolean }>`
  display: flex;
  padding: 2px;
  margin-right: 4px;
  overflow: hidden;
  ${props => props.interactive && interactiveStyle}
`
