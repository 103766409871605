import {Dispatch, SetStateAction, useCallback, useMemo} from 'react'
import {IFilterValue} from 'shared/filters/InputFilters'

const key = 'filters'

export function useParamFilter([params, setParams], defaultValue = undefined): [IFilterValue[], Dispatch<SetStateAction<IFilterValue[]>>] {
  const value = params?.[key]

  const filters = useMemo(() => {
      const obj: IFilterValue[] = JSON.parse(value || '[]')
      return obj.filter(item => item && item.field && item.op)
    },
    [value])

  const setFilter = useCallback(obj => {
    const newParam = obj?.length > 0 ? JSON.stringify(obj) : null
    setParams({...params, [key]: newParam, page: 1})
  }, [params, setParams])

  return [filters, setFilter]
}
