import React, {useCallback, useState} from 'react'
import {Button, IButtonProps} from '@blueprintjs/core'

interface IButtonApiProps<T> extends Omit<IButtonProps, 'loading' | 'onClick'> {
  apiCall: () => Promise<any>
  onSuccess?: (response: T) => any
  onError?: (response: T) => any
}

export function ButtonApi<T>(props: IButtonApiProps<T>) {
  const {
    apiCall,
    onSuccess,
    onError,
    ...buttonProps
  } = props
  const [loading, setLoading] = useState<boolean>(false)

  const onClick = useCallback(() => {
    setLoading(true)
    apiCall()
      .then(response => onSuccess && setTimeout(() => onSuccess(response)))
      .catch(error => onError && setTimeout(() => onError(error)))
      .finally(() => setLoading(false))
  }, [apiCall, onSuccess, onError])

  return (
    <Button
      loading={loading}
      onClick={onClick}
      {...buttonProps}
    />
  )
}
