import React from 'react'
import {Table} from '../table/Table'
import {Classes} from '@blueprintjs/core'
import _ from 'lodash'

interface IDataViewerProps extends React.HTMLAttributes<HTMLTableElement> {
  data: {
    label: any
    value: any
    hidden?: boolean
  }[]
  hideRowIfNullValue?: boolean
  striped?: boolean
}

export function DataViewer(props: IDataViewerProps) {
  const {
    data,
    hideRowIfNullValue,
    striped = true,
    ...tableProps
  } = props

  const filteredData = data.filter(({value, hidden}) => !(hidden || (hideRowIfNullValue && _.isNil(value))))

  return (
    <Table
      {...tableProps}
      hideHeader={true}
      striped={striped}
      data={filteredData}
      columns={[
        {
          renderCell: row => row.label,
          cellProps: () => ({className: Classes.TEXT_MUTED}),
        },
        {
          renderCell: row => row.value,
        },
      ]}
    />
  )
}
