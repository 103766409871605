import React from 'react'
import {AppText} from 'shared/components/AppText'
import {Card} from 'shared/styled/Card'

interface ITotalCardProps {
  label: string
  value: any
  loading?: boolean
}

function TotalCard(props: ITotalCardProps) {
  const {
    label,
    value,
  } = props

  return (
    <Card>
      <AppText muted>{label}</AppText>
      <AppText size={'XL'}>{value}</AppText>
    </Card>
  )
}

export default TotalCard
