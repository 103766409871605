import {Dispatch, SetStateAction, useMemo} from 'react'
import {Period, valueToPeriodConfig} from 'shared/filters/PeriodPicker/configs'
import {IFilterValue} from 'shared/filters/InputFilters'

export interface IPeriodParamConfig {
  key?: string
  defaultValue?: Period
  field?: string
}

const defaultConfig: IPeriodParamConfig = {
  key: 'period',
  defaultValue: undefined,
  field: 'date',
}

export function useParamPeriod([params, setParams], config: IPeriodParamConfig = {}): [string, Dispatch<SetStateAction<string>>, IFilterValue[]] {
  const {
    key,
    defaultValue,
    field,
  } = {
    ...defaultConfig,
    ...config,
  }

  const value = params?.[key]

  const period = useMemo(() => {
    if (!valueToPeriodConfig(value))
      return defaultValue
    return value
  }, [value, defaultValue])

  const setPeriod = period => setParams({...params, [key]: period, page: 1})

  const periodFilters = useMemo<IFilterValue[]>(() => {
    const config = valueToPeriodConfig(period)
    if (!config || !config.dateFrom || !config.dateTo)
      return []

    return [
      {
        field,
        op: '>=',
        value: config.dateFrom,
      },
      {
        field,
        op: '<=',
        value: config.dateTo,
      },
    ]
  }, [period, field])

  return [period, setPeriod, periodFilters]
}
