import React, {KeyboardEventHandler, useMemo} from 'react'
import {Button, IconName, InputGroup, Spinner} from '@blueprintjs/core'
import {IInputGenericProps} from './IInputGenericProps'

export interface IInputProps extends IInputGenericProps<string, HTMLInputElement> {
  autoComplete?: boolean
  type?: 'text' | 'number' | 'password'
  placeholder?: string
  rightElement?: JSX.Element
  loading?: boolean
  leftIcon?: IconName
  leftElement?: JSX.Element
  showResetButton?: boolean
  min?: number
  max?: number
  onEnter?: (e: KeyboardEventHandler) => void
}

export function Input(props: IInputProps) {
  const {
    value,
    onChange,
    autoComplete,
    rightElement,
    loading,
    showResetButton,
    defaultValue,
    onKeyDown,
    onEnter,
    ...others
  } = props

  const _rightElement = useMemo(() => {
    if (loading)
      return <Spinner size={20}/>

    if (showResetButton && !!value)
      return <Button
        icon='cross'
        minimal={true}
        onClick={() => onChange('')}
      />
    return rightElement
  }, [value, loading, rightElement, showResetButton, onChange])

  const _onKeyDown = e => {
    if (e.keyCode === 13 && onEnter)
      onEnter(e)
    if (onKeyDown)
      onKeyDown(e)
  }

  return (
    <InputGroup
      {...others}
      onKeyDown={_onKeyDown}
      rightElement={_rightElement}
      value={value || ''}
      autoComplete={autoComplete ? 'on' : 'off'}
      onChange={event => onChange(event.target.value)}
    />
  )
}
