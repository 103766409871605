import React from 'react'
import {Button, IButtonProps} from '@blueprintjs/core'
import {UnstyledLink} from '../components/UnstyledLink'

interface IButtonApiProps<T> extends IButtonProps {
  to: string
}

export function ButtonLink<T>(props: IButtonApiProps<T>) {
  const {
    to,
    ...buttonProps
  } = props

  return (
    <UnstyledLink to={to}>
      <Button
        {...buttonProps}
      />
    </UnstyledLink>
  )
}
