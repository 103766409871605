import React, {useContext} from 'react'
import {PageContainer} from 'shared/components/PageContainer'
import {Form} from 'react-final-form'
import {Button} from '@blueprintjs/core'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {InputPassword} from 'shared/inputsV2/InputPassword'
import {FieldStrongPassword} from '../auth/FieldStrongPassword'
import {AuthApi} from '../auth/AuthApi'
import {useApiFormSubmitter} from 'shared/api/hooks/useApiFormSubmitter'
import {Intent} from '@blueprintjs/core/lib/esm/common/intent'
import {InlineGroup} from 'shared/styled/InlineGroup'
import {IUser} from './IUser'
import {UserContext} from './UserState'
import {Input} from 'shared/inputsV2/Input'

function Section(props) {
  const {
    title,
    children,
  } = props

  return (
    <div>
      <h2>{title}</h2>
      <hr/>
      {children}
    </div>
  )
}

const validateSamePassword = (value, allValues) => {
  if (value !== allValues['newPassword'])
    return 'Le password non corrispondono'
}

export function UserSettingsPage() {
  const passwordSubmitter = useApiFormSubmitter(AuthApi.changePassword)
  const {user, updateUser, updatingUser} = useContext(UserContext)

  return (
    <PageContainer maxWidth={'900px'} minWidth={'400px'}>
      <Section title={'Dettagli utente'}>
        <Form<IUser>
          initialValues={user}
          onSubmit={updateUser}
          render={({handleSubmit}) =>
            <form onSubmit={handleSubmit}>
              <InlineGroup>
                <FieldFormGroup
                  name={'first_name'}
                  label={'Nome'}
                  render={params => <Input {...params}/>}
                />

                <FieldFormGroup
                  name={'last_name'}
                  label={'Cognome'}
                  render={params => <Input {...params}/>}
                />
              </InlineGroup>

              <Button
                type={'submit'}
                text={'Salva'}
                intent={Intent.SUCCESS}
                loading={updatingUser}
              />
            </form>
          }
        />
      </Section>

      <Section title={'Aggiorna password'}>
        <Form
          onSubmit={passwordSubmitter.onSubmit}
          render={({handleSubmit, form}) =>
            <form onSubmit={event => {
              handleSubmit(event).then((result) => {
                if (!result)
                  form.reset()
              })
            }}>
              <FieldFormGroup
                name={'oldPassword'}
                label={'Password attuale'}
                render={params => <InputPassword {...params}/>}
                required={true}
              />

              <FieldStrongPassword
                name={'newPassword'}
                label={'Nuova password'}
              />

              <FieldFormGroup
                name={'confirmPassword'}
                label={'Conferma password'}
                validators={validateSamePassword}
                render={params => <InputPassword {...params}/>}
              />

              <Button
                type={'submit'}
                text={'Aggiorna password'}
                loading={passwordSubmitter.loading}
              />
            </form>
          }
        />
      </Section>
    </PageContainer>
  )
}
