import React, {useCallback} from 'react'
import {Tab, Tabs} from '@blueprintjs/core'
import {useApiListResponse} from 'shared/api/hooks/useApiListResponse'
import ICounterpart from 'counterpart/ICounterpart'
import CounterpartApi from 'counterpart/CounterpartApi'
import DataTable from 'shared/table/DataTable'
import {useParamPage} from 'shared/params/useParamPage'
import {useRouterParams} from 'shared/routing/useRouterParams'
import {useParamSort} from 'shared/params/useParamSort'
import {useLocalStorage} from 'use-hooks'
import Row from 'settings/Row'
import {SearchField} from 'shared/components/SearchField'
import {useRouterParam} from 'shared/routing/useRouterParam'
import {ISortParam} from 'shared/api/hooks/IGetListParams'
import PageHeader from 'shared/components/PageHeader'
import {ButtonLink} from 'shared/routing/ButtonLink'
import {Intent} from '@blueprintjs/core/lib/esm/common/intent'

const defaultSort: ISortParam = {sortBy: 'name', sortDir: 'asc'}
const sideEffect = {page: 1}

export function CounterpartsListPage() {
  const routerParams = useRouterParams()
  const [role, setRole] = useLocalStorage('CounterpartsListPage.role', 'all')
  const [search, setSearch] = useRouterParam('search', '', sideEffect)
  const [page, setPage] = useParamPage(routerParams)
  const [sort, setSort] = useParamSort(routerParams, defaultSort)

  const listCall = useCallback(() => {
    const filters = []
    if (role !== 'all')
      filters.push({field: 'role', op: '=', value: role})
    return CounterpartApi.getList({filters, search, page, ...sort})
  }, [role, page, sort, search])

  const listResponse = useApiListResponse<ICounterpart>(listCall)

  return (
    <div>
      <PageHeader
        title={'Anagrafiche'}
        rightElement={<ButtonLink
          icon={'plus'}
          to={'counterpart/new'}
          text={'Crea nuovo'}
          intent={Intent.SUCCESS}
          large={true}
        />}
      />

      <Row childrenMargin>
        <SearchField
          value={search}
          onChange={setSearch}
          large={true}
          fill={true}
          placeholder={'Cerca...'}
        />
      </Row>

      <Tabs
        renderActiveTabPanelOnly={true}
        selectedTabId={role}
        onChange={setRole}
      >
        <Tab
          id={'all'}
          title={'Tutti'}
        />
        <Tab
          id={'customer'}
          title={'Clienti'}
        />
        <Tab
          id={'supplier'}
          title={'Fornitori'}
        />
      </Tabs>

      <DataTable
        apiListResponse={listResponse}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        sortOptions={[
          {label: 'Nome', sortBy: 'name'},
        ]}
        rowLink={({id}) => 'counterpart/' + id}
        columns={[
          {
            name: 'name',
            renderCell: ({name}) => name,
            renderHead: 'Nome',
          },
          {
            name: 'phone',
            renderCell: ({phone_number}) => phone_number,
            renderHead: 'Telefono',
          },
        ]}
      />
    </div>
  )
}
