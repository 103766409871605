import React from 'react'
import {TextArea} from '@blueprintjs/core'
import {IInputGenericProps} from './IInputGenericProps'

export interface IInputTextProps extends IInputGenericProps<string, HTMLTextAreaElement> {
  autoComplete?: boolean
}

export function InputText(props: IInputTextProps) {
  const {
    onChange,
    autoComplete,
    ...others
  } = props
  return (
    <TextArea
      {...others}
      style={{resize: 'vertical'}}
      growVertically={true}
      autoComplete={autoComplete ? 'on' : 'off'}
      onChange={event => onChange(event.target.value)}
    />
  )
}
