import React from 'react'
import {Button, ButtonGroup, Menu, MenuItem, Popover, Tooltip} from '@blueprintjs/core'
import styled from 'styled-components'
import {IApiListResponse} from 'shared/api/hooks/useApiListResponse'

interface IPageSelectorProps<T> {
  page: number
  setPage: (page: number) => void
  listResponse: IApiListResponse<T>
}

export function PageSelector<T>(props: IPageSelectorProps<T>) {
  const {setPage, listResponse} = props
  const {from, to, total, lastPage} = listResponse

  const page = +props.page || 1
  const isFirstPage = +page === 1
  const isLastPage = +page === +lastPage

  return (
    <PageSelectorTag>
      <Popover
        interactionKind={'hover'}
        content={<Menu>
          <MenuItem
            text={'Inizio'}
            disabled={isFirstPage}
            onClick={() => setPage(1)}
          />
          <MenuItem
            text={'Fine'}
            disabled={isLastPage}
            onClick={() => setPage(+lastPage)}
          />
        </Menu>}
      >
        <Button
          minimal={true}
          text={from + '-' + to + ' di ' + total}
        />
      </Popover>
      <ButtonGroup>
        <Tooltip
          disabled={isFirstPage}
          content={'Pagina precedente'}
        >
          <Button
            disabled={isFirstPage}
            icon='chevron-left'
            onClick={() => setPage(page - 1)}
          />
        </Tooltip>
        <Tooltip
          disabled={isLastPage}
          content={'Pagina successiva'}
        >
          <Button
            disabled={isLastPage}
            icon='chevron-right'
            onClick={() => setPage(page + 1)}
          />
        </Tooltip>
      </ButtonGroup>
    </PageSelectorTag>
  )
}

const PageSelectorTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
`
