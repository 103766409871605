import React, {useMemo, useRef, useState} from 'react'
import FakeInput from 'shared/filters/FakeInput'
import {Classes, Divider, Menu, MenuItem, Popover, Position} from '@blueprintjs/core'
import Arr from 'shared/utils/Arr'
import './style.css'
import _ from 'lodash'
import FilterSearch from 'shared/filters/filtersTag/FilterSearch'

export interface IFilterFieldValue {
  op: string
  value: any
}

export interface IFilterValue extends IFilterFieldValue {
  field: string
}

export interface IRenderTagProps {
  key: string
  value: IFilterFieldValue
  onChange: (IFilterFieldValue) => void
  onRemove?: () => void
  onClose?: () => void
  name: string
  isNew: boolean
}

export interface IFilterConfig {
  name: string
  renderTag: (props: IRenderTagProps) => JSX.Element
}

export interface IFiltersConfigs {
  [field: string]: IFilterConfig
}

interface IInputFiltersProps {
  value: IFilterValue[]
  onChange: (value: IFilterValue[]) => void
  configs: IFiltersConfigs
  search?: string
  setSearch?: (value: string) => void
}

function InputFilters(props: IInputFiltersProps) {
  const {
    value,
    onChange,
    configs,
    search,
    setSearch,
  } = props

  const validatedValue = useMemo(() =>
      value.filter(({field}) => !!configs[field]),
    [value, configs])
  const [inputValue, setInputValue] = useState('')
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isAdding, setIsAdding] = useState<string>()
  const inputRef = useRef<HTMLInputElement>()

  const handleInputFocus = e => setIsPopoverOpen(true)

  const handlePopoverInteraction = (nextOpenState: boolean, e) => requestAnimationFrame(() => {
    if (nextOpenState)
      return

    const isInputFocused = inputRef.current === document.activeElement
    if (!isInputFocused)
      setIsPopoverOpen(false)
  })

  const isSearchActive = !!search

  const hasValue = validatedValue?.length > 0 || isSearchActive

  const placeholder = (hasValue || isAdding) ? null : 'Filtri'

  const onNewSelected = (field) => {
    setInputValue('')
    setIsAdding(field)
  }

  const insertFilter = (filter) => onChange(Arr.insert(validatedValue, filter))

  const updateFilter = (filter, index) => onChange(Arr.update(validatedValue, filter, index))

  const removeFilter = index => onChange(Arr.remove(validatedValue, index))

  const inUseFields = validatedValue.map(({field}) => field)
  const configFields = Object.keys(configs)

  const availableConfigsKeys: string[] = _.difference(configFields, inUseFields)
  const filteredConfigsKeys = availableConfigsKeys.filter(field => configs[field].name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0)

  const showSearchOption = !!setSearch && !!inputValue && !search
  const showDivider = filteredConfigsKeys.length > 0 && showSearchOption
  const showNoFilters = filteredConfigsKeys.length === 0 && !showSearchOption

  const popoverContent = <Menu>
    {filteredConfigsKeys.map(field => <MenuItem
      key={field}
      text={configs[field].name}
      shouldDismissPopover={true}
      onClick={() => onNewSelected(field)}
    />)}

    {showDivider && <Divider/>}

    {showSearchOption && <MenuItem
      text={'Cerca "' + inputValue + '"'}
      icon={'search'}
      shouldDismissPopover={true}
      onClick={() => {
        setSearch(inputValue)
        setInputValue('')
      }}
    />}

    {showNoFilters && <MenuItem
      text={'Nessun filtro corrispondente'}
      className={Classes.DISABLED}
    />}
  </Menu>

  const newFilter = !!isAdding ?
    configs[isAdding].renderTag({
      key: isAdding,
      value: null,
      onChange: ({op, value}) => insertFilter({field: isAdding, op, value}),
      name: configs[isAdding].name,
      onClose: () => setIsAdding(null),
      isNew: true,
    }) : null

  return (
    <FakeInput
      large={true}
      className={'input-filters'}
      active={isPopoverOpen}
      leftIcon={'filter'}
      leftIconProps={{onClick: () => inputRef.current.focus()}}
    >
      {!!search && <FilterSearch
        value={search}
        onChange={setSearch}
      />}

      {validatedValue
        .map(({field, op, value}, index) => {
          const config = configs[field]
          return config.renderTag({
            key: field,
            value: {op, value},
            onChange: ({op, value}) => updateFilter({field, op, value}, index),
            onRemove: () => removeFilter(index),
            name: config.name,
            isNew: false,
          })
        })}

      {newFilter}

      <Popover
        isOpen={isPopoverOpen}
        content={popoverContent}
        onInteraction={handlePopoverInteraction}
        minimal={true}
        position={Position.BOTTOM_LEFT}
        modifiers={{offset: {offset: '0, 6px'}}}
        className={'input'}
      >
        <input
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          ref={inputRef}
          className={Classes.INPUT_GHOST}
          onFocus={handleInputFocus}
          placeholder={placeholder}
        />
      </Popover>
    </FakeInput>
  )
}

export default InputFilters
