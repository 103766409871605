import React from 'react'
import styled from 'styled-components'
import {IDocument} from './IDocument'
import {FullScreenSection} from 'shared/components/FullScreenPage'
import {MoneyValue} from 'shared/components/MoneyValue'
import {AppText} from 'shared/components/AppText'

interface IDocumentTotalsStepperSectionProps {
  isGrossMode: boolean
  movement: IDocument
}

export function DocumentTotalsStepperSection(props: IDocumentTotalsStepperSectionProps) {
  const {
    isGrossMode,
    movement: {
      net,
      gross,
      isEmpty,
    },
  } = props

  if (isEmpty)
    return null

  const value = (isGrossMode ? gross : net) || 0
  return (
    <FullScreenSection>
      <Container
        intent={'primary'}
        bold={true}
        size={'XL'}
      >
        <span>Totale {isGrossMode ? 'lordo' : 'netto'}</span>

        <span>
          <MoneyValue value={value}/>
        </span>
      </Container>
    </FullScreenSection>
  )
}

const Container = styled(AppText)`
display: flex;
flex-direction: row;
justify-content: space-between;
font-size: 20px;
`
