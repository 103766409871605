import React from 'react'
import {FormManager, IGenericFormProps} from 'shared/form/FormManager'
import ICounterpart from '../ICounterpart'
import CounterpartApi from '../CounterpartApi'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {InputRadio} from 'shared/inputs/InputRadio'
import {IOptionProps} from '@blueprintjs/core/src/common/props'
import {InlineGroup} from 'shared/styled/InlineGroup'
import {InputText} from 'shared/inputsV2/InputText'
import {Input} from 'shared/inputsV2/Input'

interface ICounterpartFormProps extends IGenericFormProps<ICounterpart> {
  hideRoleSelector?: boolean
}

export default function CounterpartForm(props: ICounterpartFormProps) {
  const {hideRoleSelector, ...formProps} = props

  const roleOptions: IOptionProps[] = [
    {
      label: 'Fornitore',
      value: 'supplier',
    },
    {
      label: 'Cliente',
      value: 'customer',
    },
  ]

  return (
    <FormManager
      {...formProps}
      api={CounterpartApi}
      render={(form, entityActions) => <>
        {!hideRoleSelector && entityActions.isNew && <FieldFormGroup
          name='role'
          label='Ruolo'
          type='radioGroup'
          required={true}
          render={input => <InputRadio
            {...input}
            options={roleOptions}
          />}
        />}

        <FieldFormGroup
          label='Nome'
          name='name'
          required={true}
          render={input => <Input {...input} autoFocus={true}/>}
        />

        <InlineGroup>
          <FieldFormGroup
            label='Email'
            name='email'
            render={input => <Input {...input} leftIcon={'envelope'}/>}
          />

          <FieldFormGroup
            label='Numero di telefono'
            name='phone_number'
            render={input => <Input {...input} leftIcon={'phone'}/>}
          />
        </InlineGroup>

        <FieldFormGroup
          label='Note'
          name='note'
          render={input => <InputText {...input}/>}
        />
      </>}
    />
  )
}
