import React, {useCallback} from 'react'
import {useApiListStatusManager} from 'shared/api/hooks/useApiListStatusManager'
import IGetListParams from 'shared/api/hooks/IGetListParams'
import {SaleApi} from './SaleApi'
import {useApiListResponse} from 'shared/api/hooks/useApiListResponse'
import {IMovementGoodItem} from '../IMovementGoodItem'
import {ISaleReturnedItem} from './ISaleReturnedItem'
import {Classes} from '@blueprintjs/core'
import {DataList} from 'shared/list/DataList'
import {ReturnableItemListRow} from './ReturnableItemListRow'

interface IReturnableItemsListProps {
  isGrossMode: boolean
  onItemSelect: (value: ISaleReturnedItem) => void
  actualSaleId: number
}

export function ReturnableItemsList(props: IReturnableItemsListProps) {
  const {
    isGrossMode,
    onItemSelect,
    actualSaleId,
  } = props

  const paramsManager = useApiListStatusManager()
  const {apiParams} = paramsManager

  const getList = useCallback(() => {
    const filters = []
    if (actualSaleId)
      filters.push({
        field: 'movement_id',
        op: '!=',
        value: actualSaleId,
      })

    const params: IGetListParams = {
      ...apiParams,
      filters,
    }

    return SaleApi.getReturnable(params)
  }, [apiParams, actualSaleId])

  const apiListResponse = useApiListResponse(getList)

  const onItemSelectMap = (item: IMovementGoodItem) => {
    const net = item.net / item.quantity
    const gross = item.gross / item.quantity

    onItemSelect({
      product: item.product,
      quantity: 1,
      unit_net: net,
      unit_gross: gross,
      net,
      gross,
      vat: item.vat,
      returned_from: item,
    })
  }

  return (
    <div className={Classes.DIALOG_BODY}>
      <DataList<IMovementGoodItem>
        renderItem={item => <ReturnableItemListRow
          item={item}
          isGrossMode={isGrossMode}
        />}
        paramsManager={paramsManager}
        apiListResponse={apiListResponse}
        interactive={true}
        itemRowProps={item => ({
          onClick: () => onItemSelectMap(item),
        })}
      />
    </div>
  )
}
