import React from 'react'
import moment, {Moment} from 'moment'
import styled from 'styled-components'

export function MomentDate(props?: { date: string | Moment, format?: string }) {
  const {date, format} = props
  return (
    <DateTag>
      {date && moment(date).format(format || 'LL')}
    </DateTag>
  )
}

const DateTag = styled.span`
  white-space: nowrap;
`
