import moment, {Moment} from 'moment'
import {DATE_FORMAT} from 'shared/date/date'

export type Period =
  'today'
  | 'week'
  | '4_weeks'
  | 'quarter'
  | 'year'
  | 'all'
  | 'current_year'
  | 'previous_year'
  | 'd_previous_year'
  | 'custom'

interface IMomentPeriodConfig {
  key: Period
  dateFrom: Moment
  dateTo: Moment
  label: string
}

export const periodMomentConfigs: IMomentPeriodConfig[] = [
  {
    key: 'today',
    dateFrom: moment(),
    dateTo: moment(),
    label: 'Oggi',
  },
  {
    key: 'week',
    dateFrom: moment().subtract(7, 'd'),
    dateTo: moment(),
    label: 'Ultimi 7 giorni',
  },
  {
    key: '4_weeks',
    dateFrom: moment().subtract(28, 'd'),
    dateTo: moment(),
    label: 'Ultimi 28 giorni',
  },
  {
    key: 'quarter',
    dateFrom: moment().subtract(90, 'd'),
    dateTo: moment(),
    label: 'Ultimi 90 giorni',
  },
  {
    key: 'year',
    dateFrom: moment().subtract(365, 'd'),
    dateTo: moment(),
    label: 'Ultimi 365 giorni',
  },
  {
    key: 'all',
    dateFrom: null,
    dateTo: null,
    label: 'Tutto il periodo',
  },
  {
    key: 'current_year',
    dateFrom: moment().startOf('year'),
    dateTo: moment(),
    label: moment().year().toString(),
  },
  {
    key: 'previous_year',
    dateFrom: moment().subtract(1, 'year').startOf('year'),
    dateTo: moment().subtract(1, 'year').endOf('year'),
    label: moment().subtract(1, 'year').year().toString(),
  },
  {
    key: 'd_previous_year',
    dateFrom: moment().subtract(2, 'year').startOf('year'),
    dateTo: moment().subtract(2, 'year').endOf('year'),
    label: moment().subtract(2, 'year').year().toString(),
  },
]

const customPeriodRegex = /([0-9-]*),([0-9-]*)/

function valueToMomentPeriodConfig(value: string): IMomentPeriodConfig {
  const config = periodMomentConfigs.find(({key}) => key === value)

  if (config)
    return config

  const regexResult = (value || '').match(customPeriodRegex)
  if (!regexResult)
    return null

  const dateFrom = moment(regexResult[1])
  const dateTo = moment(regexResult[2])

  if (!dateFrom.isValid() || !dateTo.isValid() || dateFrom.isAfter(dateTo))
    return null

  return {
    key: 'custom',
    label: 'Personalizzata',
    dateFrom,
    dateTo,
  }
}

export interface IPeriodConfig {
  key: string
  dateFrom: string
  dateTo: string
  label: string
}

export function valueToPeriodConfig(value: string): IPeriodConfig {
  const config = valueToMomentPeriodConfig(value)
  if (!config)
    return null
  return {
    key: config.key,
    label: config.label,
    dateFrom: config.dateFrom?.format(DATE_FORMAT),
    dateTo: config.dateTo?.format(DATE_FORMAT),
  }
}
