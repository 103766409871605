import React from 'react'
import {Form} from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {IItemsFormSectionState, ItemsFormSection} from '../ItemsFormSection'
import {IDocumentGoodItem} from '../IDocumentGoodItem'
import {Button, Classes, Intent} from '@blueprintjs/core'
import {MoneyUtils} from 'shared/utils/moneyUtils'
import {MoneyItemListRow} from './MoneyItemListRow'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {InlineGroup} from 'shared/styled/InlineGroup'
import {Validators} from 'shared/form/Validators'
import {InputMoney} from 'shared/inputsV2/InputMoney'
import {InputVat} from 'shared/inputs/InputVat'
import {TotalEditor} from './TotalEditor'
import {Input} from 'shared/inputsV2/Input'

interface IMoneysItemsFormSectionProps {
  isGrossMode: boolean
  state: IItemsFormSectionState<IDocumentGoodItem>
}

export function MoneysItemsFormSection(props: IMoneysItemsFormSectionProps) {
  const {
    state,
    isGrossMode,
  } = props

  const {
    selectedValue,
    onDialogSave,
    isNewSelected,
    removeSelected,
  } = state

  const priceFieldProps = isGrossMode ?
    {name: 'gross', label: `Valore lordo`} :
    {name: 'net', label: `Valore netto`}

  const onSubmit = value => onDialogSave(moneyItemMapper(value, isGrossMode))

  const renderDialog = isNewSelected ?
    <TotalEditor
      isGrossMode={isGrossMode}
      onSubmit={onSubmit}
    /> :
    <Form<IDocumentGoodItem>
      onSubmit={onSubmit}
      initialValues={selectedValue}
      mutators={{...arrayMutators}}
      render={({handleSubmit}) =>
        <form onSubmit={handleSubmit} className={Classes.DIALOG_BODY}>

          <FieldFormGroup
            name={'description'}
            label={'Descrizione'}
            required={true}
            render={props => <Input {...props}/>}
          />

          <InlineGroup>
            <FieldFormGroup
              {...priceFieldProps}
              required={true}
              validators={[Validators.numeric]}
              render={props => <InputMoney {...props}/>}
            />

            <FieldFormGroup
              name={'vat'}
              label={'IVA'}
              render={props => <InputVat {...props}/>}
            />
          </InlineGroup>

          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {!isNewSelected && <Button
              text={'Rimuovi articolo'}
              intent={Intent.DANGER}
              onClick={removeSelected}
            />}
            <Button
              type={'submit'}
              text={'Conferma'}
              intent={Intent.SUCCESS}
            />
          </div>
        </form>
      }
    />

  return (
    <ItemsFormSection<IDocumentGoodItem>
      title={'modifiche al totale'}
      renderItem={item => <MoneyItemListRow
        item={item}
        isGrossMode={isGrossMode}
      />}
      state={state}
      dialogTitle={'Modifica il totale'}
      renderDialog={renderDialog}
    />
  )
}

export const moneyItemMapper = (item: IDocumentGoodItem, isGrossMode: boolean) => {
  const vat = item.vat ? item.vat.value : 0
  return isGrossMode ?
    ({...item, gross: +item.gross, net: MoneyUtils.grossToNetR(item.gross, vat, 5)}) :
    ({...item, net: +item.net, gross: MoneyUtils.netToGrossR(item.net, vat, 2)})
}
