import React, {useEffect} from 'react'
import {IApiListResponse} from '../api/hooks/useApiListResponse'
import {ErrorState} from '../components/ErrorState'
import {LoadingState} from '../components/LoadingState'
import {IconName, NonIdealState} from '@blueprintjs/core'
import {IApiListStatusManager} from '../api/hooks/useApiListStatusManager'
import {PageSelector} from './PageSelector'
import styled from 'styled-components'
import {IListProps, List} from './List'
import {ISortOption, SortSelector} from './SortSelector'
import {Input} from '../inputsV2/Input'

interface IDataListProps<T> extends Omit<IListProps<T>, 'items'> {
  apiListResponse: IApiListResponse<T>
  paramsManager: IApiListStatusManager
  emptyStateTitle?: string
  emptyStateDescription?: string
  sortOptions?: ISortOption[]
  hideSearchAndSort?: boolean
  emptyStateIcon?: IconName
  renderHeader?: any
}

export function DataList<T>(props: IDataListProps<T>) {
  const {
    apiListResponse,
    paramsManager,
    selectionManager,
    sortOptions,
    hideSearchAndSort,
    emptyStateIcon = 'cube-add',
    emptyStateTitle = 'Nessun elemento',
    emptyStateDescription = 'Aggiungi elementi in questa lista',
    renderHeader,
    ...listProps
  } = props

  const {
    data,
    error,
    lastPage,
    loading,
  } = apiListResponse

  const {
    search,
    setSearch,
    setPage,
    page,
  } = paramsManager

  const showSortSelector = !!(sortOptions && sortOptions.length)

  useEffect(() => {
    if (page === apiListResponse.page && page > lastPage)
      setPage(lastPage)
  }, [page, lastPage, setPage, apiListResponse.page])

  if (error)
    return <ErrorState/>

  if (!data)
    return <LoadingState/>

  return (
    <DataListTag>
      {!hideSearchAndSort && <SearchSortTag>
        <InputSearch
          leftIcon={'search'}
          showResetButton={true}
          value={search}
          onChange={setSearch}
        />

        {showSortSelector && <SortSelector
          options={sortOptions}
          value={paramsManager.sort}
          onChange={paramsManager.setSort}
        />}
      </SearchSortTag>}

      {renderHeader}

      {!!data.length ?
        <>
          <List
            items={data}
            loading={loading}
            selectionManager={selectionManager}
            {...listProps}
          />

          {lastPage > 1 && <PageSelector
            page={page}
            setPage={setPage}
            listResponse={apiListResponse}
          />}
        </>
        :
        <NonIdeaStateMargin
          title={emptyStateTitle}
          description={emptyStateDescription}
          icon={emptyStateIcon}
        />
      }
    </DataListTag>
  )
}

const DataListTag = styled.div`
  position: relative;
`

const NonIdeaStateMargin = styled(NonIdealState)`
  min-height: 250px;
`

const InputSearch = styled(Input)`
  flex: 1;
  margin-right: 8px;
`

const SearchSortTag = styled.div`
  display: flex;
  margin-bottom: 8px;
`
