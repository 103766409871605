import {useCallback, useEffect, useState} from 'react'

export interface IResponse<T> {
  data?: T
  message?: string
}

export function useApiResponse<T>(api: () => Promise<T>) {
  const [response, setResponse] = useState<T>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const reload = useCallback(() => {
    const call = api && api()

    if (!call)
      return

    setLoading(true)
    setError(null)

    const stopLoading = () => setLoading(false)

    call
      .then(setResponse)
      .catch(setError)
      .finally(stopLoading)
  }, [api])

  useEffect(() => {reload()}, [reload])

  const reset = useCallback((value?) => setResponse(value), [])

  return {
    response,
    loading,
    error,
    reload,
    reset,
  }
}
