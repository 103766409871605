import React, {useState} from 'react'
import {Button} from '@blueprintjs/core'
import {IInputProps, Input} from '../inputsV2/Input'

export function SearchField(props: IInputProps) {
  const {
    value,
    onChange,
    ...inputProps
  } = props

  const [inputValue, setInputValue] = useState(value || '')

  const onClear = () => {
    setInputValue('')
    if (value !== '')
      onChange('')
  }

  const triggerChange = () => {
    if (value !== inputValue)
      onChange(inputValue)
  }

  const clearButton =
    <Button
      icon='cross'
      minimal={true}
      onClick={onClear}
    />

  const confirmButton =
    <Button
      icon={'key-enter'}
      minimal={true}
      onClick={triggerChange}
      intent={'success'}
    />

  const rightElement = inputValue ?
    (value === inputValue ? clearButton : confirmButton) :
    null

  return (
    <Input
      value={inputValue}
      onChange={setInputValue}
      leftIcon={'search'}
      rightElement={rightElement}
      onBlur={triggerChange}
      onEnter={triggerChange}
      {...inputProps}
    />
  )
}
