import {DataViewer} from 'shared/components/DataViewer'
import {CounterpartPreview} from '../counterpart/components/CounterpartPreview'
import {MomentDate} from 'shared/date/MomentDate'
import React from 'react'
import {useCounterpartLabel} from '../counterpart/components/useCounterpartLabel'

export function DocumentDataView(props) {
  const {
    counterpartRole,
    document,
  } = props

  const counterpartLabel = useCounterpartLabel(counterpartRole)

  return (
    <DataViewer
      data={[
        {
          label: counterpartLabel,
          value: document.counterpart && <CounterpartPreview item={document.counterpart}/>,
        },
        {
          label: 'Data',
          value: <MomentDate date={document.date_time} format={'LL - H:mm'}/>,
        },
        {
          label: 'N° Documento',
          value: document.number,
        },
        {
          label: 'Note',
          value: document.note,
        },
      ]}
    />
  )
}
