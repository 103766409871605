import {useCallback, useState} from 'react'

export function useToggleState(initial = false) {
  const [isOn, setIsOn] = useState<boolean>(initial)

  const on = useCallback(() => setIsOn(true), [])
  const off = useCallback(() => setIsOn(false), [])
  const toggle = useCallback(() => setIsOn(!isOn), [isOn])

  return {
    isOn,
    isOff: !isOn,
    on,
    off,
    toggle,
  }
}
