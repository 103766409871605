import React from 'react'
import _ from 'lodash'

export function PercentageValue(props?: { value }) {
  const {value} = props
  const percentValue = _.round(value * 100)

  return (
    <>
      {percentValue}%
    </>
  )
}
