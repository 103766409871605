import moment from 'moment'
import {FieldValidator} from 'final-form'
import _ from 'lodash'

const required = (value): string | undefined => {
  if (value !== null && value !== undefined)
    return
  return 'Campo obbligatorio'
}

const minLength = (minLength: number) => (value): string | undefined => {
  if (!value)
    return
  if (value.toString().length >= minLength)
    return
  return `Lunghezza minima ${minLength} caratteri`
}

const numeric = (value): string | undefined => {
  if (value !== null && value !== undefined && value !== '' && isNaN(value))
    return 'Questo campo deve essere numerico'
}

const min = (min: number) => (value): string | undefined => {
  const numericValidation = numeric(value)
  if (numericValidation)
    return numericValidation

  if (+value < min)
    return `Il valore minimo è ${min}`
}

const max = (max: number) => (value): string | undefined => {
  const numericValidation = numeric(value)
  if (numericValidation)
    return numericValidation
  if (+value > max)
    return `Il valore massimo è ${max}`
}

const date = (value) => {
  if (!value) return

  if (moment(value, 'YYYY-MM-DD', true).isValid())
    return

  return 'Deve essere una data valida'
}

const email = (value) => {
  if (!/^ *[a-zA-Z0-9.\-_+]+@[a-zA-Z0-9.\-_]+\.[a-zA-Z]+ *$/.test(value))
    return 'Deve essere un\'email valida'
}

const arrayMinLength = (min: number) => (value) => {
  if (!value || value.length < min)
    return `Devono  esserci almeno ${min} elementi in questa lista`
}

export const Validators = {
  required,
  minLength,
  numeric,
  min,
  max,
  date,
  email,
  arrayMinLength,
}

export const composeValidators = (validators: FieldValidator<any>[]) => (value, allValues?, meta?) => {
  if (!validators)
    return
  for (const validator of validators) {
    const errorString = validator(value, allValues, meta)
    if (errorString)
      return errorString
  }
}

export const normalizeValidationResult = value => {
  const normalized = _.pickBy(value, _.identity)
  return _.isEmpty(normalized) ? null : normalized
}
