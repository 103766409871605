import React from 'react'
import {DocumentListPage} from 'document/DocumentListPage'
import {SaleApi} from './SaleApi'
import {MomentDate} from 'shared/date/MomentDate'
import {MoneyValue} from 'shared/components/MoneyValue'
import {ISale} from 'document/movement/sale/ISale'

export function SalesListPage() {
  return <DocumentListPage<ISale>
    title={'Vendite'}
    link={'sale'}
    newLinkText={'Nuova vendita'}
    counterpartRole={'customer'}
    crudApi={SaleApi}
    defaultPeriod={'week'}
    columns={[
      {
        renderHead: 'Data',
        renderCell: ({date}) => <MomentDate date={date}/>,
        minSpace: true,
      },
      {
        renderHead: 'Ora',
        renderCell: ({date_time}) => <MomentDate date={date_time} format={'H:mm'}/>,
        minSpace: true,
      },
      {
        renderHead: 'Cliente',
        renderCell: ({counterpart}) => counterpart?.name,
      },
      {
        renderHead: 'Prezzo',
        renderCell: ({gross}) => <MoneyValue value={gross}/>,
        align: 'right',
        minSpace: true,
      },
    ]}
  />
}
