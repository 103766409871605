import React, {useCallback, useMemo} from 'react'
import {Form, FormProps, FormRenderProps} from 'react-final-form'
import {Button, Tab, TabId, Tabs} from '@blueprintjs/core'
import {FullScreenHeaderSection, FullScreenPage, FullScreenSection} from '../components/FullScreenPage'
import {SubmissionErrors, ValidationErrors} from 'final-form'
import {normalizeValidationResult} from './Validators'
import styled from 'styled-components'
import {Intent} from '@blueprintjs/core/lib/esm/common/intent'

export interface IFormStep<FormValues = any> {
  id: TabId
  title: string
  validate?: (values: FormValues) => ValidationErrors | Promise<ValidationErrors> | undefined
  render: (props: FormRenderProps<FormValues>) => JSX.Element
  renderFooter?: (props: FormRenderProps<FormValues>) => JSX.Element
  renderHeader?: (props: FormRenderProps<FormValues>) => JSX.Element
  previousButtonText?: string
  nextButtonText?: string
}

interface IFullscreenFormStepperProps<FormValues = any> {
  title?: string
  formProps?: Omit<FormProps<FormValues>, 'render' | 'validate' | 'onSubmit'>
  steps: IFormStep<FormValues>[]
  selectedTabId: TabId
  onTabIdChange: (value: TabId) => any
  onClose?,
  contentMaxWidth?,
  submitLoading?: boolean
  onSubmit: (values: FormValues) =>
    | SubmissionErrors
    | Promise<SubmissionErrors | undefined>
    | undefined
    | void
}

export function FullscreenFormStepper<FormValues = any>(props: IFullscreenFormStepperProps<FormValues>) {
  const {
    title,
    onClose,
    formProps,
    steps,
    selectedTabId,
    onTabIdChange,
    onSubmit,
    contentMaxWidth,
    submitLoading,
  } = props

  const currentStep = useMemo(() => steps.find(({id}) => id === selectedTabId), [steps, selectedTabId])
  const currentStepIndex = useMemo(() => steps.indexOf(currentStep), [steps, currentStep])
  const stepsLength = useMemo(() => steps.length, [steps])
  const isLastStep = useMemo(() => currentStepIndex === stepsLength - 1, [currentStepIndex, stepsLength])
  const isFirstStep = useMemo(() => currentStepIndex === 0, [currentStepIndex])
  const onFormSubmit = useCallback((value) => {
    if (isLastStep)
      return onSubmit(value)
    else
      onTabIdChange(steps[currentStepIndex + 1].id)
  }, [isLastStep, onSubmit, onTabIdChange, steps, currentStepIndex])

  const goToPreviousStep = useCallback(() => {
    if (currentStepIndex > 0)
      onTabIdChange(steps[currentStepIndex - 1].id)
  }, [currentStepIndex, onTabIdChange, steps])

  return (
    <Form<FormValues>
      {...formProps}
      onSubmit={onFormSubmit}
      validate={currentStep.validate}
      render={(formRenderProps) => {
        const {handleSubmit, values, valid} = formRenderProps

        let disableNext = false
        const tabs = steps.map(({id, title, validate}, index) => {
          const disabled = disableNext

          if (!disableNext && ((validate && !!normalizeValidationResult(validate(values))) || (id === currentStep.id && !valid)))
            disableNext = true

          return {id, title, disabled, key: id}
        })

        const stepper =
          <Tabs
            onChange={onTabIdChange}
            selectedTabId={selectedTabId}
          >
            {tabs.map(props => <Tab {...props}/>)}
          </Tabs>

        const {
          render,
          previousButtonText,
          nextButtonText,
          renderFooter,
          renderHeader,
        } = currentStep

        return (
          <FullScreenPage
            wrapperProps={{onSubmit: handleSubmit}}
            contentMaxWidth={contentMaxWidth}
            tagName={'form'}
          >
            <FullScreenHeaderSection
              title={title}
              renderSubHeader={stepper}
              onClose={onClose}
            />

            {renderHeader && renderHeader(formRenderProps)}

            <FullScreenSection
              fill={true}
              children={render && render(formRenderProps)}
            />

            {renderFooter && renderFooter(formRenderProps)}

            <FullScreenSection
              children={<StepperFooter
                isLastStep={isLastStep}
                isFirstStep={isFirstStep}
                previousButtonText={previousButtonText}
                nextButtonText={nextButtonText}
                onBackClick={goToPreviousStep}
                loading={submitLoading}
              />}
            />
          </FullScreenPage>
        )
      }}
    />
  )
}

function StepperFooter(props) {
  const {
    isFirstStep,
    isLastStep,
    previousButtonText = 'Idietro',
    nextButtonText = 'Avanti',
    onBackClick,
    loading,
  } = props

  return (
    <FooterTag>
      {!isLastStep && <div>
        {!isFirstStep && <Button
          large={true}
          fill={true}
          text={previousButtonText}
          minimal={true}
          icon={'arrow-left'}
          onClick={onBackClick}
        />}
      </div>}
      <div>
        <Button
          large={true}
          fill={true}
          text={nextButtonText}
          type={'submit'}
          intent={isLastStep ? Intent.SUCCESS : Intent.PRIMARY}
          rightIcon={isLastStep ? null : 'arrow-right'}
          loading={loading}
        />
      </div>
    </FooterTag>
  )
}

const FooterTag = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 1;
  }
`
