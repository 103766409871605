import React from 'react'
import {ProductApi} from './ProductApi'
import {Button} from '@blueprintjs/core'
import {LoadingState} from 'shared/components/LoadingState'
import {ErrorState} from 'shared/components/ErrorState'
import PageHeader from 'shared/components/PageHeader'
import {ProductForm} from './components/ProductForm'
import {useEntityPage} from 'shared/hooks/useEntityPage'
import IProduct from './IProduct'
import {ProductHistoryList} from './components/ProductHistoryList'
import {ProductViewer} from './components/ProductViewer'

export function ProductPage() {
  const {
    isNew,
    entity,
    error,
    loading,
    editing,
    onDismiss,
    onSaved,
    setEditing,
    goBack,
  } = useEntityPage<IProduct>('product', ProductApi)

  if (loading)
    return <LoadingState/>
  if (error)
    return <ErrorState/>

  return (
    <div>
      <PageHeader
        title={isNew ? 'Nuovo prodotto' : entity.description}
        rightElement={!editing && <Button
          text={'Modifica'}
          onClick={() => setEditing(true)}
        />}
      />

      {editing && <ProductForm
        initialValues={entity}
        onDismiss={onDismiss}
        onSaved={onSaved}
        onDeleted={goBack}
      />}

      {!editing && <ProductViewer product={entity}/>}

      {!isNew && <ProductHistoryList product={entity}/>}
    </div>
  )
}
