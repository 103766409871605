import React, {useMemo} from 'react'
import {Field, FieldRenderProps} from 'react-final-form'
import {FormGroup} from '@blueprintjs/core'
import {Intent} from '@blueprintjs/core/lib/esm/common/intent'
import {composeValidators, Validators} from './Validators'
import {IFormGroupProps} from '@blueprintjs/core/src/components/forms/formGroup'
import {FieldValidator} from 'final-form'
import styled from 'styled-components'

export interface IFieldFormGroupProps<T> extends IFormGroupProps {
  name?: string
  render?: ({fill, value, onChange, intent, disabled, onBlur, name}, fieldRenderProps?: FieldRenderProps<any>) => any
  disabled?: boolean
  validators?: FieldValidator<T> | FieldValidator<T>[]
  helperText?: string
  intent?: Intent
  type?: string
  hydeErrorMessage?: boolean
  required?: boolean
}

export function FieldFormGroup<T>(props: IFieldFormGroupProps<T>) {
  const {
    name,
    validators,
    disabled,
    helperText,
    intent,
    render,
    type,
    hydeErrorMessage,
    required,
    label,
    ...formGroupProps
  } = props

  const validate = useMemo(() => {
    const arrayValidators = Array.isArray(validators) ? validators : (validators ? [validators] : [])
    if (required)
      arrayValidators.push(Validators.required)

    return composeValidators(arrayValidators)
  }, [validators, required])

  return (
    <Field
      name={name}
      validate={validate}
      type={type}
      render={fieldRenderProps => {
        const {input, meta} = fieldRenderProps
        const error = meta.error || meta.submitError
        const showError = ((meta.touched && meta.dirty) || meta.submitFailed) && error
        const _intent = showError ? Intent.DANGER : intent
        const _helperText = (!hydeErrorMessage && showError && error) || helperText
        const _label = label ? <FormLabel label={label} required={required}/> : null

        return (
          <FormGroup
            disabled={disabled}
            intent={_intent}
            helperText={_helperText}
            label={_label}
            {...formGroupProps}
          >
            {render({
              name,
              fill: true,
              value: input.value,
              onChange: input.onChange,
              onBlur: input.onBlur,
              intent: _intent,
              disabled,
            }, fieldRenderProps)}
          </FormGroup>
        )
      }}
    />
  )
}

function FormLabel({label, required}) {
  if (!required)
    return label

  return (
    <>{label} <RequiredAsterisk>*</RequiredAsterisk></>
  )
}

const RequiredAsterisk = styled.span`
  color: red;
`
