import {useCallback} from 'react'
import {useHistory, useLocation} from 'react-router'

interface IRouterLinkOptions {
  state?: any
  relative?: boolean
}

export default function useNavigateTo() {
  const history = useHistory()
  const location = useLocation()

  return useCallback((path: any, options?: IRouterLinkOptions) => {
    const {state, relative} = options || {}
    const newPath = relative ? location.pathname + '/' + path : path
    history.push(newPath, state)
  }, [history, location])
}
