import {useCallback, useEffect, useMemo, useState} from 'react'
import {useDebounce} from 'use-hooks/dist/commonjs'
import IGetListParams, {ISortParam, SortDirection} from './IGetListParams'

interface IStatusManagerParams {
  page?: number
  perPage?: number
  search?: string
  sortBy?: string
  sortDir?: SortDirection
}

export interface IApiListStatusManager extends IStatusManagerParams {
  search: string
  setPage: (page: number) => any
  setPerPage: (perPage: number) => any
  setSearch: (search: string) => any
  apiParams: IGetListParams
  setSort
  sort
}

export function useApiListStatusManager(initialState?: IStatusManagerParams): IApiListStatusManager {
  const [params, setParams] = useState<IStatusManagerParams>(initialState || {})

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)

  useEffect(() => {
    if ((debouncedSearch || '') !== (params.search || ''))
      setParams({...params, page: 1, search: debouncedSearch})
  }, [params, debouncedSearch])

  const setSort = useCallback((value: ISortParam) => {
    setParams({...params, ...value, page: 1})
  }, [params, setParams])

  const setPage = useCallback((page) => {
    setParams({...params, page})
  }, [params, setParams])

  const setPerPage = useCallback((perPage) => {
    setParams({...params, perPage, page: 1})
  }, [params, setParams])

  const apiParams: IGetListParams = useMemo(() => ({
    search: params.search,
    page: params.page,
    perPage: params.perPage,
    sortBy: params.sortBy,
    sortDir: params.sortDir,
  }), [params])

  return {
    apiParams,
    ...params,
    search,
    setPage,
    setPerPage,
    setSearch,
    setSort,
    sort: {sortBy: params.sortBy, sortDir: params.sortDir},
  }
}
