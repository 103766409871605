import React, {useEffect} from 'react'
import {ITableProps, Table} from 'shared/table/Table'
import {IApiListResponse} from 'shared/api/hooks/useApiListResponse'
import {ISortOption, SortSelector} from 'shared/list/SortSelector'
import {ErrorState} from 'shared/components/ErrorState'
import {LoadingState} from 'shared/components/LoadingState'
import Row from 'settings/Row'
import {LoadingPanel} from 'shared/components/LoadingPanel'
import {PageSelector} from 'shared/list/PageSelector'
import {ISortParam} from 'shared/api/hooks/IGetListParams'
import styled from 'styled-components'
import {INonIdealStateProps, NonIdealState} from '@blueprintjs/core'
import useNavigateTo from 'shared/routing/useNavigateTo'

interface IDataTableProps<T> extends Omit<ITableProps<T>, 'data'> {
  apiListResponse: IApiListResponse<T>
  sortOptions?: ISortOption[]
  sort?: ISortParam
  setSort?: (value: ISortParam) => void
  page: number
  setPage: (page: number) => void
  rowLink?: (item: T, index: number) => string
  emptyStateProps?: INonIdealStateProps
  renderHeader?: JSX.Element
}

function DataTable<T>(props: IDataTableProps<T>) {
  const {
    apiListResponse,
    sortOptions,
    page,
    setPage,
    rowLink,
    sort,
    setSort,
    emptyStateProps = {},
    renderHeader,
    ...tableProps
  } = props

  const {
    data,
    error,
    loading,
    lastPage,
  } = apiListResponse

  const interactive = !!rowLink
  const navigateTo = useNavigateTo()
  const onRowClick = (item: T, index) => rowLink && navigateTo(rowLink(item, index))

  useEffect(() => {
    if (page === apiListResponse.page && page > lastPage)
      setPage(lastPage)
  }, [page, lastPage, setPage, apiListResponse.page])

  if (error)
    return <ErrorState/>

  if (!data)
    return <LoadingState/>

  if (!data.length)
    return (
      <NonIdeaStateMargin
        title={'Nessun elemento'}
        icon={'cube-add'}
        {...emptyStateProps}
      />
    )

  return (
    <div>
      <TableHeader>
        <Row childrenMargin>
          <div>{renderHeader}</div>
        </Row>
        <Row childrenMargin contentPosition={'bottom-right'}>
          <PageSelector
            page={page}
            setPage={setPage}
            listResponse={apiListResponse}
          />

          {!!sortOptions && <SortSelector
            options={sortOptions}
            value={sort}
            onChange={setSort}
          />}
        </Row>
      </TableHeader>

      <div style={{position: 'relative'}}>
        {loading && <LoadingPanel/>}
        <Table<T>
          data={data}
          rowProps={(item, index) => ({onClick: () => onRowClick(item, index)})}
          interactive={interactive}
          {...tableProps}
        />
      </div>
    </div>
  )
}

const NonIdeaStateMargin = styled(NonIdealState)`
  min-height: 250px;
`

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export default DataTable
