import React from 'react'
import styled from 'styled-components'
import {Colors, Spinner} from '@blueprintjs/core'
import {ISpinnerProps} from '@blueprintjs/core/src/components/spinner/spinner'

export function LoadingPanel(props: ISpinnerProps) {
  return (
    <LoadingPanelTag>
      <Spinner {...props}/>
    </LoadingPanelTag>
  )
}

const LoadingPanelTag = styled.div`
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  background-color: ${Colors.LIGHT_GRAY1};
`
