import React from 'react'
import {Checkbox, HTMLDivProps} from '@blueprintjs/core'
import {IListSelectionManager} from '../hooks/useListSelectionManager'
import styled from 'styled-components'
import {CheckboxContainerTag, ListItemTag} from './List'

interface IListHeaderProps extends HTMLDivProps {
  selectionManager?: IListSelectionManager<any>
  rightElement?: JSX.Element | string
  leftElement?: JSX.Element | string
}

export function ListHeader(props: IListHeaderProps) {
  const {
    selectionManager,
    rightElement,
    leftElement,
    ...divProps
  } = props

  const showSelectCheckbox = !!selectionManager

  const {
    isAllSelected,
    isGlobalIndeterminate,
    onGlobalCheckboxChange,
  } = selectionManager || {}

  return (
    <ListItemTag {...divProps}>
      {showSelectCheckbox && <CheckboxContainerTag>
        <Checkbox
          className='only-checkbox'
          checked={isAllSelected}
          indeterminate={isGlobalIndeterminate}
          onChange={onGlobalCheckboxChange}
        />
      </CheckboxContainerTag>}

      <ListHeaderContainer>
        <LeftContainer>{leftElement}</LeftContainer>
        <div>{rightElement}</div>
      </ListHeaderContainer>
    </ListItemTag>
  )
}

const ListHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const LeftContainer = styled.div`
  flex: 1;
`
