import React, {useContext} from 'react'
import {CompanyContext} from '../../company/CompanyState'
import {InputSelectAsync} from 'shared/inputsV2/select/InputSelectAsync'
import {createCrudApi} from '../api/CrudApi'

export interface IVat {
  id: number
  value: number
  label: string
}

export function useDefaultVat() {
  const {company: {default_vat}} = useContext(CompanyContext)
  return default_vat
}

export const VatApi = createCrudApi<IVat>('vats')

const itemsGetter = VatApi.search()

export function InputVat(props) {
  const {required} = props

  const placeholder = required ?
    'Seleziona l\'IVA' :
    'Nessuna'

  return (
    <InputSelectAsync<IVat>
      required={required}
      itemsGetter={itemsGetter}
      renderItem={({label}) => label}
      placeholder={placeholder}
      itemsEqual={'id'}
      {...props}
    />
  )
}
