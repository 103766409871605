import styled from 'styled-components'
import {Portal} from '@blueprintjs/core'

export const FullScreenPortal = styled(Portal)`
  bottom: 0;
  z-index: 10;
  background-color: white;
  display: flex;
  flex-direction: column;
`
