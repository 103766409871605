import React, {useEffect} from 'react'
import {useApiListResponse} from 'shared/api/hooks/useApiListResponse'
import {AttributeApi, IAttribute} from './attribute'
import {LoadingState} from 'shared/components/LoadingState'
import {Field, useForm} from 'react-final-form'
import {MutableState, Tools} from 'final-form'
import IProduct from '../product/IProduct'
import {Validators} from 'shared/form/Validators'
import {Table} from 'shared/table/Table'
import {AttributeManager} from './AttributeManager'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {InputAttributeValue} from './InputAttributeValue'

export const attributesValuesMutator = ([data], state: MutableState<IProduct>, {
  changeValue,
  resetFieldState,
}: Tools<IProduct>) => {
  if (!data)
    return

  const actualValue = state.formState.values['attributes_values'] || []
  const actualValueById = actualValue.reduce((carry, value) => ({
    ...carry,
    [value.attribute.id]: value.value,
  }), {})
  const newValue = data.map(attribute => ({attribute, value: actualValueById[attribute.id]}))

  changeValue(state, 'attributes_values', () => newValue)
}

const valueRequired = (index) => (value, allValues) => allValues.attributes_values[index]?.attribute.required ? Validators.required(value) : null

export function AttributesValuesFormField() {
  const {
    data,
    reload,
  } = useApiListResponse<IAttribute>(AttributeApi.getList)

  const form = useForm()
  const mutator = form.mutators.attributesValuesMutator

  useEffect(() => {
    mutator(data)
  }, [mutator, data])

  if (!data)
    return <LoadingState/>

  return (
    <Field
      name={'attributes_values'}
      render={({input}) => <Table
        compact={true}
        data={input.value || []}
        columns={[
          {
            renderHead: 'Campi prodotto',
            renderCell: ({attribute}) => <AttributeManager
              attribute={attribute}
              onChange={reload}
            />,
          },
          {
            renderHead: 'Valore',
            renderCell: ({attribute, value}, index) => <FieldFormGroup
              name={'attributes_values[' + index + '].value'}
              validators={valueRequired(index)}
              render={(props) => <InputAttributeValue
                attribute={attribute}
                {...props}
              />}
            />,
          },
        ]}
        footer={<td colSpan={2}>
          <AttributeManager
            onChange={reload}
            intent={'primary'}
          />
        </td>}
      />}
    />
  )
}
