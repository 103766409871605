import {myAxios} from 'shared/api/myAxios'
import {extractApiData} from 'shared/api/CrudApi'
import {IUser} from '../user/IUser'

export const AuthApi = {
  login: (params: { email: string, password: string }): Promise<{ token: string }> =>
    myAxios.post('login', params).then(extractApiData),

  register: (params: { email: string, password: string }): Promise<{ token: string }> =>
    myAxios.post('register', params).then(extractApiData),

  changePassword: (params: { oldPassword: string, newPassword: string }): Promise<IUser> =>
    myAxios.post('change_password', params).then(extractApiData),

  resetPasswordRequest: (params: { email: string }): Promise<boolean> =>
    myAxios.post('reset_password_request', params).then(extractApiData),

  resetPassword: (params: { token: string, newPassword: string }): Promise<boolean> =>
    myAxios.post('reset_password', params).then(extractApiData),
}
