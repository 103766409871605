import React, {useEffect, useState} from 'react'
import {Popover} from '@blueprintjs/core'
import {DatePicker} from '../date/DatePicker'
import moment from 'moment'
import {TimePrecision} from '@blueprintjs/datetime'
import {Input} from '../inputsV2/Input'
import {DATE_FORMAT} from 'shared/date/date'

interface IInputDateProps {
  value: string
  onChange: (value: string) => void
  displayFormat?: string
  timePrecision?: TimePrecision
  closeOnSelection?: boolean
  fill?: boolean
}

export function InputDate(props: IInputDateProps) {
  const {
    value,
    onChange,
    displayFormat = 'LL',
    closeOnSelection = true,
    timePrecision,
    fill,
  } = props

  const [text, setText] = useState('')

  const [isFocused, setIsFocused] = useState(false)
  const [isPickerOpen, setIsPickerOpen] = useState(false)

  const onInputChange = stringDate => {
    setText(stringDate)

    if (!stringDate) {
      onChange(null)
      return
    }

    const momentDate = moment(stringDate, displayFormat)
    if (momentDate.isValid()) {
      onChange(momentDate.format(DATE_FORMAT))
    }
  }

  const onPickerChange = value => {
    if (closeOnSelection)
      setIsPickerOpen(false)
    onChange(value)
  }

  useEffect(() => {
    if (isFocused)
      return

    if (value)
      setText(moment(value).format(displayFormat))
    else
      setText('')
  }, [value, isFocused, displayFormat])

  return (
    <Popover
      isOpen={isPickerOpen || isFocused}
      onOpened={() => setIsPickerOpen(true)}
      onClose={() => setIsPickerOpen(false)}
      autoFocus={false}
      enforceFocus={false}
      canEscapeKeyClose={false}
      interactionKind={'click'}
      fill={fill}
      content={<DatePicker
        value={value}
        onChange={onPickerChange}
        timePrecision={timePrecision}
      />}
    >
      <Input
        value={text}
        onChange={onInputChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        fill={fill}
      />
    </Popover>
  )
}
