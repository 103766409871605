import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'

const decimalSeparator = ','
const thousandsSeparator = '.'

interface IMoneyValueProps extends React.HTMLAttributes<HTMLSpanElement> {
  value: number
}

export function MoneyValue(props?: IMoneyValueProps) {
  const {
    value,
    ...spanProps
  } = props

  if (_.isNil(value))
    return <></>

  const sign = value < 0 ? '-' : ''
  const rounded = Math.round(Math.abs(value) * 100)
  const integer = Math.floor(rounded / 100)
  const decimal = Math.round(rounded - (integer * 100))
  const integerString = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
  const decimalString = decimalSeparator + decimal.toString().padStart(2, '0')

  return (
    <MoneyValueTag
      {...spanProps}
    >
      {sign}
      {integerString}
      <DecimalTag>{decimalString}</DecimalTag>
      €
    </MoneyValueTag>
  )
}

const MoneyValueTag = styled.span`
  white-space: nowrap;
`

const DecimalTag = styled.span`
  font-size: .8em;
`
