import React, {useCallback, useMemo, useState} from 'react'
import _ from 'lodash'
import {ISale} from './ISale'
import {ValidationErrors} from 'final-form'
import {TabId} from '@blueprintjs/core'
import {useApiFormSubmitter} from 'shared/api/hooks/useApiFormSubmitter'
import {SaleApi} from './SaleApi'
import {FullscreenFormStepper, IFormStep} from 'shared/form/FullscreenFormStepper'
import {DocumentTotalsStepperSection} from '../../DocumentTotalsStepperSection'
import {PaymentsForm} from './PaymentsForm'
import arrayMutators from 'final-form-arrays'
import {useDefaultVat} from 'shared/inputs/InputVat'
import IProduct from '../../../product/IProduct'
import {DocumentFormActionsButton} from '../../DocumentFormActionsButton'
import {createDocumentMoneysDecorator} from '../../createDocumentMoneysDecorator'
import moment from 'moment'
import {useItemsFormSectionState} from '../../ItemsFormSection'
import {IOrderGoodItem} from '../../order/IOrderGoodItem'
import {goodItemMapper, GoodsItemsFormSection} from '../../GoodsItemsFormSection'
import {GoodItemForm} from '../GoodItemForm'
import {MoneysItemsFormSection} from '../MoneysItemsFormSection'
import {SaleDataHeader} from './SaleDataHeader'
import {ReturnedItemsFormSection} from './ReturnedItemsFormSection'
import {discountOption, vatOption} from '../../massUpdateOptions'
import {ISaleGoodItem} from './ISaleGoodItem'
import {GoodItemListRow} from '../../GoodItemListRow'
import {useFormState} from 'react-final-form'

const validatePaymentsSum = ({payments_items, gross}: ISale): ValidationErrors => {
  const list = payments_items || []
  const tot = list.reduce((tot, payment) => tot + +payment.gross, 0)
  if (tot !== gross) {
    return {
      paymentsTot: 'sum error',
    }
  }
}

const decorators = createDocumentMoneysDecorator([
  {field: 'goods_items', direction: 1},
  {field: 'moneys_items', direction: 1},
  {field: 'returned_items', direction: -1},
])

export function SaleForm({initialValues, isNew, onDismiss, onSaved}) {
  const [isGrossMode, setIsGrossMode] = useState<boolean>(true)
  const [step, setStep] = useState<TabId>('items')
  const {onSubmit, loading} = useApiFormSubmitter(SaleApi.save, onSaved)

  const orderSteps = useMemo<IFormStep<ISale>[]>(() => [
    {
      id: 'items',
      title: 'Articoli',
      render: () => <ItemsStep
        isGrossMode={isGrossMode}
        setIsGrossMode={setIsGrossMode}
      />,
      renderHeader: () => <SaleDataHeader/>,
      renderFooter: ({values}) => <DocumentTotalsStepperSection
        movement={values}
        isGrossMode={isGrossMode}
      />,
    },
    {
      id: 'payments_items',
      title: 'Pagamento',
      render: () => <PaymentsForm/>,
      validate: values => validatePaymentsSum(values),
      renderFooter: ({values}) => <DocumentTotalsStepperSection
        movement={values}
        isGrossMode={isGrossMode}
      />,
      nextButtonText: 'Salva',
    },
  ], [isGrossMode])

  const defaultValues = useMemo(() => ({
    date_time: moment().format('YYYY-MM-DD HH:mm:ss'),
  }), [])

  return <FullscreenFormStepper<ISale>
    formProps={{
      mutators: {...arrayMutators},
      decorators: [decorators],
      initialValues: initialValues || defaultValues,
    }}
    title={isNew ? 'Nuova vendita' : 'Modifica vendita'}
    selectedTabId={step}
    onTabIdChange={setStep}
    steps={orderSteps}
    onSubmit={onSubmit}
    onClose={onDismiss}
    submitLoading={loading}
  />
}

function ItemsStep({isGrossMode, setIsGrossMode}) {
  const defaultVat = useDefaultVat()
  const goodsItemsState = useItemsFormSectionState<IOrderGoodItem>('goods_items')
  const moneysItemsState = useItemsFormSectionState<IOrderGoodItem>('moneys_items')
  const returnedItemsState = useItemsFormSectionState<IOrderGoodItem>('returned_items')
  const {values: {id, date_time}} = useFormState()

  const productToGoodItem = useCallback<(product: IProduct) => ISaleGoodItem>(product => ({
    product,
    unit_net: product && product.price_list_net,
    unit_gross: product && product.price_list_gross,
    quantity: 1,
    vat: product ? product.vat : defaultVat,
  }), [defaultVat])

  const onProductSelected = (product) => {
    const item = productToGoodItem(product)
    if (!_.isNil(product.price_list_net))
      goodsItemsState.pushItem(goodItemMapper(item, isGrossMode))
    else
      goodsItemsState.onNewItem(item)
  }

  return (
    <>
      <DocumentFormActionsButton
        onSelected={onProductSelected}
        isGrossMode={isGrossMode}
        setIsGrossMode={setIsGrossMode}
        date={date_time}
        actions={[
          {
            icon: 'add',
            label: 'Crea un nuovo prodotto',
            action: goodsItemsState.onNewItem,
          }, {
            icon: 'euro',
            label: 'Modifica il totale',
            action: moneysItemsState.onNewItem,
          }, {
            icon: 'undo',
            label: 'Effettua un reso',
            action: returnedItemsState.onNewItem,
          },
        ]}
      />

      <GoodsItemsFormSection
        isGrossMode={isGrossMode}
        state={goodsItemsState}
        productToGoodItem={productToGoodItem}
        renderItemForm={<GoodItemForm
          isGrossMode={isGrossMode}
          movLabel={'vendita'}
        />}
        massUpdateOptions={[vatOption, discountOption]}
        renderItem={item => <GoodItemListRow
          item={item}
          isGrossMode={isGrossMode}
        />}
      />

      <MoneysItemsFormSection
        isGrossMode={isGrossMode}
        state={moneysItemsState}
      />

      <ReturnedItemsFormSection
        isGrossMode={isGrossMode}
        state={returnedItemsState}
        actualSaleId={id}
      />
    </>
  )
}
