import {ListRow} from 'shared/list/ListRow'
import {MoneyValue} from 'shared/components/MoneyValue'
import React from 'react'
import {IMoneyItem} from './IMoneyItem'

interface IMoneyItemListRowProps {
  item: IMoneyItem
  isGrossMode: boolean
}

export function MoneyItemListRow(props: IMoneyItemListRowProps) {
  const {
    item: {
      description,
      gross,
      net,
    },
    isGrossMode,
  } = props

  return (
    <ListRow
      topLeft={description}
      topRight={<MoneyValue value={isGrossMode ? gross : net}/>}
    />
  )
}
