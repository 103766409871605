import {IAttribute} from 'productAttributes/attribute'
import {IFiltersConfigs} from 'shared/filters/InputFilters'
import FilterSelectAttribute from 'shared/filters/filtersTag/FilterSelectAttribute'
import FilterString from 'shared/filters/filtersTag/FilterString'
import React from 'react'

function getProductsFiltersConfig(attributes: IAttribute[]): IFiltersConfigs {
  if (!attributes)
    return null
  return attributes.reduce<IFiltersConfigs>((carry, attribute) => ({
    ...carry,
    ['attribute.' + attribute.id]: {
      name: attribute.name,
      renderTag: props => <FilterSelectAttribute
        {...props}
        attribute={attribute}
      />,
    },
  }), {
    'code': {
      name: 'Codice',
      renderTag: props => <FilterString {...props}/>,
    },
    'description': {
      name: 'Descrizione',
      renderTag: props => <FilterString {...props}/>,
    },
  })
}

export default getProductsFiltersConfig
