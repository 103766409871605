import React, {useCallback} from 'react'
import {InputSuggestStringAsync} from 'shared/inputs/InputSuggestStringAsync'
import {AttributeApi} from './attribute'

export function InputAttributeValue(props) {
  const {
    attribute,
    ...others
  } = props

  const itemsGetter = useCallback(search => AttributeApi.getValues(attribute.id, search), [attribute.id])

  return (
    <InputSuggestStringAsync
      {...others}
      itemsGetter={itemsGetter}
      placeholder={'Inserisci ' + attribute.name}
    />
  )
}
