import ICounterpart from './ICounterpart'
import {createCrudApi} from 'shared/api/CrudApi'

const crudApi = createCrudApi<ICounterpart>('counterpart')

const CounterpartApi = {
  ...crudApi,
}

export default CounterpartApi
