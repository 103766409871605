import React from 'react'
import {Redirect, Route, Switch} from 'react-router'
import {LoginPage} from 'auth/LoginPage'
import {RegisterPage} from 'auth/RegisterPage'
import {Alignment, Classes, Navbar} from '@blueprintjs/core'
import {PageContainer} from 'shared/components/PageContainer'
import {ResetPasswordRequest} from 'auth/ResetPasswordRequest'
import {ResetPassword} from 'auth/ResetPassword'
import {UnstyledLink} from 'shared/components/UnstyledLink'

export function UnAuthArea() {

  return (
    <>
      <Navbar>
        <Navbar.Group align={Alignment.CENTER}>
          <UnstyledLink
            to={'/'}
            className={Classes.NAVBAR_HEADING}
          >Negoziapp</UnstyledLink>
        </Navbar.Group>
      </Navbar>

      <PageContainer maxWidth={'400px'}>
        <Switch>
          <Route path="/login" component={LoginPage}/>
          <Route path="/register" component={RegisterPage}/>
          <Route path="/reset_password_request" component={ResetPasswordRequest}/>
          <Route path="/reset_password" component={ResetPassword}/>

          <Redirect to='/login'/>
        </Switch>
      </PageContainer>
    </>
  )
}
