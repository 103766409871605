import React, {useContext, useEffect, useState} from 'react'
import {Route, Switch} from 'react-router'
import {CustomerServiceControl} from '../customerService/CustomerServiceControl'
import {AppNavBar} from '../app-base/AppNavBar'
import {TokenContext} from '../auth/TokenState'
import {LoadingState} from 'shared/components/LoadingState'
import {CompanyState} from '../company/CompanyState'
import styled from 'styled-components'
import {UserSettingsPage} from './UserSettingsPage'
import {useLocalStorageNumber} from 'shared/hooks/useLocalStorageNumber'
import {IUser} from './IUser'
import {myAxios} from 'shared/api/myAxios'
import {UserApi} from './UserApi'
import {useApiFormSubmitter} from 'shared/api/hooks/useApiFormSubmitter'

const AppBody = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`

interface IUserContext {
  user: IUser
  setUserId: (id: number) => void
  updateUser: (value: IUser) => void
  updatingUser: boolean
}

export const UserContext = React.createContext<IUserContext>({
  user: null,
  setUserId: null,
  updateUser: null,
  updatingUser: false,
})

export function UserState() {
  const {userId, isCustomerService} = useContext(TokenContext)

  const [activeUserId, setActiveUserId] = useLocalStorageNumber('userId')
  const [user, setUser] = useState<IUser>(null)

  useEffect(() => {
    setUser(null)
    myAxios.defaults.headers['Act-As'] = activeUserId
    UserApi.getUser().then(setUser)
  }, [activeUserId])

  const userUpdated = useApiFormSubmitter(UserApi.updateUser)

  if (!user)
    return <LoadingState/>

  return (
    <UserContext.Provider value={{
      user,
      setUserId: setActiveUserId,
      updateUser: userUpdated.onSubmit,
      updatingUser: userUpdated.loading,
    }}>
      <AppNavBar/>

      <AppBody>
        <Switch>
          <Route path="/usersettings" component={UserSettingsPage}/>

          <CompanyState/>
        </Switch>
      </AppBody>

      {isCustomerService && <CustomerServiceControl
        realUserId={userId}
        fakeUser={user}
        setFakeUser={user => setActiveUserId(user ? user.id : userId)}
      />}
    </UserContext.Provider>
  )
}
