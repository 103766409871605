import styled from 'styled-components'

export const MarginedContent = styled.div`
  margin: 0 -4px;

  > * {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }
`

export const InlineGroup = styled(MarginedContent)`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1;
  }
`
