import React, {useMemo, useState} from 'react'
import {Validators} from 'shared/form/Validators'
import {TabId} from '@blueprintjs/core'
import {FullscreenFormStepper, IFormStep} from 'shared/form/FullscreenFormStepper'
import {useApiFormSubmitter} from 'shared/api/hooks/useApiFormSubmitter'
import {OrderApi} from './OrderApi'
import moment from 'moment'
import {IDocument} from '../IDocument'
import {DocumentFormData} from '../DocumentFormData'
import {DocumentTotalsStepperSection} from '../DocumentTotalsStepperSection'
import {DocumentFormActionsButton} from '../DocumentFormActionsButton'
import {useItemsFormSectionState} from '../ItemsFormSection'
import {IOrderGoodItem} from './IOrderGoodItem'
import {GoodsItemsFormSection} from '../GoodsItemsFormSection'
import {OrderItemForm} from './OrderItemForm'
import arrayMutators from 'final-form-arrays'
import {createDocumentMoneysDecorator} from '../createDocumentMoneysDecorator'
import IProduct from '../../product/IProduct'
import {vatOption} from '../massUpdateOptions'
import {OrderGoodItemListRow} from './OrderGoodItemListRow'
import {useFormState} from 'react-final-form'

const decorators = createDocumentMoneysDecorator([
  {field: 'goods_items', direction: 1},
])

export function OrderForm({initialValues, isNew, onDismiss, onSaved}) {
  const [isGrossMode, setIsGrossMode] = useState<boolean>(false)
  const [step, setStep] = useState<TabId>(isNew ? 'data' : 'items')
  const {onSubmit, loading} = useApiFormSubmitter(OrderApi.save, onSaved)

  const orderSteps: IFormStep<IDocument>[] = useMemo<IFormStep[]>(() => [
    {
      id: 'data',
      title: 'Dati',
      validate: ({counterpart}) => ({
        counterpart: Validators.required(counterpart),
      }),
      render: () => <DocumentFormData
        counterpartRole={'supplier'}
        counterpartRequired={true}
      />,
    },
    {
      id: 'items',
      title: 'Articoli',
      render: () => <ItemsStep
        isGrossMode={isGrossMode}
        setIsGrossMode={setIsGrossMode}
      />,
      validate: ({goods_items}) => ({
        goods_items: Validators.arrayMinLength(1)(goods_items),
      }),
      renderFooter: ({values}) => <DocumentTotalsStepperSection
        movement={values}
        isGrossMode={isGrossMode}
      />,
      nextButtonText: 'Salva',
    },
  ], [isGrossMode])

  const defaultValues = useMemo(() => ({
    date_time: moment().format('YYYY-MM-DD HH:mm:ss'),
  }), [])

  return <FullscreenFormStepper
    formProps={{
      mutators: {...arrayMutators},
      decorators: [decorators],
      initialValues: initialValues || defaultValues,
    }}
    title={isNew ? 'Nuovo ordine' : 'Modifica ordine'}
    selectedTabId={step}
    onTabIdChange={setStep}
    steps={orderSteps}
    onSubmit={onSubmit}
    onClose={onDismiss}
    submitLoading={loading}
  />
}

const productToGoodItem = (product: IProduct): IOrderGoodItem => ({
  quantity: 1,
  vat: product.vat,
  product,
})

function ItemsStep({isGrossMode, setIsGrossMode}) {
  const goodsItemsState = useItemsFormSectionState<IOrderGoodItem>('goods_items')
  const {values} = useFormState()

  return (
    <>
      <DocumentFormActionsButton
        onSelected={product => goodsItemsState.onNewItem(productToGoodItem(product))}
        isGrossMode={isGrossMode}
        setIsGrossMode={setIsGrossMode}
        date={values.date_time}
        actions={[
          {
            icon: 'add',
            label: 'Crea un nuovo prodotto',
            action: goodsItemsState.onNewItem,
          },
        ]}
      />

      <GoodsItemsFormSection
        isGrossMode={isGrossMode}
        state={goodsItemsState}
        renderItemForm={<OrderItemForm isGrossMode={isGrossMode}/>}
        productToGoodItem={productToGoodItem}
        massUpdateOptions={[vatOption]}
        renderItem={item => <OrderGoodItemListRow
          item={item}
          isGrossMode={isGrossMode}
        />}
      />
    </>
  )
}
