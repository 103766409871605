import {useCallback, useState} from 'react'
import {useDebounce} from 'use-hooks/dist/commonjs'
import {IApiListResponse, IResponsePaginated, useApiListResponse} from '../api/hooks/useApiListResponse'

interface ISearchApiListResponse<T> extends IApiListResponse<T> {
  search: string
  setSearch: (value: string) => void
}

export function useSearchApiList<T>(itemsGetter: (find: string) => Promise<IResponsePaginated<T>>): ISearchApiListResponse<T> {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)
  const getFunction = useCallback(() => itemsGetter(debouncedSearch), [itemsGetter, debouncedSearch])
  const listResponse = useApiListResponse(getFunction)

  return {
    ...listResponse,
    search,
    setSearch,
  }
}
