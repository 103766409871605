interface Environment {
  production: boolean
  googleIdClient: string
  apiEndpoint: string
}

const getEnv = (): Environment => {
  switch (process.env.NODE_ENV) {
    case 'development':
    case 'test':
      return {
        production: false,
        googleIdClient: '699712007687-27j6kt10ei57givkovb53q5ejomo7d5f.apps.googleusercontent.com',
        apiEndpoint: 'http://local-api.negoziapp.com',
      }
    case 'production':
      return {
        production: true,
        googleIdClient: '699712007687-6mku5bik7t4rfb2nrotg5gae4c7eepa5.apps.googleusercontent.com',
        apiEndpoint: 'https://api.negoziapp.com',
      }
  }
}

export const environment: Environment = getEnv()
