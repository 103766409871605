import {CounterpartRole} from '../ICounterpart'
import {useMemo} from 'react'

export function getCounterpartLabel(role: CounterpartRole): string {
  if (role === 'supplier')
    return 'Fornitore'
  if (role === 'customer')
    return 'Cliente'
  return 'Soggetto'
}

export function useCounterpartLabel(role: CounterpartRole): string {
  return useMemo(() => getCounterpartLabel(role), [role])
}
