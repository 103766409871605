import React from 'react'
import {Classes, Divider, IconName, Menu} from '@blueprintjs/core'
import {CompanyControl} from './CompanyControl'
import styled from 'styled-components'
import {MenuItemLink} from 'shared/components/MenuItemLink'

const voices: { icon: IconName, text: string, href: string }[] = [
  {
    icon: 'book',
    text: 'Catalogo',
    href: '/product',
  },
  {
    icon: 'people',
    text: 'Clienti e Fornitori',
    href: '/counterpart',
  },
  {
    icon: 'timeline-events',
    text: 'Ordini',
    href: '/order',
  },
  {
    icon: 'shopping-cart',
    text: 'Arrivi merce',
    href: '/purchase',
  },
  {
    icon: 'shop',
    text: 'Vendite',
    href: '/sale',
  },
  {
    icon: 'timeline-line-chart',
    text: 'Analisi',
    href: '/analytics',
  },
  {
    icon: 'cog',
    text: 'Impostazioni',
    href: '/settings',
  },
]

export function AppMenu(props) {
  const menuVoices =
    voices.map((({icon, text, href}, key) =>
        <MenuItemLink
          key={key}
          to={href}
          icon={icon}
          text={text}
        />
    ))

  return (
    <AppMenuTag className={Classes.ELEVATION_2}>
      <StyledMenu>
        <CompanyControl/>

        <Divider/>
        {menuVoices}
      </StyledMenu>
    </AppMenuTag>
  )
}

const AppMenuTag = styled.div`
  display: flex;
  max-width: 180px;
`

const StyledMenu = styled(Menu)`
  && {
    border-radius: unset;
    overflow: auto;
  }
`
