import React from 'react'
import EntityPreviewer, {IEntityPreview} from 'shared/components/EntityPreviewer'
import {IUser} from './IUser'

interface IUserPreviewProps extends IEntityPreview<IUser> {

}

export function UserPreview(props: IUserPreviewProps) {
  const {item} = props

  return (
    <EntityPreviewer
      title={item.full_name}
      img={item.picture}
    />
  )
}
