import React from 'react'
import {RadioGroup} from '@blueprintjs/core'
import {IRadioGroupProps} from '@blueprintjs/core/src/components/forms/radioGroup'

interface IInputRadioProps extends Omit<IRadioGroupProps, 'selectedValue'> {
  value?: string | number
}

export function InputRadio(props: IInputRadioProps) {
  const {
    value,
    ...others
  } = props

  return (
    <RadioGroup
      selectedValue={value}
      {...others}
    />
  )
}
