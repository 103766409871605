import React from 'react'
import {InputPassword} from 'shared/inputsV2/InputPassword'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import styled from 'styled-components'

interface IFieldStrongPasswordProps {
  name: string
  label?: string
  autoFocus?: boolean
  large?: boolean
  placeholder?: string
}

const rules = [
  {
    label: 'Inserisci almeno 8 caratteri',
    validate: password => password && password.length >= 8,
  },
  {
    label: 'Usa sia lettere minuscole che maiuscole',
    validate: password => /^(?=.*[a-z])(?=.*[A-Z]).*$/.test(password),
  },
  {
    label: 'Usa anche numeri',
    validate: password => /^(?=.*[0-9]).*$/.test(password),
  },
]

const validate = password => {
  for (const rule of rules) {
    if (!rule.validate(password))
      return true
  }
}

const RulesList = styled.ul``
const Rule = styled.li<{ isOk: boolean }>`
  text-decoration: ${props => props.isOk && 'line-through'};
`

export function FieldStrongPassword(props: IFieldStrongPasswordProps) {
  const {
    name,
    label,
    autoFocus,
    large,
    placeholder = 'Inserisci una password',
  } = props

  return (
    <FieldFormGroup
      name={name}
      label={label}
      validators={validate}
      hydeErrorMessage={true}
      render={(params, {meta}) =>
        <>
          <InputPassword
            {...params}
            autoFocus={autoFocus}
            placeholder={placeholder}
            large={large}
          />
          <RulesList>
            {rules.map((rule, key) =>
              <Rule
                key={key}
                isOk={rule.validate(params.value)}
              >{rule.label}</Rule>,
            )}
          </RulesList>
        </>
      }
    />
  )
}
