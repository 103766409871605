import React from 'react'
import styled from 'styled-components'
import {HTMLDivProps} from '@blueprintjs/core/lib/esm/common/props'

interface IPageHeaderProps extends HTMLDivProps {
  title?: any
  rightElement?: any
}

function PageHeader(props: IPageHeaderProps) {
  const {
    title,
    rightElement,
    ...divProps
  } = props

  return (
    <div {...divProps}>
      <div>
        <h2 style={{margin: 0}}>{title}</h2>
      </div>
      <div>
        {rightElement}
      </div>
    </div>
  )
}

export default styled(PageHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
`
