import React, {useContext} from 'react'
import {Button, Icon, Menu, MenuDivider, MenuItem, Popover} from '@blueprintjs/core'
import Avatar from 'shared/components/Avatar'
import {TokenContext} from '../auth/TokenState'
import {MenuItemLink} from 'shared/components/MenuItemLink'
import {UserContext} from './UserState'

export function UserControl() {
  const {logout} = useContext(TokenContext)
  const {user} = useContext(UserContext)

  const {full_name, email, picture} = user

  const buttonText = picture ?
    <Avatar
      src={picture}
      small={true}
    /> :
    <Icon
      style={{display: 'contents'}}
      icon={'user'}
      iconSize={24}
    />

  const userMenu =
    <Menu>
      <MenuDivider title={full_name || email}/>
      <MenuItemLink
        icon={'cog'}
        text={'Impostazioni utente'}
        to={'usersettings'}
      />
      <MenuDivider/>
      <MenuItem
        text='Logout'
        icon='log-out'
        onClick={logout}
      />
    </Menu>

  return (
    <Popover
      content={userMenu}
    >
      <Button
        minimal={true}
        text={buttonText}
        rightIcon='chevron-down'
        large={true}
      />
    </Popover>
  )
}
