import {Dispatch, SetStateAction, useCallback, useMemo} from 'react'
import _ from 'lodash'
import {useHistory, useLocation} from 'react-router'
import {parse, stringify} from 'qs'

export function useRouterParams<T>(): [T, Dispatch<SetStateAction<T>>] {
  const history = useHistory()
  const location = useLocation()
  const searchString = location.search

  const params = useMemo<any>(() => parse(searchString.slice(1)), [searchString])

  const setParams = useCallback((newParams) => {
    const cleanedParams = _.pickBy(newParams, val => val === 0 || !!val)
    const search = stringify(cleanedParams, {encode: true})
    history.push({search})
  }, [history])

  return [params, setParams]
}
