import React from 'react'
import {SettingsCompanyPage} from './SettingsCompanyPage'
import {LinkTabs} from 'shared/routing/LinkTabs'

export function SettingsPage({match}) {
  return (
    <LinkTabs
      defaultRoute={'company'}
      match={match}
      renderActiveTabPanelOnly={true}
      tabs={[
        {
          route: 'company',
          label: 'Azienda',
          component: SettingsCompanyPage,
        },
      ]}
    />
  )
}
