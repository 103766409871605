import React from 'react'
import {OrderApi} from './OrderApi'
import {DocumentListPage} from 'document/DocumentListPage'
import {MomentDate} from 'shared/date/MomentDate'
import {MoneyValue} from 'shared/components/MoneyValue'
import {IOrder} from 'document/order/IOrder'
import {Tag} from '@blueprintjs/core'

export function OrdersListPage() {
  return <DocumentListPage<IOrder>
    title={'Ordini'}
    link={'order'}
    newLinkText={'Nuovo ordine'}
    counterpartRole={'supplier'}
    crudApi={OrderApi}
    defaultPeriod={'current_year'}
    columns={[
      {
        renderHead: 'Data',
        renderCell: ({date}) => <MomentDate date={date}/>,
      },
      {
        renderHead: 'Fornitore',
        renderCell: ({counterpart}) => counterpart.name,
      },
      {
        renderHead: 'Prezzo',
        renderCell: ({net}) => <MoneyValue value={net}/>,
        align: 'right',
      },
      {
        renderCell: ({status}) => <Tag
          minimal={true}
          intent={status === 'pending' ? 'warning' : 'success'}
        >{status === 'pending' ? 'In attesa' : 'Consegnato'}</Tag>,
        align: 'right',
        minSpace: true,
      },
    ]}
  />
}
