import React, {useCallback, useEffect, useMemo} from 'react'
import {useRouterParams} from 'shared/routing/useRouterParams'
import {useParamPage} from 'shared/params/useParamPage'
import {useParamSort} from 'shared/params/useParamSort'
import {useParamFilter} from 'shared/params/useParamFilter'
import {useApiListResponse} from 'shared/api/hooks/useApiListResponse'
import InputFilters, {IFiltersConfigs} from 'shared/filters/InputFilters'
import PageHeader from 'shared/components/PageHeader'
import Row from 'settings/Row'
import {AttributeApi, IAttribute} from 'productAttributes/attribute'
import {MoneyValue} from 'shared/components/MoneyValue'
import {InputDateSelect} from 'shared/inputs/InputDateSelect'
import {useRouterParam} from 'shared/routing/useRouterParam'
import moment from 'moment'
import {DATE_FORMAT} from 'shared/date/date'
import {ButtonApi} from 'shared/components/ButtonApi'
import {downloadResponse} from 'shared/utils/downloadResponse'
import TotalCard from 'shared/components/TotalCard'
import {useApiResponse} from 'shared/api/hooks/useApiResponse'
import {IStockListData, IStockTotalData, StockApi} from 'analytics/StockApi'
import getProductsFiltersConfig from 'product/getProductsFiltersConfig'
import DataTable from 'shared/table/DataTable'
import GroupBySelect, {groupByPreviewColumn} from 'analytics/GroupBySelect'
import {Tag} from '@blueprintjs/core'

const today = moment().format(DATE_FORMAT)

const sideEffect = {page: 1}

export function StockPage() {
  const routerParams = useRouterParams()
  const [date, setDate] = useRouterParam('date', today, sideEffect)
  const [page, setPage] = useParamPage(routerParams)
  const [sort, setSort] = useParamSort(routerParams)
  const [filters, setFilters] = useParamFilter(routerParams)
  const [groupBy, setGroupBy] = useRouterParam('group_by', null, sideEffect)
  const [search, setSearch] = useRouterParam('search', null, sideEffect)

  const listCall = useCallback(() => StockApi.getList({
      date,
      group_by: groupBy,
      filters,
      page,
      ...sort,
    }),
    [filters, sort, page, date, groupBy])

  const totalCall = useCallback(() => StockApi.totals({filters, date}), [date, filters])
  const exportCall = useCallback(() => StockApi.export({filters, date, group_by: groupBy}), [date, filters, groupBy])

  const attributeResponse = useApiListResponse<IAttribute>(AttributeApi.getList)
  const listResponse = useApiListResponse<IStockListData>(listCall)
  const totalResponse = useApiResponse<IStockTotalData>(totalCall)

  const resetListResponse = listResponse.reset
  useEffect(() => {
    resetListResponse()
  }, [groupBy, resetListResponse])

  const filtersConfigs = useMemo<IFiltersConfigs>(() =>
      getProductsFiltersConfig(attributeResponse?.data),
    [attributeResponse])

  return (
    <div>
      <PageHeader
        title={'Giacenze'}
        rightElement={<ButtonApi
          icon={'export'}
          text={'Esporta'}
          apiCall={exportCall}
          onSuccess={downloadResponse}
        />}
      />

      <Row childrenMargin>
        <InputDateSelect
          value={date}
          onChange={setDate}
          large={true}
        />
        {filtersConfigs && <InputFilters
          value={filters}
          onChange={setFilters}
          configs={filtersConfigs}
          search={search}
          setSearch={setSearch}
        />}
      </Row>

      <Row childrenMargin>
        <TotalCard
          label={'Totale pezzi'}
          loading={totalResponse.loading}
          value={totalResponse.response?.stock}
        />
        <TotalCard
          label={'Totale netto'}
          loading={totalResponse.loading}
          value={<MoneyValue value={totalResponse.response?.stock_value}/>}
        />
      </Row>

      <DataTable<any>
        apiListResponse={listResponse}
        sort={sort}
        setSort={setSort}
        renderHeader={<GroupBySelect
          value={groupBy}
          onChange={setGroupBy}
          items={attributeResponse.data}
        />}
        sortOptions={[
          {label: 'Valore magazzino', sortBy: 'stock_value'},
          {label: 'Giacenza', sortBy: 'stock'},
        ]}
        page={page}
        setPage={setPage}
        columns={[
          groupByPreviewColumn(attributeResponse?.data, groupBy),
          {
            renderHead: 'Giacenza',
            renderCell: ({stock}) =>
              <Tag minimal={true} intent={stock > 0 ? 'success' : 'danger'}>{stock}</Tag>,
            minSpace: true,
            align: 'center',
          },
          {
            renderHead: 'Valore magazzino',
            renderCell: ({stock_value}) =>
              <MoneyValue value={stock_value}/>,
            minSpace: true,
          },
        ]}
      />

    </div>
  )
}
