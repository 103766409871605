import {useCallback, useMemo, useState} from 'react'

export interface IListSelectionManager<T> {
  selected: T[]
  isItemSelected: (item: T) => boolean
  isAllSelected: boolean
  isSomeSelected: boolean
  onItemSelectionChange: (item: T) => () => void
  onGlobalCheckboxChange: () => void
  isGlobalIndeterminate: boolean
  selectedIndexes: number[]
  resetSelection: () => void
}

export function useListSelectionManager<T>(items: T[]): IListSelectionManager<T> {
  const [selected, setSelected] = useState<T[]>([])

  const arrayItems = useMemo(() => items || [], [items])

  const isAllSelected = useMemo(() => arrayItems.length === selected.length, [arrayItems, selected])
  const isSomeSelected = !!selected.length
  const isGlobalIndeterminate = isSomeSelected && !isAllSelected

  const isItemSelected = useCallback(item => selected.indexOf(item) >= 0, [selected])

  const onItemSelectionChange = useCallback(item => () => {
    if (isItemSelected(item))
      setSelected(selected.filter(i => i !== item))
    else
      setSelected([...selected, item])
  }, [selected, setSelected, isItemSelected])

  const onGlobalCheckboxChange = () => {
    if (isAllSelected)
      setSelected([])
    else
      setSelected(arrayItems)
  }

  const selectedIndexes = useMemo(() => selected.map(item => arrayItems.indexOf(item)), [selected, arrayItems])

  const resetSelection = useCallback(() => setSelected([]), [])

  return {
    selected,
    isItemSelected,
    isAllSelected,
    isSomeSelected,
    onItemSelectionChange,
    onGlobalCheckboxChange,
    isGlobalIndeterminate,
    selectedIndexes,
    resetSelection,
  }
}
