import React, {useState} from 'react'
import {Button, Popover} from '@blueprintjs/core'
import {DatePicker} from '../date/DatePicker'
import moment from 'moment'
import {TimePrecision} from '@blueprintjs/datetime'

interface IInputDateSelectProps {
  value: string
  onChange: (value: string) => void
  displayFormat?: string
  timePrecision?: TimePrecision
  closeOnSelection?: boolean
  fill?: boolean
  large?: boolean
}

export function InputDateSelect(props: IInputDateSelectProps) {
  const {
    value,
    onChange,
    displayFormat = 'LL',
    timePrecision,
    fill,
    large,
  } = props

  const [isOpen, setIsOpen] = useState(false)

  const text = moment(value).format(displayFormat)

  const onPickerChange = value => {
    setIsOpen(false)
    onChange(value)
  }

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      autoFocus={false}
      enforceFocus={false}
      canEscapeKeyClose={false}
      fill={fill}
      content={<DatePicker
        value={value}
        onChange={onPickerChange}
        timePrecision={timePrecision}
      />}
    >
      <Button
        onClick={() => setIsOpen(true)}
        text={text}
        fill={fill}
        rightIcon={'chevron-down'}
        large={large}
      />
    </Popover>
  )
}

