import React from 'react'
import {IPaymentMethod} from './IPaymentMethod'
import PaymentMethodApi from './PaymentMethodApi'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {FormManager, IGenericFormProps} from 'shared/form/FormManager'
import {Input} from 'shared/inputsV2/Input'

export default function PaymentMethodForm(props: IGenericFormProps<IPaymentMethod>) {
  const {...formProps} = props

  return (
    <FormManager
      {...formProps}
      api={PaymentMethodApi}
      render={() => <>
        <FieldFormGroup
          label='Nome'
          name='name'
          required={true}
          render={input => <Input {...input} autoFocus={true}/>}
        />
      </>}
    />
  )
}
