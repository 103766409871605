import {EntityId, IEntity} from '../types/IEntity'
import {myAxios} from './myAxios'
import IGetListParams from './hooks/IGetListParams'
import {IResponsePaginated} from './hooks/useApiListResponse'
import Arr from 'shared/utils/Arr'

export interface ICrudApi<T extends IEntity> {
  getList: (params?: IGetListParams) => Promise<IResponsePaginated<T>>
  getOne: (id: string | number, config?) => Promise<T>
  create: (value?: T) => Promise<T>
  update: (id: EntityId, value: T) => Promise<T>
  save: (value?: T) => Promise<T>
  delete: (id: EntityId) => Promise<EntityId>
  search: (params?: IGetListParams) => (search: string) => Promise<IResponsePaginated<T>>
  loadByKeys: (params?: IGetListParams) => (ids: number[]) => Promise<IResponsePaginated<T>>
}

export const extractApiData = ({data: {data}}) => data

export function createCrudApi<T extends IEntity>(url: string): ICrudApi<T> {
  const getList = (params: IGetListParams) => myAxios.get(url, {params}).then(({data}) => data)
  const getOne = (id: EntityId, config?) => myAxios.get(url + '/' + id, config).then(extractApiData)
  const create = (value: T) => myAxios.post(url, value).then(extractApiData)
  const update = (id: EntityId, value: T) => myAxios.put(url + '/' + id, value).then(extractApiData)
  const _delete = (id: EntityId) => myAxios.delete(url + '/' + id).then(extractApiData)
  const save = (value: T) => !!value.id ? update(value.id, value) : create(value)

  const search = (params?: IGetListParams) => (search: string) => getList({...params, search})
  const loadByKeys = (params?: IGetListParams) => ids => getList({
    ...params,
    filters: Arr.insert(params.filters, {field: 'id', op: 'in', value: ids}),
  })

  return {
    getList,
    getOne,
    create,
    update,
    save,
    delete: _delete,
    search,
    loadByKeys,
  }
}
