import {InlineGroup} from 'shared/styled/InlineGroup'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {Validators} from 'shared/form/Validators'
import {InputVat} from 'shared/inputs/InputVat'
import {InputMoney} from 'shared/inputsV2/InputMoney'
import React from 'react'
import {Input} from 'shared/inputsV2/Input'

export function GoodItemForm({isGrossMode, movLabel}) {
  const priceFieldProps = isGrossMode ?
    {name: 'unit_gross', label: `Prezzo di ${movLabel} lordo`} :
    {name: 'unit_net', label: `Prezzo di ${movLabel} netto`}

  return <>
    <InlineGroup>
      <FieldFormGroup
        name={'quantity'}
        label='Quantità'
        required={true}
        validators={[Validators.min(1)]}
        render={input => <Input type={'number'} {...input} autoFocus={true} min={1}/>}
      />

      <FieldFormGroup
        name={'vat'}
        label='IVA'
        render={input => <InputVat {...input} required={false}/>}
      />
    </InlineGroup>

    <InlineGroup>
      <FieldFormGroup
        {...priceFieldProps}
        required={true}
        render={input => <InputMoney {...input}/>}
      />

      <FieldFormGroup
        name={'discount_percent'}
        label={'Sconto'}
        validators={[Validators.min(0), Validators.max(100)]}
        render={input => <Input type={'number'} {...input} leftIcon={'percentage'}/>}
      />
    </InlineGroup>
  </>
}
