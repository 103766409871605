import {Dispatch, SetStateAction, useMemo} from 'react'
import {ISortParam} from 'shared/api/hooks/IGetListParams'

export function useParamSort([params, setParams], defaultValue: ISortParam = undefined): [ISortParam, Dispatch<SetStateAction<ISortParam>>] {

  const sortBy = params?.sortBy
  const sortDir = params?.sortDir

  const sort = useMemo<ISortParam>(() => ({
    sortBy: sortBy || defaultValue?.sortBy,
    sortDir: sortDir || defaultValue?.sortDir || 'asc',
  }), [sortBy, sortDir, defaultValue])

  const setSort = sort => setParams({...params, ...sort, page: 1})

  return [sort, setSort]
}
