export interface ITokenPayload {
  user_id: number
  exp: number
  iss: string
  iat: number
  ucs: 0 | 1
}

export function parseJwt(token): ITokenPayload {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(jsonPayload)
  } catch (e) {
    return null
  }
}
