import {Dispatch, SetStateAction, useCallback, useMemo} from 'react'
import {useRouterParams} from 'shared/routing/useRouterParams'
import _ from 'lodash'

export function useRouterParam<T>(key: string, initial: T, sideEffect = null): [T, Dispatch<SetStateAction<T>>] {
  const [params, setParams] = useRouterParams()

  const param = useMemo(() =>
      _.isNil(params[key]) ? initial : params[key],
    [params, key, initial])

  const setParam = useCallback(param => {
    const otherParams = sideEffect || {}
    setParams({...params, [key]: param, ...otherParams})
  }, [params, setParams, key, sideEffect])

  return [param, setParam]
}
