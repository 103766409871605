import React from 'react'
import {CardsList} from 'shared/styled/CardsList'
import {IOrder} from './IOrder'
import {DocumentDataView} from '../DocumentDataView'
import {ItemsViewSection} from '../ItemsViewSection'
import {OrderGoodItemListRow} from './OrderGoodItemListRow'

interface IOrderViewProps {
  order: IOrder
}

export function OrderView(props: IOrderViewProps) {
  const {order} = props

  return (
    <CardsList>
      <DocumentDataView
        document={order}
        counterpartRole={'supplier'}
      />

      <ItemsViewSection
        title={'Articoli'}
        items={order.goods_items}
        renderItem={item => <OrderGoodItemListRow
          item={item}
          isGrossMode={false}
        />}
      />
    </CardsList>
  )
}
