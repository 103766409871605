import _ from 'lodash'

const round = (number: number, precision?: number): number => {
  const p = _.isNil(precision) ? 5 : precision
  return _.round(number, p)
}

const netToGross = (net, vat) => (+net || 0) * (1 + (+vat || 0))
const grossToNet = (gross, vat) => (+gross || 0) / (1 + (+vat || 0))
const applyDiscount = (value, discount) => (+value || 0) * (1 - (+discount || 0))

const grossToNetR = (gross, vat, precision?) => round(grossToNet(gross, vat), precision)
const netToGrossR = (net, vat, precision?) => round(netToGross(net, vat), precision)
const applyDiscountR = (value, discount, precision?) => round(applyDiscount(value, discount), precision)

export const MoneyUtils = {
  round,
  netToGross,
  netToGrossR,
  grossToNet,
  grossToNetR,
  applyDiscount,
  applyDiscountR,
}
