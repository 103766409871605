import IGetListParams from 'shared/api/hooks/IGetListParams'
import {myAxios} from 'shared/api/myAxios'

export const CustomerServiceApi = {
  getUsers: (params: IGetListParams) =>
    myAxios.get('customer_service/users', {params}).then(({data}) => data),

  alignStockHistory: () =>
    myAxios.post('customer_service/align_stock_history'),

  alignDocumentsTotals: () =>
    myAxios.post('customer_service/align_documents_totals'),
}
