import React from 'react'
import {ISale} from './ISale'
import {DocumentDataView} from '../../DocumentDataView'
import {ItemsViewSection} from '../../ItemsViewSection'
import {GoodItemListRow} from '../../GoodItemListRow'
import {CardsList} from 'shared/styled/CardsList'
import {MoneyItemListRow} from '../MoneyItemListRow'
import {ListRow} from 'shared/list/ListRow'
import {MoneyValue} from 'shared/components/MoneyValue'
import {ReturnedItemListRow} from './ReturnedItemListRow'

interface ISaleViewProps {
  sale: ISale
}

export function SaleView(props: ISaleViewProps) {
  const {sale} = props

  return (
    <CardsList>
      <DocumentDataView
        document={sale}
        counterpartRole={'customer'}
      />

      <ItemsViewSection
        title={'Articoli'}
        items={sale.goods_items}
        renderItem={item => <GoodItemListRow item={item} isGrossMode={true}/>}
      />

      <ItemsViewSection
        title={'Modifiche al totale'}
        items={sale.moneys_items}
        renderItem={item => <MoneyItemListRow item={item} isGrossMode={true}/>}
      />

      <ItemsViewSection
        title={'Resi'}
        items={sale.returned_items}
        renderItem={item => <ReturnedItemListRow item={item} isGrossMode={true}/>}
      />

      <ItemsViewSection
        title={'Pagamenti'}
        items={sale.payments_items}
        renderItem={item => <ListRow
          topLeft={item.payment_method.name}
          topRight={<MoneyValue value={item.gross}/>}
        />}
      />
    </CardsList>
  )
}
