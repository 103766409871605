import {createCrudApi, ICrudApi} from 'shared/api/CrudApi'
import {EntityId} from 'shared/types/IEntity'
import {myAxios} from 'shared/api/myAxios'
import IGetListParams from 'shared/api/hooks/IGetListParams'
import {IResponsePaginated} from 'shared/api/hooks/useApiListResponse'
import {IProductHistory} from 'product/IProductHistory'
import IProduct from './IProduct'

interface IProductApi extends ICrudApi<IProduct> {
  history: (id: EntityId) => (params?: IGetListParams) => Promise<IResponsePaginated<IProductHistory>>
}

export const ProductApi: IProductApi = {
  ...createCrudApi<IProduct>('product'),

  history: id => (params: IGetListParams) =>
    myAxios.get('product_history/' + id, {params}).then(({data}) => data),
}
