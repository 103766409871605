import React, {useState} from 'react'
import {Form} from 'react-final-form'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {Validators} from 'shared/form/Validators'
import {Button, Intent, NonIdealState} from '@blueprintjs/core'
import {Link} from 'react-router-dom'
import {AuthApi} from './AuthApi'
import {useApiFormSubmitter} from 'shared/api/hooks/useApiFormSubmitter'
import {Input} from 'shared/inputsV2/Input'

export function ResetPasswordRequest() {
  const [requestSended, setRequestSended] = useState<boolean>()
  const {onSubmit, loading} = useApiFormSubmitter(AuthApi.resetPasswordRequest, () => setRequestSended(true))

  if (requestSended)
    return (
      <NonIdealState
        title={'Ti abbiamo inviato un link'}
        description={'Per reimpostare la tua password, clicca sul link che ti abbiamo inviato per email.'}
        icon={'envelope'}
      />
    )

  return <Form<{ email: string }>
    onSubmit={onSubmit}
    render={({handleSubmit, form}) => {
      const emailStatus = form.getFieldState('email')
      return (<form onSubmit={handleSubmit}>
        <h4>Password dimenticata?</h4>
        <p>Nessun problema, ti manderemo un'email con le istruzioni per reimpostare la tua password</p>
        <FieldFormGroup
          name={'email'}
          validators={[Validators.email]}
          required={true}
          render={(params, {meta}) => <Input
            {...params}
            autoFocus={true}
            autoComplete={true}
            placeholder={'Inserisci qui la tua email'}
            large={true}
          />}
        />

        <Button
          disabled={emailStatus && (emailStatus.invalid || emailStatus.validating)}
          type={'submit'}
          fill={true}
          large={true}
          text={'Reimposta la password'}
          loading={loading}
          intent={Intent.PRIMARY}
        />

        <div style={{marginTop: '8px'}}>
          <Link to={'/login'}>Torna al login</Link>
        </div>
      </form>)
    }}
  />
}
