import React, {useCallback} from 'react'
import {MomentDate} from 'shared/date/MomentDate'
import {CounterpartPreview} from '../../counterpart/components/CounterpartPreview'
import {useApiListResponse} from 'shared/api/hooks/useApiListResponse'
import {useApiListStatusManager} from 'shared/api/hooks/useApiListStatusManager'
import {MoneyValue} from 'shared/components/MoneyValue'
import {DataList} from 'shared/list/DataList'
import {ProductApi} from '../ProductApi'
import {ListRow} from 'shared/list/ListRow'
import {Link} from 'react-router-dom'
import {DetailsViewer} from 'shared/list/DetailsViewer'
import {Icon, Intent} from '@blueprintjs/core'
import {IconName} from '@blueprintjs/icons'
import IProduct from '../IProduct'
import {IProductHistory} from 'product/IProductHistory'

interface IProductHistoryListProps {
  product: IProduct
}

export function ProductHistoryList(props: IProductHistoryListProps) {
  const {
    product,
  } = props

  const paramsManager = useApiListStatusManager({sortBy: 'date_time', sortDir: 'asc'})
  const {apiParams} = paramsManager

  const getList = useCallback(() => ProductApi.history(product.id)(apiParams), [product, apiParams])

  const apiListResponse = useApiListResponse<IProductHistory>(getList)

  return (
    <div>
      <h4>Storico movimenti</h4>

      <DataList<IProductHistory>
        renderItem={item => <HistoryListRow movementItem={item}/>}
        apiListResponse={apiListResponse}
        paramsManager={paramsManager}
        hideSearchAndSort={true}
        emptyStateTitle='Prodotto inutilizzato'
        emptyStateDescription='Questo prodotto non è mai stato ne acquistato ne venduto'
      />
    </div>
  )
}

interface IHistoryListRowProps {
  movementItem: IProductHistory
}

function HistoryListRow(props: IHistoryListRowProps) {
  const {
    movementItem: {
      quantity,
      document_type,
      net,
      gross,
      date_time,
      document_id,
      document,
    },
  } = props

  const {label, icon, link, intent} = getHistoryTypeConfig(document_type)

  const topLeft = document ?
    <Link to={'/' + link + '/' + document_id}>{label}: {quantity}</Link> :
    label + ': ' + quantity

  return <ListRow
    leftImage={<Icon icon={icon} iconSize={20} intent={intent}/>}
    topLeft={topLeft}
    topRight={<MoneyValue value={document_type === 'sale' ? gross : net}/>}
    bottomLeft={<DetailsViewer
      items={[
        {
          label: 'Data',
          value: <MomentDate date={date_time}/>,
          hidden: !date_time,
        },
        {
          label: (document_type === 'purchase' ? 'Fornitore' : 'Cliente'),
          value: document?.counterpart && <CounterpartPreview item={document.counterpart}/>,
          hidden: !document?.counterpart,
        },
      ]}
    />}
  />
}

function getHistoryTypeConfig(type): { icon: IconName, label: string, intent: Intent, link?: string } {
  switch (type) {
    case 'order':
      return {icon: 'timeline-events', label: 'Ordinati', link: 'order', intent: 'warning'}
    case 'purchase':
      return {icon: 'shopping-cart', label: 'Acquistati', link: 'purchase', intent: 'danger'}
    case 'sale':
      return {icon: 'shop', label: 'Venduti', link: 'sale', intent: 'success'}
    case 'returned':
      return {icon: 'undo', label: 'Resi', link: 'sale', intent: 'danger'}
    default:
      return {icon: 'database', label: 'Giacenza iniziale', intent: 'primary'}
  }
}
