import React, {useCallback, useMemo} from 'react'
import {IInputGenericProps} from 'shared/inputsV2/IInputGenericProps'
import {InlineMultiSelect} from 'shared/inputsV2/select/InlineMultiSelect'
import {IResponsePaginated} from 'shared/api/hooks/useApiListResponse'
import {useSearchApiList} from 'shared/hooks/useSearchApiList'
import {executeItemsEqual, ItemRenderer} from '@blueprintjs/select'
import {Button, Checkbox, ITagInputProps, Menu, MenuItem, Spinner} from '@blueprintjs/core'
import Arr from 'shared/utils/Arr'
import {ItemListRenderer} from '@blueprintjs/select/src/common/itemListRenderer'
import {ItemsEqualProp} from '@blueprintjs/select/src/common/listItemsProps'

interface IInlineMultiSelectAsyncProps<T> extends IInputGenericProps<T[], HTMLDivElement> {
  itemsGetter: (find: string) => Promise<IResponsePaginated<T>>
  renderItem: (item: T) => React.ReactNode
  allowNull?: boolean
  itemsEqual?: ItemsEqualProp<T>
}

function InlineMultiSelectAsync<T>(props: IInlineMultiSelectAsyncProps<T>) {
  const {
    value,
    onChange,
    itemsGetter,
    renderItem,
    autoFocus,
    allowNull,
    itemsEqual,
    ...divProps
  } = props

  const {
    response,
    search,
    setSearch,
    loading,
    data,
    remaining,
  } = useSearchApiList(itemsGetter)

  const valueArray = useMemo(() => value || [], [value])
  const getItemIndex = useCallback(item =>
      item === null ?
        valueArray.indexOf(null) :
        valueArray.findIndex(i => executeItemsEqual(itemsEqual, i, item)),
    [valueArray, itemsEqual])
  const isItemSelected = item => getItemIndex(item) >= 0
  const items = useMemo(() => {
    if (!data)
      return []
    if (!allowNull || search.length)
      return data
    return [null, ...data]
  }, [data, allowNull, search])

  const itemRenderer: ItemRenderer<T> = (item, {handleClick, modifiers: {active}, index}) => {
    const isSelected = isItemSelected(item)
    const text = <Checkbox
      checked={isSelected}
      inline={true}
      onClick={e => e.stopPropagation()}
      style={{margin: 0}}
    >{renderItem(item)}</Checkbox>

    return <MenuItem
      active={active}
      key={index}
      onClick={handleClick}
      text={text}
      shouldDismissPopover={false}
    />
  }

  const onItemSelect = item => {
    if (isItemSelected(item))
      onChange(Arr.remove(valueArray, getItemIndex(item)))
    else
      onChange(Arr.insert(valueArray, item))
  }

  const onItemRemove = (_, index) => onChange(Arr.remove(valueArray, index))

  const clear = () => onChange([])

  const clearButton =
    loading ? <Spinner size={20}/> :
      ((valueArray.length > 0) ? <Button icon="cross" minimal={true} onClick={clear}/> : undefined)

  const tagInputProps: Partial<ITagInputProps> = {
    rightElement: clearButton,
    onRemove: onItemRemove,
    tagProps: {minimal: true},
  }

  const renderNoResults = response && response.data.length === 0 && <MenuItem
    disabled={true}
    text='Nessun risultato'
  />

  const renderMoreResults = !!remaining && <MenuItem
    disabled={true}
    text={`Ci sono altri ${remaining} risultati`}
  />

  const itemListRenderer: ItemListRenderer<T> = ({items, renderItem}) =>
    <Menu>
      {items.map(renderItem)}
      {renderNoResults}
      {renderMoreResults}
    </Menu>

  return (
    <InlineMultiSelect
      tagRenderer={renderItem}
      items={items}
      onItemSelect={onItemSelect}
      itemRenderer={itemRenderer}
      query={search}
      onQueryChange={setSearch}
      itemListRenderer={itemListRenderer}
      tagInputProps={tagInputProps}
      selectedItems={valueArray}
      scrollToActiveItem={true}
      itemsEqual={itemsEqual}
      {...divProps}
    />
  )
}

export default InlineMultiSelectAsync
