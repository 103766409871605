import React from 'react'
import {FocusStyleManager} from '@blueprintjs/core'
import {BrowserRouter} from 'react-router-dom'
import {AppWrapper} from 'shared/components/AppWrapper'
import {TokenState} from 'auth/TokenState'

FocusStyleManager.onlyShowFocusOnTabs()

function App() {
  return (
    <AppWrapper>
      <BrowserRouter>
        <TokenState/>
      </BrowserRouter>
    </AppWrapper>
  )
}

export default App
