import {Redirect, Route, Switch, useLocation} from 'react-router'
import {Tab, Tabs} from '@blueprintjs/core'
import {Link} from 'react-router-dom'
import React from 'react'
import styled from 'styled-components'

interface ILinkTab {
  route: string
  label: string
  component: any
}

interface ILinkTabsProps {
  tabs: ILinkTab[]
  defaultRoute: string
  match
  renderActiveTabPanelOnly?: boolean
}

export function LinkTabs(props: ILinkTabsProps) {
  const {
    tabs,
    match,
    defaultRoute,
    renderActiveTabPanelOnly,
  } = props

  const location = useLocation()
  const selected = location.pathname.substr(match.path.length + 1)

  return <div>
    <Tabs
      renderActiveTabPanelOnly={renderActiveTabPanelOnly}
      selectedTabId={selected}
    >
      {tabs.map(({label, route}) => <Tab
        key={route}
        id={route}
        title={<Link to={match.path + '/' + route}>{label}</Link>}
      />)}
    </Tabs>

    <TabPanel>
      <Switch>
        {tabs.map(({route, component}) => <Route
          key={route}
          path={match.path + '/' + route}
          strict
          exact
          component={component}/>)}

        <Redirect to={match.path + '/' + defaultRoute}/>
      </Switch>
    </TabPanel>
  </div>
}

const TabPanel = styled.div`
  margin-top: 20px;
`
