import React from 'react'
import {Suggest} from '@blueprintjs/select'
import {HTMLInputProps, IInputGroupProps, Menu, MenuItem} from '@blueprintjs/core'

interface IInputSuggestAsyncProps {
  value: string
  onChange: (value: string) => void
  items: string[]
  inputProps?: IInputGroupProps & HTMLInputProps
}

export function InputSuggestString(props: IInputSuggestAsyncProps) {
  const {
    value,
    onChange,
    items,
    inputProps,
  } = props

  const hidePopover = !items || !items.length

  return (
    <Suggest<string>
      items={items || []}
      query={value}
      onQueryChange={onChange}
      onItemSelect={onChange}
      inputValueRenderer={() => value}
      fill={true}
      itemRenderer={(item, {index, handleClick, modifiers: {active, disabled}}) =>
        <MenuItem
          key={index}
          text={item}
          onClick={handleClick}
          active={active}
          disabled={disabled}
        />
      }
      itemListRenderer={({items, renderItem}) => <Menu>
        {items.map((item, index) => renderItem(item, index))}
      </Menu>}
      inputProps={inputProps}
      popoverProps={{
        minimal: true,
        disabled: hidePopover,
      }}
    />
  )
}
