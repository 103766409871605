import React from 'react'
import {Validators} from 'shared/form/Validators'
import {InputVat} from 'shared/inputs/InputVat'
import {IMassUpdateOption} from './MassUpdateButton'
import {Input} from 'shared/inputsV2/Input'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'

export const vatOption: IMassUpdateOption = {
  label: 'Imposta IVA',
  initialValue: {vat: null},
  form: <FieldFormGroup
    name={'vat'}
    label={'IVA'}
    render={props => <InputVat
      {...props}
      autoFocus={true}
    />}
  />,
}

export const discountOption: IMassUpdateOption = {
  label: 'Imposta Sconto',
  initialValue: {discount_percent: null},
  form: <FieldFormGroup
    name={'discount_percent'}
    validators={[Validators.min(0), Validators.max(100)]}
    label={'Sconto'}
    render={props => <Input
      {...props}
      leftIcon={'percentage'}
      type={'number'}
      autoFocus={true}
    />}
  />,
}
