import {useHistory, useParams} from 'react-router'
import {useCallback, useState} from 'react'
import {EntityId, IEntity} from '../types/IEntity'
import {ICrudApi} from '../api/CrudApi'
import {useApiResponse} from '../api/hooks/useApiResponse'

export function useEntityPage<T extends IEntity>(baseUrl: string, api: ICrudApi<T>) {
  const {id} = useParams()
  const history = useHistory()
  const [_editing, setEditing] = useState(false)

  const manager = useEntityManager<T>(+id, api.getOne)
  const {isNew} = manager

  const editing = isNew || _editing

  const goBack = () => history.push('/' + baseUrl)
  const goTo = id => history.push(`/${baseUrl}/${id}`)

  const onDismiss = () => isNew ? goBack() : setEditing(false)
  const onSaved = isNew ?
    (item) => goTo(item.id) :
    (item) => {
      setEditing(false)
      manager.setEntity(item)
    }

  return {
    ...manager,
    editing,
    setEditing,
    onDismiss,
    onSaved,
    goBack,
    goTo,
  }
}

interface IUseEntityManager<T> {
  id: EntityId
  entity: T
  setEntity: (value: T) => void
  isNew: boolean
  loading: boolean
  error: any
  reload: () => void
}

function useEntityManager<T extends IEntity>(id: EntityId, apiCall: (id: EntityId) => Promise<T>): IUseEntityManager<T> {
  const api = useCallback(() => id && apiCall(id), [apiCall, id])

  const {
    response,
    loading,
    reload,
    error,
    reset,
  } = useApiResponse<T>(api)

  const isNew = !id

  return {
    id,
    isNew,
    entity: response,
    loading: loading || (!isNew && !response && !error),
    setEntity: reset,
    error,
    reload,
  }
}
