import React from 'react'
import {Alignment, Classes, Navbar} from '@blueprintjs/core'
import {UserControl} from '../user/UserControl'
import {UnstyledLink} from 'shared/components/UnstyledLink'

export function AppNavBar(props) {
  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <UnstyledLink
          to={'/'}
          className={Classes.NAVBAR_HEADING}
        >Negoziapp</UnstyledLink>
      </Navbar.Group>

      <Navbar.Group align={Alignment.RIGHT}>
        <UserControl/>
      </Navbar.Group>
    </Navbar>
  )
}
