import React from 'react'
import {IItemsFormSectionState, ItemsFormSection} from '../../ItemsFormSection'
import {ISaleReturnedItem} from './ISaleReturnedItem'
import {MoneyUtils} from 'shared/utils/moneyUtils'
import {ISaleGoodItem} from './ISaleGoodItem'
import {ReturnableItemsList} from './ReturnableItemsList'
import {Form} from 'react-final-form'
import {Button, Classes, Intent} from '@blueprintjs/core'
import {goodItemMapper} from '../../GoodsItemsFormSection'
import {ReturnedItemListRow} from './ReturnedItemListRow'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {InlineGroup} from 'shared/styled/InlineGroup'
import {Validators} from 'shared/form/Validators'
import {InputMoney} from 'shared/inputsV2/InputMoney'
import {InputVat} from 'shared/inputs/InputVat'
import {GoodItemListRow} from '../../GoodItemListRow'
import {MomentDate} from 'shared/date/MomentDate'
import {DataViewer} from 'shared/components/DataViewer'
import {Input} from 'shared/inputsV2/Input'

interface IReturnedItemsFormSectionProps {
  isGrossMode: boolean
  state: IItemsFormSectionState<ISaleReturnedItem>
  actualSaleId
}

export function ReturnedItemsFormSection(props: IReturnedItemsFormSectionProps) {
  const {
    state,
    isGrossMode,
    actualSaleId,
  } = props

  const {
    isNewSelected,
    selectedValue,
    onDialogSave,
    removeSelected,
    onNewItem,
  } = state

  const priceFieldProps = isGrossMode ?
    {name: 'unit_gross', label: `Prezzo lordo`} :
    {name: 'unit_net', label: `Prezzo netto`}

  const onReturnableItemSelect = value => onNewItem(returnedItemMapper(value, isGrossMode))
  const onSubmit = value => onDialogSave(goodItemMapper(value, isGrossMode))

  const renderDialog = selectedValue ?
    <Form<ISaleReturnedItem>
      onSubmit={onSubmit}
      initialValues={selectedValue}
      render={({handleSubmit, values}) =>
        <form onSubmit={handleSubmit} className={Classes.DIALOG_BODY}>
          <DataViewer
            style={{marginBottom: '8px'}}
            striped={false}
            hideRowIfNullValue={true}
            data={[
              {label: 'Venduto il', value: <MomentDate date={values.returned_from.movement.date_time}/>},
              {
                label: 'Cliente',
                value: values.returned_from.movement.counterpart?.name,
              },
            ]}
          />

          <GoodItemListRow
            item={{
              ...values.returned_from,
              product: values.product,
            }}
            isGrossMode={isGrossMode}
            style={{marginBottom: '16px'}}
          />

          <InlineGroup>
            <FieldFormGroup
              name={'quantity'}
              label='Quantità'
              required={true}
              disabled={values.returned_from.quantity === 1}
              validators={[Validators.min(1), Validators.max(values.returned_from.quantity)]}
              render={input => <Input type={'number'} {...input} autoFocus={true} min={1}/>}
            />

            <FieldFormGroup
              name={'vat'}
              label={'IVA'}
              render={props => <InputVat {...props}/>}
            />
          </InlineGroup>

          <FieldFormGroup
            {...priceFieldProps}
            required={true}
            render={input => <InputMoney {...input}/>}
          />

          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {!isNewSelected && <Button
              text={'Annulla reso'}
              intent={Intent.DANGER}
              onClick={removeSelected}
            />}
            <Button
              type={'submit'}
              text={'Conferma'}
              intent={Intent.SUCCESS}
            />
          </div>
        </form>
      }
    /> :
    <ReturnableItemsList
      isGrossMode={isGrossMode}
      onItemSelect={onReturnableItemSelect}
      actualSaleId={actualSaleId}
    />

  return (
    <ItemsFormSection<ISaleReturnedItem>
      title={'resi'}
      renderItem={item => <ReturnedItemListRow
        item={item}
        isGrossMode={isGrossMode}
      />}
      state={state}
      dialogTitle={'Effettua un reso'}
      renderDialog={renderDialog}
    />
  )
}

const returnedItemMapper = (item: ISaleGoodItem, isGrossMode: boolean): ISaleReturnedItem => {
  const vat = item.vat ? item.vat.value : 0
  const value = isGrossMode ?
    ({...item, gross: +item.gross, net: MoneyUtils.grossToNetR(item.gross, vat, 5)}) :
    ({...item, net: +item.net, gross: MoneyUtils.netToGrossR(item.net, vat, 2)})

  return value
}
