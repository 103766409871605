import React, {useCallback, useMemo} from 'react'
import {ProductApi} from 'product/ProductApi'
import {useRouterParams} from 'shared/routing/useRouterParams'
import {useParamPage} from 'shared/params/useParamPage'
import {useParamSort} from 'shared/params/useParamSort'
import {useParamFilter} from 'shared/params/useParamFilter'
import {useApiListResponse} from 'shared/api/hooks/useApiListResponse'
import InputFilters, {IFiltersConfigs} from 'shared/filters/InputFilters'
import PageHeader from 'shared/components/PageHeader'
import Row from 'settings/Row'
import DataTable from 'shared/table/DataTable'
import IProduct from 'product/IProduct'
import {AttributeApi, IAttribute} from 'productAttributes/attribute'
import {MoneyValue} from 'shared/components/MoneyValue'
import {Tab, Tabs, Tag} from '@blueprintjs/core'
import {useLocalStorage} from 'use-hooks'
import {ISortParam} from 'shared/api/hooks/IGetListParams'
import {ButtonLink} from 'shared/routing/ButtonLink'
import Arr from 'shared/utils/Arr'
import ProductAttributesList from 'product/components/ProductAttributesList'
import {AppText} from 'shared/components/AppText'
import getProductsFiltersConfig from 'product/getProductsFiltersConfig'
import {useRouterParam} from 'shared/routing/useRouterParam'

const defaultSort: ISortParam = {sortBy: 'code', sortDir: 'asc'}

export function ProductsListPage() {
  const routerParams = useRouterParams()
  const [page, setPage] = useParamPage(routerParams)
  const [sort, setSort] = useParamSort(routerParams, defaultSort)
  const [filters, setFilters] = useParamFilter(routerParams)
  const [search, setSearch] = useRouterParam('search', '', defaultSort)
  const [stockStatus, setStockStatus] = useLocalStorage('ProductsPage.stockStatus', 'all')

  const listCall = useCallback(() => {
      const allFilters = [...filters]

      if (stockStatus === 'inStock')
        allFilters.push({field: 'stock', op: '>', value: 0})
      else if (stockStatus === 'notInStock')
        allFilters.push({field: 'stock', op: '=', value: 0})

      return ProductApi.getList({
        filters: allFilters,
        ...sort,
        page,
        search,
      })
    },
    [filters, sort, page, stockStatus, search])

  const attributeResponse = useApiListResponse<IAttribute>(AttributeApi.getList)
  const listResponse = useApiListResponse<IProduct>(listCall)

  const filtersConfigs = useMemo<IFiltersConfigs>(() =>
      getProductsFiltersConfig(attributeResponse?.data),
    [attributeResponse])

  const onAttributeValueClick = ({attribute, value}, e) => {
    e.stopPropagation()
    const attributeField = 'attribute.' + attribute.id
    const filterIndex = filters.findIndex(({field}) => field === attributeField)
    const nextValue = {field: attributeField, op: 'in', value: [value]}
    setFilters(Arr.insertOrUpdate(filters, nextValue, filterIndex))
  }

  return (
    <div>
      <PageHeader
        title={'Catalogo'}
        rightElement={<ButtonLink
          icon={'plus'}
          to={'product/new'}
          text={'Crea prodotto'}
          intent={'success'}
          large={true}
        />}
      />

      <Row childrenMargin>
        {filtersConfigs && <InputFilters
          value={filters}
          onChange={setFilters}
          configs={filtersConfigs}
          search={search}
          setSearch={setSearch}
        />}
      </Row>

      <Tabs
        renderActiveTabPanelOnly={true}
        selectedTabId={stockStatus}
        onChange={setStockStatus}
      >
        <Tab
          id={'all'}
          title={'Tutti'}
        />
        <Tab
          id={'inStock'}
          title={'In magazzino'}
        />
        <Tab
          id={'notInStock'}
          title={'Terminati'}
        />
      </Tabs>

      <DataTable<IProduct>
        apiListResponse={listResponse}
        sort={sort}
        setSort={setSort}
        sortOptions={[
          {label: 'Prezzo', sortBy: 'price_list_net'},
          {label: 'Descrizione', sortBy: 'description'},
          {label: 'Codice', sortBy: 'code'},
          {label: 'Giacenza', sortBy: 'stock'},
        ]}
        page={page}
        setPage={setPage}
        rowLink={({id}) => 'product/' + id}
        columns={[
          {
            renderHead: 'Prodotto',
            renderCell: ({code, description, attributes_values}) =>
              <div>
                <div>
                  <AppText size={'L'}>{description}</AppText>
                  {code && <AppText muted> - {code}</AppText>}
                </div>

                <ProductAttributesList
                  values={attributes_values}
                  onAttributeClick={onAttributeValueClick}
                />
              </div>,
          },
          {
            renderHead: 'Giacenza',
            renderCell: ({stock}) =>
              <Tag minimal={true} intent={stock > 0 ? 'success' : 'danger'}>{stock}</Tag>,
            minSpace: true,
            align: 'center',
          },
          {
            renderHead: 'Prezzo',
            renderCell: ({price_list_gross}) =>
              <MoneyValue value={price_list_gross}/>,
            minSpace: true,
          },
        ]}
      />
    </div>
  )
}
