import React from 'react'
import {Colors} from '@blueprintjs/core'
import styled, {css} from 'styled-components'

export interface ITableColumn<T> {
  renderCell: (item: T, index?: number) => any
  name?: string
  renderHead?: any
  cellProps?: (item: T) => React.HTMLAttributes<HTMLTableCellElement>
  minSpace?: boolean
  align?: 'right' | 'left' | 'center'
}

export interface ITableProps<T> extends React.HTMLAttributes<HTMLTableElement> {
  data: T[]
  columns: ITableColumn<T>[]
  rowProps?: (item: T, index: number) => React.HTMLAttributes<HTMLTableRowElement>
  hideHeader?: boolean
  interactive?: boolean
  striped?: boolean
  compact?: boolean
  footer?: any
}

export function Table<T>(props: ITableProps<T>) {

  const {
    data,
    columns,
    rowProps,
    hideHeader,
    footer,
    ...htmlTableProps
  } = props

  const header = !hideHeader &&
    <thead>
    <tr>
      {columns.map((column, i) => <Th
        key={column.name || i}
        minSpace={column.minSpace}
        align={column.align}
      >
        {column.renderHead}
      </Th>)}
    </tr>
    </thead>


  const rows = data && data.map((item, index) => {
    const itemRowProps = {
      key: index,
      ...(rowProps && rowProps(item, index)),
    }

    return (
      <tr {...itemRowProps}>{
        columns.map((column, i) => {
            const itemCellProps = {
              key: itemRowProps + '_' + (column.name || i),
              ...(column.cellProps && column.cellProps(item)),
            }

            return (
              <Td
                {...itemCellProps}
                align={column.align}
              >
                {column.renderCell(item, index)}
              </Td>
            )
          },
        )
      }</tr>
    )
  })

  return (
    <StyledTable
      hideHeader={hideHeader}
      {...htmlTableProps}
    >
      {header}
      <tbody>
      {rows}
      </tbody>
      <tfoot>
      <tr>
        {footer}
      </tr>
      </tfoot>
    </StyledTable>
  )
}

const interactiveThCss = css`
  cursor: pointer;

  :hover {
    background-color: ${Colors.LIGHT_GRAY4};
  }
`

const minSpaceCss = css`
  width: 1%;
  white-space: nowrap;
`

const Th = styled.th<{ interactive?, minSpace?, align }>`
  background-color: ${Colors.LIGHT_GRAY5};

  :not(:last-child) {
    border-right: solid 1px;
    border-color: rgba(16, 22, 26, 0.15);
  }

  ${({interactive}) => interactive && interactiveThCss}
  ${({minSpace}) => minSpace && minSpaceCss}
  text-align: ${({align}) => align || 'left'}
`
const Td = styled.td<{ align }>`
  text-align: ${({align}) => align || 'left'}
`

const interactiveCss = css`
  tbody > tr {
    cursor: pointer;
  }

  tbody > tr:hover {
    background-color: rgba(191, 204, 214, 0.3);
  }

  tbody > tr:active {
    background-color: rgba(191, 204, 214, 0.4);
  }
`

const stripedCss = css`
  tbody > tr:nth-child(even) {
    background-color: rgba(191, 204, 214, .15);
  }
`

const headSeparatorCss = css`
  tbody > tr:first-child {
    box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, .15);
  }
`

const StyledTable = styled.table<any>`
  width: 100%;
  border-collapse: collapse;

  td, th {
    padding: ${props => props.compact ? 4 : 11}px;
    vertical-align: top;
  }

  ${props => !props.hideHeader && headSeparatorCss}
  ${props => props.interactive && interactiveCss}
  ${props => props.striped && stripedCss}
`
