import React, {useCallback} from 'react'
import {Button, ButtonGroup, IconName, Intent, MenuItem, Tag} from '@blueprintjs/core'
import IProduct from '../product/IProduct'
import {useToggleState} from 'shared/hooks/useToggleState'
import {AsyncOmnibar} from 'shared/components/AsyncOmnibar'
import {ListRow} from 'shared/list/ListRow'
import {MoneyValue} from 'shared/components/MoneyValue'
import {DetailsViewer} from 'shared/list/DetailsViewer'
import {ProductApi} from '../product/ProductApi'

interface IDocumentFormActionsButtonProps {
  onSelected: (product: IProduct) => void
  isGrossMode: boolean
  setIsGrossMode: (value: boolean) => void
  date: string
  actions: {
    icon: IconName,
    label: string,
    action: () => void
    disabled?: boolean
  }[]
}

export function DocumentFormActionsButton(props: IDocumentFormActionsButtonProps) {
  const {
    date,
    onSelected,
    actions,
    isGrossMode,
    setIsGrossMode,
  } = props

  const searchOpenState = useToggleState()

  const onProductSelected = (product) => {
    searchOpenState.off()
    onSelected(product)
  }

  const itemsGetter = useCallback(search => ProductApi.getList({search, stock_date: date}), [date])

  return (
    <div>
      <ButtonGroup fill={true} style={{marginBottom: '8px'}}>
        {actions.map(({icon, label, action, disabled}, index) => <Button
          key={index}
          text={label}
          icon={icon}
          onClick={() => action()}
          disabled={disabled}
        />)}
        <Button
          icon={'percentage'}
          text={'Utilizza prezzi ' + (isGrossMode ? 'netti' : 'lordi')}
          onClick={() => setIsGrossMode(!isGrossMode)}
        />
      </ButtonGroup>

      <Button
        icon={'add-to-artifact'}
        text={'Aggiungi un articolo'}
        large={true}
        fill={true}
        intent={Intent.PRIMARY}
        onClick={() => searchOpenState.on()}
      />

      <AsyncOmnibar<IProduct>
        isOpen={searchOpenState.isOn}
        onClose={searchOpenState.off}
        onItemSelect={onProductSelected}
        itemsGetter={itemsGetter}
        itemRenderer={(product, itemProps) => <MenuItem
          onClick={itemProps.handleClick}
          key={product.id}
          text={<SelectProductListRow
            product={product}
          />}
        />}
      />
    </div>
  )
}

function SelectProductListRow({product}) {

  return (
    <ListRow
      topLeft={product.description}
      topRight={<MoneyValue value={product.price_list_gross}/>}
      bottomLeft={<DetailsViewer items={[
        {label: 'Cod', value: product.code, hidden: !product.code},
        {label: 'IVA', value: product.vat ? product.vat.label : 'Nessuna'},
      ]}/>}
      bottomRight={product.stock <= 0 ?
        <Tag intent={Intent.DANGER} minimal={true}>terminato</Tag> :
        <Tag intent={Intent.SUCCESS} minimal={true}>{product.stock} in magazzino</Tag>
      }
    />
  )
}
