import React from 'react'
import {PurchaseApi} from './PurchaseApi'
import {useEntityPage} from 'shared/hooks/useEntityPage'
import {LoadingState} from 'shared/components/LoadingState'
import {ErrorState} from 'shared/components/ErrorState'
import {IMovement} from '../IMovement'
import {Button, ButtonGroup, Menu, MenuItem, Popover} from '@blueprintjs/core'
import PageHeader from 'shared/components/PageHeader'
import useEntityActions from 'shared/hooks/useEntityActions'
import {PurchaseForm} from './PurchaseForm'
import {PurchaseView} from './PurchaseView'

export function PurchasePage() {
  const {
    isNew,
    entity,
    error,
    loading,
    onSaved,
    editing,
    setEditing,
    onDismiss,
    goBack,
  } = useEntityPage<IMovement>('purchase', PurchaseApi)

  const {deleteItem} = useEntityActions(entity, PurchaseApi, {onDeleted: goBack})

  if (loading)
    return <LoadingState/>
  if (error)
    return <ErrorState/>

  const buttonGroup = <ButtonGroup>
    <Button
      text={'Modifica'}
      onClick={() => setEditing(true)}
    />
    <Popover content={<Menu>
      <MenuItem
        icon={'trash'}
        text={'Elimina'}
        onClick={deleteItem}
      />
    </Menu>}>
      <Button icon={'more'}/>
    </Popover>
  </ButtonGroup>

  const title = isNew ? 'Nuovo arrivo merce' : ('Arrivo merce da ' + entity.counterpart.name)

  return (
    <div>
      <PageHeader
        title={title}
        rightElement={buttonGroup}
      />
      {editing ?
        <PurchaseForm
          initialValues={entity}
          isNew={isNew}
          onSaved={onSaved}
          onDismiss={onDismiss}
        /> :
        <PurchaseView
          purchase={entity}
        />
      }
    </div>
  )
}
