import React from 'react'
import {useToggleState} from '../hooks/useToggleState'
import {Button} from '@blueprintjs/core'
import {IInputProps, Input} from './Input'

export function InputPassword(props: IInputProps) {
  const {isOn, toggle} = useToggleState()

  return (
    <Input
      {...props}
      type={isOn ? 'text' : 'password'}
      rightElement={<Button
        icon={isOn ? 'eye-off' : 'eye-open'}
        onClick={toggle}
        minimal={true}
      />}
    />
  )
}
