import {Link} from 'react-router-dom'
import styled from 'styled-components'

export const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`
