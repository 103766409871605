import React, {useCallback, useState} from 'react'
import {ProductApi} from 'product/ProductApi'
import {Card} from '@blueprintjs/core'
import IProduct from 'product/IProduct'
import {useDebounce} from 'use-hooks'
import {useApiListResponse} from 'shared/api/hooks/useApiListResponse'
import {List} from 'shared/list/List'
import {ListRow} from 'shared/list/ListRow'
import {DetailsViewer} from 'shared/list/DetailsViewer'

interface ISuggestProductTemplateProps {
  description: string
  onSelect: (value: IProduct) => void
}

function SuggestProductTemplate(props: ISuggestProductTemplateProps) {
  const {
    description,
    onSelect,
  } = props

  const debouncedValue = useDebounce(description, 500)
  const [last, setLast] = useState()

  const apiCall = useCallback(() =>
      ProductApi.getList({filters: [{field: 'description', op: 'like', value: debouncedValue}]}),
    [debouncedValue])

  const response = useApiListResponse(apiCall)

  const onRowClick = (item) => {
    onSelect(item)
    setLast(item.description)
  }

  if (!response.data?.length || response.data[0].description === last)
    return null

  return (
    <div style={{marginBottom: '8px'}}>
      <label>Prodotti simili</label>
      <Card style={{padding: '8px', overflow: 'auto', maxHeight: '150px'}}>
        <List<IProduct>
          items={response.data}
          interactive={true}
          itemRowProps={(item) => ({onClick: () => onRowClick(item)})}
          renderItem={({description, code}) => <ListRow
            topLeft={description}
            bottomLeft={<DetailsViewer items={[
              {label: 'Cod', value: code, hidden: !code},
            ]}/>}
          />}
        />
      </Card>
    </div>
  )
}

export default SuggestProductTemplate
