import React from 'react'
import IProduct from '../IProduct'
import {FormManager, IGenericFormProps} from 'shared/form/FormManager'
import {Validators} from 'shared/form/Validators'
import {ProductApi} from '../ProductApi'
import {MoneyUtils} from 'shared/utils/moneyUtils'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {InputVat, useDefaultVat} from 'shared/inputs/InputVat'
import {InputMoney} from 'shared/inputsV2/InputMoney'
import {InlineGroup} from 'shared/styled/InlineGroup'
import {FormGroup, Icon} from '@blueprintjs/core'
import {useToggleState} from 'shared/hooks/useToggleState'
import {FakeLink} from 'shared/styled/FakeLink'
import {AttributesValuesFormField, attributesValuesMutator} from '../../productAttributes/AttributesValuesFormField'
import {Input} from 'shared/inputsV2/Input'
import SuggestProductTemplate from 'product/components/SuggestProductTemplate'

interface IProductFormProps extends IGenericFormProps<IProduct> {
  hideInitialStock?: boolean
}

export function ProductForm(props: IProductFormProps) {
  const {
    emptyFormValue,
    hideInitialStock,
    ...others
  } = props

  const defaultVat = useDefaultVat()

  return (
    <FormManager<IProduct>
      {...others}
      api={ProductApi}
      emptyFormValue={{
        vat: defaultVat,
        ...emptyFormValue,
      }}
      mutators={{attributesValuesMutator}}
      render={({values, form: {reset}}, {isNew}) => <>
        <FieldFormGroup
          name='description'
          label='Descrizione'
          required={true}
          render={input => <Input
            {...input}
            autoFocus={true}
          />}
        />

        {isNew && values.description?.length > 3 && <SuggestProductTemplate
          description={values.description}
          onSelect={reset}
        />}

        <InlineGroup>
          <FieldFormGroup
            name='code'
            label='Codice'
            render={input => <Input
              {...input}
            />}
          />

          <FieldFormGroup
            name='vat'
            label='IVA di default'
            required={true}
            render={input => <InputVat
              {...input}
              required={true}
            />}
          />
        </InlineGroup>

        <FieldFormGroup
          name='price_list_gross'
          label='Prezzo di vendita'
          render={input => <InputMoney {...input}/>}
        />

        <AttributesValuesFormField/>

        {!hideInitialStock && <InitialStockForm values={values}/>}
      </>}
    />
  )
}

function InitialStockForm({values}: { values: IProduct }) {
  const showInitialStock = useToggleState(!!values.initial_stock)

  return showInitialStock.isOn ?
    (<div>
      <h4>Giacenza iniziale</h4>
      <InlineGroup>
        <FieldFormGroup
          name='initial_stock'
          label='Giacenza iniziale'
          required={true}
          validators={[Validators.min(0)]}
          render={input => <Input type={'number'} {...input}/>}
        />

        <FieldFormGroup
          name='initial_stock_value'
          label='Prezzo di acquisto iniziale'
          helperText={'Prezzo netto'}
          required={true}
          validators={[Validators.min(0)]}
          render={input => <InputMoney {...input}/>}
        />

        <FormGroup
          label={'Valore totale'}
          helperText={'Valore netto'}
          disabled={true}
        >
          <InputMoney
            disabled={true}
            value={MoneyUtils.round(values.initial_stock_value * values.initial_stock).toString()}
          />
        </FormGroup>
      </InlineGroup>
    </div>) :
    <div style={{margin: '16px 0'}}>
      <FakeLink onClick={showInitialStock.on}>
        <Icon icon={'edit'}/>Modifica giacenza iniziale
      </FakeLink>
    </div>
}
