import {Button, Classes, Intent} from '@blueprintjs/core'
import React from 'react'
import {Form, useFormState} from 'react-final-form'
import {FieldFormGroup} from 'shared/form/FieldFormGroup'
import {InputMoney} from 'shared/inputsV2/InputMoney'
import {Validators} from 'shared/form/Validators'
import {IMoneyItem} from './IMoneyItem'
import {useDefaultVat} from 'shared/inputs/InputVat'
import {MoneyUtils} from 'shared/utils/moneyUtils'
import {IMovement} from './IMovement'

interface ITotalEditorProps {
  isGrossMode: boolean
  onSubmit: (value: IMoneyItem) => void
}

export function TotalEditor(props: ITotalEditorProps) {
  const {
    isGrossMode,
    onSubmit,
  } = props

  const {values: {net, gross}} = useFormState<IMovement>()
  const total = isGrossMode ? gross : net
  const defaultVat = useDefaultVat()

  const onFormSubmit = ({newTotal}) => {
    const delta = MoneyUtils.round(newTotal - (total || 0), (isGrossMode ? 2 : 5))

    if (!delta)
      return

    const moneyItem: IMoneyItem = {
      net: isGrossMode ? null : delta,
      gross: isGrossMode ? delta : null,
      description: delta > 0 ? 'Arrotondamento per eccesso' : 'Sconto generale',
      vat: defaultVat,
    }

    onSubmit(moneyItem)
  }

  return (
    <Form
      initialValues={{newTotal: total}}
      onSubmit={onFormSubmit}
      render={({handleSubmit}) => <form onSubmit={handleSubmit} className={Classes.DIALOG_BODY}>
        <FieldFormGroup
          name={'newTotal'}
          required={true}
          validators={[Validators.min(0)]}
          render={props => <InputMoney {...props} autoFocus={true}/>}
        />

        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            fill={true}
            text={'Conferma'}
            intent={Intent.SUCCESS}
            type={'submit'}
          />
        </div>
      </form>}
    />
  )
}
