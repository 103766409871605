import React from 'react'
import CounterpartApi from '../CounterpartApi'
import {ErrorState} from 'shared/components/ErrorState'
import {LoadingState} from 'shared/components/LoadingState'
import {Button} from '@blueprintjs/core'
import CounterpartForm from '../components/CounterpartForm'
import PageHeader from 'shared/components/PageHeader'
import {DataViewer} from 'shared/components/DataViewer'
import {useEntityPage} from 'shared/hooks/useEntityPage'
import ICounterpart from '../ICounterpart'

export function CounterpartPage() {
  const {
    isNew,
    entity,
    error,
    loading,
    editing,
    onDismiss,
    onSaved,
    setEditing,
    goBack,
  } = useEntityPage<ICounterpart>('counterpart', CounterpartApi)

  if (loading)
    return <LoadingState/>
  if (error)
    return <ErrorState/>

  return (
    <div>
      <PageHeader
        title={isNew ? 'Nuovo' : entity.name}
        rightElement={!editing && <Button
          text={'Modifica'}
          onClick={() => setEditing(true)}
        />}
      />

      {editing && <CounterpartForm
        initialValues={entity}
        onDismiss={onDismiss}
        onSaved={onSaved}
        onDeleted={goBack}
      />}

      {!editing && <DataViewer
        data={[
          {label: 'Ruolo', value: entity.role},
          {label: 'Nome', value: entity.name},
          {label: 'Email', value: entity.email},
          {label: 'Numero di telefono', value: entity.phone_number},
          {label: 'Note', value: entity.note},
        ]}
      />}
    </div>
  )
}
