import {Colors, Intent} from '@blueprintjs/core'
import styled, {css} from 'styled-components'

interface IAppTextProps {
  size?: Size
  bold?: boolean
  intent?: Intent
  colorScale?: 1 | 2 | 3 | 4 | 5
  preventNewLine?: boolean
  muted?: boolean
}

export const AppText = styled.div<IAppTextProps>`
  display: inline-block;
  font-size: ${getFontSize}px;
  font-weight: ${getFontWeight};
  color: ${getColor};

  ${props => props.preventNewLine && preventNewLineCss}
  *:not(:last-child) {
    margin-right: 4px;
  }
`

const preventNewLineCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

type Size = 'XS' | 'S' | 'M' | 'L' | 'XL'

const sizeMap = {
  'XS': 10,
  'S': 12,
  'M': 14,
  'L': 16,
  'XL': 20,
}

const intentMap = {
  [Intent.SUCCESS]: 'GREEN',
  [Intent.DANGER]: 'RED',
  [Intent.PRIMARY]: 'BLUE',
  [Intent.WARNING]: 'ORANGE',
}

function getFontSize({size}: IAppTextProps) {
  if (!size)
    return null
  return sizeMap[size]
}

function getFontWeight({bold}: IAppTextProps) {
  return bold ? 500 : 'initial'
}

function getColor({intent, colorScale, muted}: IAppTextProps) {
  if (muted)
    return Colors.GRAY1
  if (!intent)
    return null
  const scale = colorScale || 3
  return Colors[intentMap[intent] + scale]
}
