import React from 'react'
import InputSelectId from 'shared/inputsV2/select/InputSelectId'
import {IAttribute} from 'productAttributes/attribute'
import {FormGroup} from '@blueprintjs/core'
import IProduct from 'product/IProduct'
import {AppText} from 'shared/components/AppText'
import ProductAttributesList from 'product/components/ProductAttributesList'

interface IGroupBySelectProps {
  value: string
  onChange: (value: string) => void
  items: IAttribute[]
}

function GroupBySelect(props: IGroupBySelectProps) {
  const {
    value,
    onChange,
    items,
  } = props

  return (
    <FormGroup
      label={'Raggruppa per:'}
      style={{margin: 0}}
      inline={true}
    >
      <InputSelectId<IAttribute>
        value={value}
        onChange={onChange}
        items={items}
        renderItem={item => item.name}
        nullOptionText={'Prodotto'}
        placeholder={'Prodotto'}
        allowSearch={false}
      />
    </FormGroup>
  )
}

export default GroupBySelect


export const groupByPreviewColumn = (attributes: IAttribute[], groupBy: string) => {
  if (!attributes)
    return null

  const selectedGroupByAttribute: IAttribute = attributes.find(({id}) => id === +groupBy)
  return groupBy ?
    {
      renderHead: selectedGroupByAttribute?.name,
      renderCell: ({name}) => name ? name : '- Nessuno -',
    } :
    {
      renderHead: 'Prodotto',
      renderCell: (product) => <ProductPreview product={product}/>,
    }
}

function ProductPreview({product}: { product: IProduct }) {
  if (!product)
    return null
  const
    {
      code, description, attributes_values,
    }
      = product
  return (<div>
    <div>
      <AppText size={'L'}>{description}</AppText>
      {code && <AppText muted> - {code}</AppText>}
    </div>

    <ProductAttributesList
      values={attributes_values}
    />
  </div>)
}
