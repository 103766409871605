import {useCallback, useState} from 'react'
import _ from 'lodash'

export function useApiFormSubmitter<T>(apiCall, onSuccess?) {
  const [loading, setLoading] = useState(false)

  const onSubmit: (value: T) => Promise<any> = useCallback((value) => {
    setLoading(true)
    return apiCall(value)
      .then((response) => {
        if (onSuccess)
          setTimeout(() => onSuccess(response), 0)
      })
      .catch(error => _.get(error, 'data.data'))
      .finally(() => setLoading(false))
  }, [apiCall, onSuccess])

  return {onSubmit, loading}
}
