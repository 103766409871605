import React from 'react'
import {Tag, Tooltip} from '@blueprintjs/core'
import Row from 'settings/Row'
import {IProductAttributeValue} from 'product/IProduct'

interface IProductAttributesListProps {
  values: IProductAttributeValue[]
  onAttributeClick?: (value: IProductAttributeValue, e?) => void

}

function ProductAttributesList(props: IProductAttributesListProps) {
  const {
    values,
    onAttributeClick,
  } = props

  const interactive = !!onAttributeClick

  return (
    <Row childrenMargin={'S'}>
      {values?.map((value) =>
        <Tooltip content={value.attribute.name} key={value.attribute.id}>
          <Tag
            interactive={interactive}
            minimal={true}
            onClick={(e) => onAttributeClick && onAttributeClick(value, e)}
          >{value.value}</Tag>
        </Tooltip>,
      )}
    </Row>
  )
}

export default ProductAttributesList
