import {FullScreenSection} from 'shared/components/FullScreenPage'
import {InlineGroup} from 'shared/styled/InlineGroup'
import {Field} from 'react-final-form'
import {CounterpartSelect} from '../../../counterpart/components/CounterpartSelect'
import {InputDate} from 'shared/inputs/InputDate'
import React from 'react'

export function SaleDataHeader() {
  return (
    <FullScreenSection>
      <InlineGroup>
        <Field
          name={'counterpart'}
          render={({input}) => <CounterpartSelect
            {...input}
            placeholder={'Seleziona il cliente'}
            role={'customer'}
            fill={true}
            canAddNew={true}
            required={false}
          />}
        />

        <Field
          name={'date_time'}
          render={({input}) => <InputDate
            {...input}
            fill={true}
            timePrecision={'second'}
            displayFormat={'LL HH:mm:ss'}
            closeOnSelection={false}
          />}
        />
      </InlineGroup>
    </FullScreenSection>
  )
}
