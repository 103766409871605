import {useState} from 'react'
import _ from 'lodash'

export function useSelectedListItem<T>() {
  const [selected, setSelected] = useState<{ value?: T, index?: number }>()
  const selectedValue = selected?.value
  const selectedIndex = selected?.index
  const isNew = selected && _.isNil(selectedIndex)
  const isSelected = !!selected

  const reset = () => setSelected(null)
  const selectNew = (value?: T) => setSelected({value})
  const selectItem = (value: T, index: number) => setSelected({value, index})

  return {
    selectedValue,
    selectedIndex,
    isSelected,
    isNew,
    reset,
    selectNew,
    selectItem,
  }
}
