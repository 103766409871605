import React from 'react'
import Avatar from './Avatar'
import classNames from 'classnames'
import {Classes} from '@blueprintjs/core'
import styled from 'styled-components'

export interface IEntityPreview<T> extends React.HTMLAttributes<HTMLDivElement> {
  item: T
  small?: boolean
  large?: boolean
}

interface IEntityPreviewerProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: any
  subTitle?: any
  rightElement?: any
  img?: string
  large?: boolean
  small?: boolean
}

function EntityPreviewer(props: IEntityPreviewerProps) {
  const {
    title,
    subTitle,
    img,
    large,
    small,
    rightElement,
    ...divProps
  } = props

  const titleClasses = classNames(Classes.TEXT_OVERFLOW_ELLIPSIS, large && Classes.TEXT_LARGE)
  const subTitleClasses = classNames(Classes.TEXT_OVERFLOW_ELLIPSIS, Classes.TEXT_MUTED, Classes.TEXT_SMALL)

  return (
    <div {...divProps}>
      {img && <Avatar
        src={img}
        style={{marginRight: '4px'}}
        large={large}
        small={small}
      />}
      <div style={{overflow: 'hidden', flex: 1}}>
        <div className={titleClasses}>{title}</div>
        {!small && <div className={subTitleClasses}>{subTitle}</div>}
      </div>
      {rightElement && <div>{rightElement}</div>}
    </div>
  )
}

export default styled(EntityPreviewer)`
  display: flex;
  align-items: center;
`
